import styled from 'styled-components';
import * as DesignSystem from '../../../assets/styles/StyleTypes';

export const Modal = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;

  @media (max-width: 960px) {
    top: 0;
    max-width: 100%;
  }
`;

export const Container = styled.div`
  top: 20vh;
  left: 31%;
  width: 500px;
  height: 440px;
  z-index: 10;
  position: absolute;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  background-color: white;

  @media screen and (max-width: 768px) {
    left: 10%;
    width: auto;
  }

  @media screen and (max-width: 630px) {
    left: 7%;
    top: 10%;
    width: 80%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 5px;
  }
`;

export const Image = styled.div`
  background-color: #5DAEFF;
  border-radius: 5px;

  img {
    width: 100%; 
    height: 204px;
  }

`;

export const Content = styled.div`
  width: 500px;
  height: 196px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  @media (max-width: 630px) {
    width: auto;
    height: auto;
    padding-bottom: 10%;
  }
`;

export const Title = styled.div`
  text-align: center;
  letter-spacing: 0.24px;
  color: #004687;
  text-transform: uppercase;
  opacity: 1;
  font-family: Roboto, sans-serif;
  font-size: 1.25em;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 25px;
`;

export const Text = styled.div`
  text-align: center;
  letter-spacing: 0.21px;
  color: #333333;
  opacity: 1;
  font-family: Roboto, sans-serif;
  font-size: 1.12em;
  font-weight: 400;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 36px;
  line-height: 30px;

  .bold {
      text-decoration: none;
      font-weight: 600;
      cursor: auto;
    }

  & span {
    cursor: pointer;
    text-decoration: underline;
    font-size: 18px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
  }

  @media (max-width: 630px) {
    font-size: 1em;

    & span {
      font-size: 16px;
    }
  }
`;

export const Footer = styled.div`
    display: flex;
    padding-left: 140px;

    .container {
        display: block;
        position: relative;
        padding-left: 20px;
        cursor: pointer;
        font-size: 13px;
        letter-spacing: 0.18px;
        color: #333333;
        opacity: 1;
        margin: 0;
        font-family: Roboto, sans-serif;
        font-weight: normal;    
    }

    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 13px;
        width: 13px;
        background-color: #FFFFFF;
        border-radius: 2px;
        border: 1px #004687 solid;
    }

    .container:hover input ~ .checkmark {
        background-color: #ccc;
    }

    .container .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 3.59px;
        top: 0px;
        width: 3px;
        height: 7px;
        border: solid #004687;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .container input:checked ~ .checkmark:after {
        display: block;
    }

    @media (max-width: 630px) {
      width: auto;
      padding: 0;
      justify-content: center;
    }
`;

export const CloseButton = styled.button`
  cursor: pointer;
  background-color: ${DesignSystem.Colors.HIGH_PURE};
  color: ${(props) => props.theme.colors.header};
  height: 20px;
  width: 20px;
  font-weight: 600;
  border-color: transparent;
  outline: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 91%;
  top: 3%;
`;

