import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import HomeIcon from '../../../../assets/image/home.svg';
import CookbookIcon from '../../../../assets/image/cookbook.svg';
import MenuIcon from '../../../../assets/image/meus-menus.svg';
import EquipmentIcon from '../../../../assets/image/equipamentos.svg';
import UserIcon from '../../../../assets/image/usuarios.svg';
import SettingsIcon from '../../../../assets/image/settings.svg';
import Avatar from '../../../../assets/image/avatar.svg';
import LogoutIcon from '../../../../assets/image/logout.svg';
import AnalyticsIcon from '../../../../assets/image/analytics.svg'
import API from '../../../../assets/image/API.svg'
import { useNavigate } from "react-router-dom";
import { LinksList, ListItem, Icon } from './style';
import { db } from '../../../../db/db';
import { useLiveQuery } from 'dexie-react-hooks';
import ModalConfigs from '../../../Modals/ModalConfigs';
import { useEffect, useState } from 'react';
import ModalEditAccount from '../../../Modals/ModalEditAccount';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { getMetabaseUrl } from '../../../../services';

type LinksProps = {
  hidden: boolean;
  handleHideNavbar: () => void;
};

const Links = (props: LinksProps) => {
  const intl = useIntl();
  const userDb = useLiveQuery(() => db.user.toArray());
  const userConfigDb = useLiveQuery(() => db.userConfigs.toArray());
  const companyDb = useLiveQuery(() => db.company.toArray());
  const navigate = useNavigate();
  const [showModalConfigs, setShowModalConfigs] = useState(false);
  const [showModalEditAccount, setShowModalEditAccount] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [metabaseUrl, setMetabaseUrl] = useState<string>();

  const signOutHandler = () => {
    try {
      db.user.clear();
      db.userConfigs.clear();
      db.company.clear();
      setTimeout(function () {
        navigate('/login')
        window.location.reload()
      }, 50);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (userDb) {
      getMetabaseUrl(33, userDb[0].accessToken)
        .then(resp => {
          setMetabaseUrl(resp.data)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [userDb]);

  const openTwoTabs = () => {
    window.open(metabaseUrl, '_blank');
    window.open('https://analytics.google.com/analytics/web/#/p347113554/reports/intelligenthome', '_blank');
  }

  const redirectHomePage = (companyType: string): string => {
    switch (companyType) {
      case 'Suporte':
        return '/home/support'
      case 'Master':
        return '/home/master'    
      default:
        return '/home'
    }
  };

  return (
    <>

      {showModalConfigs && (
        <div className="modal">
          <ModalConfigs onCancel={() => (setShowModalConfigs(false), setShowDropdown(!showDropdown))} userConfigsDb={userConfigDb ? userConfigDb[0] : ''} />
        </div>
      )}

      {showModalEditAccount && (
        <div className="modal">
          <ModalEditAccount user={userDb ? userDb[0] : ""} onCancel={() => setShowModalEditAccount(false)} />
        </div>
      )}

      <LinksList>
        <ListItem className='desktop'>
          {companyDb &&
            <NavLink to={redirectHomePage(companyDb[0].companyType)} onClick={props.handleHideNavbar}>
              <Icon src={HomeIcon} alt='Home' />
              {!props.hidden && intl.formatMessage({ id: 'navbar.home' })}
            </NavLink>
          }
        </ListItem>

        {(userConfigDb && userConfigDb[0].permission && userConfigDb[0].permission.find((permission) => permission.permission === "cookbook") || companyDb && (companyDb[0].companyType !== "Suporte" && companyDb[0].companyType !== "Master")) &&
          <ListItem className='desktop'>
            <NavLink to='/cookbook' onClick={props.handleHideNavbar}>
              <Icon src={CookbookIcon} alt='Cookbook' />
              {!props.hidden && intl.formatMessage({ id: 'navbar.cookbook' })}
            </NavLink>
          </ListItem>
        }
        {(userConfigDb && userConfigDb[0].permission && userConfigDb[0].permission.find((permission) => permission.permission === "menus") || companyDb && (companyDb[0].companyType !== "Suporte" && companyDb[0].companyType !== "Master")) &&
          <ListItem className='desktop'>
            <NavLink to='/menu' onClick={props.handleHideNavbar}>
              <Icon src={MenuIcon} alt='Menu' />
              {!props.hidden && intl.formatMessage({ id: 'navbar.my menus' })}
            </NavLink>
          </ListItem>
        }
        {(userConfigDb && userConfigDb[0].permission && userConfigDb[0].permission.find((permission) => permission.permission === "equipment") || companyDb && (companyDb[0].companyType === "Suporte" || companyDb[0].companyType === "Master")) &&
          <ListItem>
            <NavLink to='/equipment' onClick={props.handleHideNavbar}>
              <Icon src={EquipmentIcon} alt='Equipment' />
              {!props.hidden && intl.formatMessage({ id: 'navbar.equipment' })}
            </NavLink>
          </ListItem>
        }
        {(userConfigDb && userConfigDb[0].permission && userConfigDb[0].permission.find((permission) => permission.permission === "users") || companyDb && (companyDb[0].companyType !== "Suporte" && companyDb[0].companyType !== "Master")) &&
          <ListItem>
            <NavLink to='/users' onClick={props.handleHideNavbar}>
              <Icon src={UserIcon} alt='Users' />
              {!props.hidden && intl.formatMessage({ id: 'navbar.users' })}
            </NavLink>
          </ListItem>
        }
        {/*
          <ListItem>
          <NavLink to='/calls' onClick={props.handleHideNavbar}>
            <Icon src={AttendanceIcon} alt='Attendance' />
            {!props.hidden && intl.formatMessage({ id: 'navbar.calls' })}
          </NavLink>
        </ListItem>
        */}
        {((userDb && (userDb[0].userTypeId == '1') && companyDb && (companyDb[0].companyType === "Prática" || companyDb[0].companyType === "Suporte" || companyDb[0].companyType === "Master")) || userDb && userDb[0].email === 'de@praticabr.com') &&
          <ListItem>
            {process.env.REACT_APP_API_URL === 'https://new.praticaiok.com:3001/' ?
              <a onClick={openTwoTabs} href='#'>
                <Icon src={AnalyticsIcon} alt='Analytics' style={{ filter: "invert(100%) sepia(0%) saturate(7476%) hue-rotate(359deg) brightness(109%) contrast(106%)" }} />
                {!props.hidden && "Analytics"}
              </a>
              :
              <NavLink to='/analytics' onClick={props.handleHideNavbar}>
                <Icon src={AnalyticsIcon} alt='Analytics' style={{ filter: "invert(100%) sepia(0%) saturate(7476%) hue-rotate(359deg) brightness(109%) contrast(106%)" }} />
                {!props.hidden && "Analytics"}
              </NavLink>
            }
          </ListItem>
        }
        <ListItem className='mobile'>
          <NavLink onClick={() => setShowModalEditAccount(true)} to={''}>
            <Icon src={Avatar} alt='Avatar' />
            {!props.hidden && intl.formatMessage({ id: 'navbar.Editar conta' })}
          </NavLink>
        </ListItem>
        <ListItem className='mobile'>
          <NavLink onClick={() => setShowModalConfigs(true)} to={''}>
            <Icon src={SettingsIcon} alt='Settings' />
            {!props.hidden && intl.formatMessage({ id: 'navbar.settings' })}
          </NavLink>
        </ListItem>
        {userDb && userDb[0].developerToken != null && (userDb[0].developerToken as string).length === 20 &&
          <ListItem id='link'>
            <a href={process.env.REACT_APP_EXTERNAL_API_URL + 'external-api'} target="_blank" >
              <Icon src={API} alt='External API' />
              {!props.hidden && intl.formatMessage({ id: 'navbar.API Externa' })}
            </a>
            {!props.hidden && <FaExternalLinkAlt id='external' />}
          </ListItem>
        }
        {/*
      <ListItem className='mobile'>
        <NavLink to='/help' onClick={props.handleHideNavbar}>
          <Icon src={HelpIcon} alt='Help' />
          {!props.hidden && intl.formatMessage({ id: 'navbar.help' })}
        </NavLink>
      </ListItem>
    */}
        <ListItem className='mobile' id='button-logout' >
          <NavLink to='/login' onClick={() => { signOutHandler() }}>
            <Icon src={LogoutIcon} alt='Logout' />
            {!props.hidden && intl.formatMessage({ id: 'navbar.logout' })}
          </NavLink>
        </ListItem>
      </LinksList>
    </>
  );
};

export default Links;
