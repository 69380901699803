import { LOCALES } from '../constants';
const fr = {
  [LOCALES.FR]: {
    //LOGIN
    "login.Login": "Connexion",
    "login.Informe seu e-mail": "Entrez votre adresse e-mail",
    "login.E-mail inválido. Por favor, verifique": "Adresse e-mail invalide. Veuillez vérifier.",
    "login.Senha": "Mot de passe",
    "login.Sua senha deve possuir no mínimo 6 caracteres": "Votre mot de passe doit comporter au moins 6 caractères.",
    "login.Permanecer conectado": "Rester connecté",
    "login.Esqueci minha senha": "J'ai oublié mon mot de passe",
    "login.ENTRAR": "ENTRER",
    "login.CRIAR CONTA": "CRÉER UN COMPTE",
    "login.Não foi possÍvel fazer o login. Verifique seus dados e tente novamente": "Impossible de se connecter. Vérifiez vos données et réessayez.",
    "login.Solicitar Acesso": "Demander l'accès",
    "login.Utilize esta opção caso possua apenas fornos da versão antiga, que não geram PIN.": "Utilisez cette option si vous avez uniquement des anciens fours qui ne génèrent pas de code PIN.",
    "login.A PLATAFORMA IOK MUDOU!": "LA PLATEFORME IOK A CHANGÉ !",
    "login.Não exibir este aviso novamente": "Ne pas afficher cette notification à nouveau.",
    "login.Se você já possui um cadastro na antiga plataforma,": "Si vous avez déjà un enregistrement sur l'ancienne plateforme,",
    "login.clique aqui": "cliquez ici",
    "login. para migrar seus dados e aproveitar toda a experiência IOK.": "pour migrer vos données et profiter pleinement de l'expérience IOK.",
    "login.Sua conta não foi ativada. Por gentileza, verifique seu e-mail para realizar a ativação": "Votre compte n'a pas été activé. Veuillez vérifier votre e-mail pour l'activer",
    "login.Para solicitar acesso a uma organização, o nome informado por você deve ser exatamente o mesmo cadastrado pelo administrador da conta. Em caso de dúvida, procure o responsável pela rede.": "Pour demander l'accès à une organisation, le nom que vous fournissez doit être exactement le même que celui enregistré par l'administrateur du compte. En cas de doute, recherchez la chaîne responsable.",
    "login.Se você já possui um cadastro na antiga plataforma, ": "Si vous êtes déjà inscrit sur l'ancienne plateforme, ",
    "login. para": " pour ",
    "login.migrar": "émigrer ",
    "login.seus dados e aproveitar toda a experiência IOK.": "vos données et profitez de toute l’expérience IOK.",
    "login.PLATAFORMA EM MANUTENÇÃO": "PLATEFORME EN MAINTENANCE",
    "login.ESTAMOS PREPARANDO A NOVA PLATAFORMA IOK E EM BREVE ELA ESTARÁ PRONTA!": "NOUS PRÉPARONS LA NOUVELLE PLATEFORME IOK ET ELLE SERA BIENTÔT PRÊTE!",
    "login.Migrar Conta": "Migrer le compte",
    "login.Se você já possui um cadastro na antiga plataforma, clique aqui em Migrar Conta para migrar seus dados e aproveitar toda a experiência IOK": "Si vous êtes déjà inscrit sur l'ancienne plateforme, cliquez ici sur Migrer le compte pour migrer vos données et profiter de toute l'expérience IOK",

    //REGISTER
    "DISTRIBUIDOR": "DISTRIBUTEUR : Je suis distributeur de matériel de Prática.",
    "REDE": "RÉSEAU : Je possède un réseau.",
    "DONO": "ENTREPRISE : Je possède ma propre entreprise/je fais partie d'un réseau.",
    "register.Informe seu e-mail cadastrado na plataforma IOK anterior": "Entrez votre e-mail enregistré sur l'ancienne plateforme IOK.",
    "register.E-mail inválido. Por favor, verifique": "E-mail non valide. Veuillez vérifier.",
    "register.BUSCAR DADOS": "RECHERCHER DES DONNÉES",
    "register.Nome da organização": "Nom de l'organisation",
    "register.Informe o nome de sua organização": "Entrez le nom de votre organisation.",
    "register.O campo nome da organização é obrigatório": "Le champ du nom de l'organisation est obligatoire.",
    "register.Este nome já existe. Por favor, escolha outro": "Ce nom existe déjà. Veuillez en choisir un autre.",
    "register.RECUPERAR CONTA": "RÉCUPÉRER LE COMPTE",
    "register.E-mail não encontrado": "E-mail non trouvé.",
    "register.Digite seu e-mail da plataforma IOK antiga": "Tapez votre e-mail de l'ancienne plateforme IOK.",
    "register.Não foi possível recuperar sua conta. Verifique seus dados e tente novamente": "Il n'a pas été possible de récupérer votre compte. Vérifiez vos données et réessayez.",
    "register.Não foi possível recuperar seus dados. Verifique e tente novamente": "Il n'a pas été possible de récupérer vos données. Vérifiez et réessayez.",
    "register.PROSSEGUIR": "CONTINUER",
    "register.VOCÊ ESTÁ SOLICITANDO ACESSO PARA A ORGANIZAÇÃO": "VOUS DEMANDEZ L'ACCÈS À L'ORGANISATION :",
    "register.COM O ADMINISTRADOR": "AVEC L'ADMINISTRATEUR :",
    "register.E-mail já cadastrado na plataforma. Informe um novo e-mail ou acesse a plataforma com o e-mail já cadastrado": "L'e-mail est déjà enregistré sur la plateforme. Entrez une nouvelle adresse e-mail ou accédez à la plateforme avec celle déjà enregistrée.",
    "register.Empresa já cadastrada na plataforma. Por favor, informe o nome de uma nova empresa": "L'entreprise est déjà enregistrée sur la plateforme. Veuillez entrer un nouveau nom d'entreprise.",
    "register.VOCÊ NÃO RECONHECEU O USUÁRIO {userName} COMO PARTE DA SUA ORGANIZAÇÃO E POR ISSO A CONTA DELE FOI DELETADA.": "VOUS N'AVEZ PAS RECONNU L'UTILISATEUR {userName} COMME PARTIE DE VOTRE ORGANISATION ET C'EST POURQUOI SON COMPTE A ÉTÉ SUPPRIMÉ.",
    "register.NÂO FOI POSSÍVEL DELETAR O USÁRIO {userName}. POR GENTILEZA, TENTE NOVAMENTE MAIS TARDE.": "IL N'A PAS ÉTÉ POSSIBLE DE SUPPRIMER L'UTILISATEUR {userName}. VEUILLEZ RÉESSAYER PLUS TARD.",
    "register.CONFIRMAR": "CONFIRMER",
    "register.Informe o ID da organização": "Indiquez l'ID de votre organisation.",
    "register.O campo com ID é obrigatório": "Le champ ID est obligatoire.",
    "register.Ainda não tenho uma organização. Criar": "Je n'ai pas encore d'organisation. Créer",
    "register.ID informado não corresponde a nenhuma companhia. Verifique e tente novamente": "L'ID indiqué ne correspond à aucune entreprise. Vérifiez et réessayez.",
    "register.O ADMINISTRADOR DA ORGANIZAÇÃO RECEBERÁ UM E-MAIL PARA ATIVAR SUA CONTA. POR GENTILIZA, AGUARDE": "L'ADMINISTRATEUR DE L'ORGANISATION RECEVRA UN E-MAIL POUR ACTIVER VOTRE COMPTE UTILISATEUR. VEUILLEZ PATIENTER.",
    "register.Este nome de franquia já possui uma conta ativa na plataforma": "Ce nom de franchise a déjà un compte actif sur la plateforme.",
    "register.Não foi possível fazer o cadastro. Verifique seus dados e tente novamente": "Il n'a pas été possible de s'inscrire. Vérifiez vos données et réessayez.",
    "register.VOCÊ RECEBERÁ UM E-MAIL PARA CONCLUIR SEU CADASTRO E ATIVAR SUA CONTA DE ADMINISTRADOR": "VOUS RECEVREZ UN E-MAIL POUR FINALISER VOTRE INSCRIPTION ET ACTIVER VOTRE COMPTE D'ADMINISTRATEUR.",
    "register.Nome": "Nom",
    "register.Informe seu nome": "Entrez votre nom.",
    "register.O campo nome é obrigatório": "Le champ du nom est obligatoire.",
    "register.E-mail": "E-mail",
    "register.Informe seu e-mail": "Entrez votre adresse e-mail.",
    "register.E-mail inválido. Por favor, verifique novamente": "E-mail non valide. Veuillez vérifier.",
    "register.Telefone": "Numéro de téléphone",
    "register.Informe seu telefone": "Entrez votre numéro de téléphone.",
    "register.Crie sua senha": "Créez un mot de passe",
    "register.Informe sua senha": "Indiquez votre mot de passe.",
    "register.O campo senha deve conter no mínimo 6 caracteres": "Le champ du mot de passe doit comporter au moins 6 caractères.",
    "register.Confirme sua senha": "Confirmez le mot de passe",
    "register.As senhas devem ser iguais": "Le mot de passe doit correspondre.",
    "register.CRIAR CONTA": "CRÉER UN COMPTE",
    "register.CONTA ATIVADA COM SUCESSO": "COMPTE ACTIVÉ AVEC SUCCÈS",
    "register.ACESSAR": "ACCÈS",
    "register.NÃO FOI POSSÍVEL ATIVAR SUA CONTA": "IL N'A PAS ÉTÉ POSSIBLE D'ACTIVER VOTRE COMPTE.",
    "register.Digite seu telefone": "Indiquez votre numéro de téléphone.",
    "register.AGUARDE ENQUANTO TRANSFERIMOS SEUS DADOS. ESTE PROCESSO DEVE LEVAR ALGUNS MINUTOS. CASO VOCÊ POSSUA UMA GRANDE QUANTIDADE DE DADOS, A TRANSFERÊNCIA PODE LEVAR ATÉ UMA HORA.": "VEUILLEZ ATTENDRE PENDANT QUE NOUS TRANSFÉRONS VOS DONNÉES. CE PROCESSUS DEVRAIT PRENDRE QUELQUES MINUTES. SI VOUS DISPOSEZ D'UNE GRANDE QUANTITÉ DE DONNÉES, LE TRANSFERT PEUT PRENDRE JUSQU'À UNE HEURE.",
    "register.DADOS TRANSFERIDOS COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "TRANSFERT DE DONNÉES RÉUSSI ! CLIQUEZ SUR LE BOUTON CI-DESSOUS POUR ACCÉDER À LA PLATEFORME.",
    "register.EMAIL INVÁLIDO. POR GENTILEZA, VERIFIQUE SE SUA CONTA JÁ FOI ATIVADA.": "E-MAIL INVALIDE. VEUILLEZ VÉRIFIER SI VOTRE COMPTE A DÉJÀ ÉTÉ ACTIVÉ.",
    "register.A nova plataforma IOK agora conta com novas formas de acesso - que são referentes ao tipo de organização em que você atua. O responsável pela sua organização deve migrar a conta principal da empresa, se tornando o administrador, enquanto o restante dos funcionários devem se vincular a ela pelo ID fornecido.": "La nouvelle plateforme IOK propose désormais de nouvelles formes d'accès, qui sont liées au type d'organisation dans laquelle vous travaillez. La personne responsable de votre organisation doit migrer le compte principal de l'entreprise, devenant ainsi l'administrateur, tandis que le reste des employés doit s'y connecter en utilisant l'ID fourni.",
    "register.O administrador da minha organização já realizou a migração da conta e temos um ID. Desejo migrar meus dados de usuário.": "L'administrateur de mon organisation a déjà migré le compte et nous avons un ID. Je veux migrer les données de mon utilisateur.",
    "register.Desejo migrar a conta da minha organização e me tornar o administrador.": "Je veux migrer le compte de mon organisation et devenir l'administrateur.",
    "register.Conta da organização já migrada": "Compte de l'organisation déjà migré.",
    "register.VOCÊ POSSUI MUITOS MENUS, NÃO CONSEGUIMOS REALIZAR A IMPORTAÇÃO AUTOMÁTICA. SUA CONTA SERÁ ATIVADA, PORÉM OS MENUS PRECISARÃO SER MIGRADOS MANUALMENTE.": "VOUS AVEZ DE NOMBREUX MENUS, NOUS NE POUVONS PAS EFFECTUER UNE IMPORTATION AUTOMATIQUE. VOTRE COMPTE SERA ACTIVÉ, MAIS LES MENUS DEVRONT ÊTRE MIGRÉS MANUELLEMENT.",
    "register.PARA MIGRAR SEUS MENUS, APÓS O LOGIN, CLIQUE NO SEU NOME NO CANTO SUPERIOR DIREITO, CLIQUE EM EDITAR CONTA E DEPOIS EM IMPORTAR MENUS.": "POUR MIGRER VOS MENUS, APRÈS CONNEXION, CLIQUEZ SUR VOTRE NOM DANS LE COIN SUPÉRIEUR À DROITE, CLIQUEZ SUR MODIFIER LE COMPTE PUIS SUR IMPORTER LES MENUS.",
    "register.Atenção:": "Attention:",
    "register.apenas uma pessoa da organização pode criar a conta e, depois de ativá-la, convidar os demais usuários da empresa para acessá-la.": "seule une personne de l'organisation peut créer le compte et, après l'avoir activé, inviter les autres utilisateurs de l'entreprise à y accéder.",
    "register.TOKEN DESENVOLVEDOR CANCELADO": "JETON DÉVELOPPEUR ANNULÉ",

    //HOME
    "home.Menus disponíveis na plataforma": "Menus disponibles sur la plateforme",
    "home.Criados na plataforma": "Créé sur la plateforme",
    "home.Recebidos dos fornos": "Reçu des fours",
    "home.Equipamentos vinculados": "Équipements liés",
    "home.Total de receitas cadastradas": "Total de recettes enregistrées",
    "home.Receitas": "Recettes",
    "home.Receitas em menus": "Recettes dans les menus",
    "home.Em menus": "Dans les menus",
    "home.Receitas salvas no Cookbook": "Recettes enregistrées dans le livre de cuisine",
    "home.No Cookbook": "Dans le livre de cuisine",
    "home.Atualizações": "Mises à jour",
    "home.Copa": "Copa",
    "home.Rocket": "Rocket",
    "home.Single Mag": "Single Mag",
    "home.Versão instalada": "Version installée",
    "home.Versão disponível": "Version disponible",
    "home.Usuários cadastrados": "Utilisateurs enregistrés",
    "home.Aceitação pendente": "Acceptation en attente",
    "home.Atualizado": "Mis à jour",
    "home.OLÁ": "BONJOUR",
    "home.É muito bom ter você aqui!": "C'est génial de vous avoir ici !",
    "home.Com a Plataforma IOK é possível administrar seus fornos de onde estiver, criar e gerenciar receitas e menus, fazer backups, analisar os dados de seus equipamentos e muito mais!": "Avec la Plateforme IOK vous pouvez gérer vos fours où que vous soyez, créer et gérer des recettes et des menus, effectuer des sauvegardes, analyser les données de vos équipements et bien plus encore !",
    "home.Conheça a plataforma": "Découvrez la plateforme",
    "home.Taxa de erro por modelo": "Taux d'erreur par modèle",
    "home.Erro": "Erreur",
    "home.Modelos em evidência": "Modèles en vedette",
    "home.Modelos": "Modèles",
    "home.Total de equipamentos vinculados": "Nombre total d'équipements liés",
    "home.Total de equipamentos online": "Nombre total d'équipements en ligne",
    "home.Equipamentos online": "Équipements en ligne",
    "home.Países com Prática": "Pays avec Prática",
    "home.Países": "Pays",
    "home.Aguarde enquanto carregamos os dados": "Veuillez patienter pendant que nous chargeons les données",
    "home.Quase lá! Aguarde um pouco mais": "Presque terminé ! Veuillez patienter encore un peu",
    "home.QUEREMOS SABER SUA OPINIÃO SOBRE": "NOUS VOULONS CONNAÎTRE VOTRE AVIS SUR",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a Plataforma {model} foi criado!": "Nous apprécions grandement votre point de vue et c'est pourquoi ce formulaire d'enquête de satisfaction sur la plateforme {model} a été créé !",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a linha {model} foi criado!": "Nous apprécions grandement votre point de vue et c'est pourquoi ce formulaire d'enquête de satisfaction concernant la ligne {model} a été créé !",
    "home.Suas respostas nos ajudarão a aprimorar nossos serviços, garantindo que continuemos a oferecer a você a melhor experiência possível.": "Vos réponses nous aideront à améliorer nos services, garantissant ainsi que nous continuons à vous offrir la meilleure expérience possible.",
    "home.Reserve uns minutinhos de seu dia e participe!": "Prenez quelques minutes de votre journée et participez !",
    "home.Responder a pesquisa": "Répondez à l'enquête",

    //MAIN
    "main.HOME": "ACCUEIL",
    "main.COOKBOOK": "LIVRE DE CUISINE",
    "main.MEUS MENUS": "MES MENUS",
    "main.EQUIPAMENTOS": "ÉQUIPEMENT",
    "main.USUÁRIOS": "UTILISATEURS",
    "main.ATENDIMENTOS": "SERVICES",
    "main.Idioma": "Langue",
    "main.Editar conta": "Modifier le compte",
    "main.Configurações": "Paramètres",
    "main.Sair": "Déconnexion",
    "main.Configurações salvas com sucesso!": "Paramètres enregistrés avec succès!",
    "main.SALVAR": "ENREGISTRER",
    "main.CONFIGURAÇÕES": "PARAMÈTRES",
    "main.Tema dark": "Thème sombre",
    "main.Notificações": "Notifications",
    "main.Informações para as receitas": "Informations sur la recette",
    "main.Peso:": "Poids",
    "main.Gramas": "Grammes",
    "main.Oz": "Onces",
    "main.Temperatura:": "Températures :",
    "main.Celsius (°C)": "Celsius (°C)",
    "main.Fahrenheit (°F)": "Fahrenheit (°F)",
    "main.Conta editada com sucesso!": "Compte modifié avec succès!",
    "main.EDITAR CONTA": "MODIFIER LE COMPTE",
    "main.Nome": "Nom",
    "main.Preencha com o seu nome": "Remplissez votre nom",
    "main.Telefone": "Téléphone",
    "main.Preencha com o seu telefone": "Remplissez votre numéro de téléphone",
    "main.E-mail": "E-mail",
    "main.Preencha com o seu e-mail": "Remplissez votre e-mail",
    "main.Alterar Senha": "Changer le mot de passe",
    "main.ALTERAR SENHA": "CHANGER LE MOT DE PASSE",
    "main.Senha atual": "Mot de passe actuel",
    "main.Nova senha": "Nouveau mot de passe",
    "main.Confirmar nova senha": "Confirmez le nouveau mot de passe",
    "main.Informe sua senha atual": "Entrez votre mot de passe actuel",
    "main.O campo senha deve conter no mínimo 6 caracteres": "Le champ du mot de passe doit comporter au moins 6 caractères",
    "main.Informe sua nova senha": "Entrez votre nouveau mot de passe",
    "main.Confirme sua nova senha": "Confirmez votre nouveau mot de passe",
    "main.Sua nova senha deve conter no mínimo 6 caracteres": "Votre nouveau mot de passe doit comporter au moins 6 caractères",
    "main.INFORME SUA SENHA": "ENTREZ VOTRE MOT DE PASSE",
    "main.Informe sua senha para realizar a alteração de e-mail da plataforma:": "Entrez votre mot de passe pour changer votre e-mail de plateforme :",
    "main.CONFIRMAR": "CONFIRMER",
    "main.Existem campos que não foram preenchidos corretamente": "Certains champs n'ont pas été correctement remplis",
    "main.Informe sua senha para prosseguir com a alteração de e-mail": "Entrez votre mot de passe pour procéder au changement de votre e-mail",
    "main.Senha alterada com sucesso": "Mot de passe changé avec succès",
    "main.Informe sua senha atual corretamente": "Entrez correctement votre mot de passe actuel",
    "main.As senhas devem ser iguais": "Les mots de passe doivent correspondre",
    "main.Nome do novo tipo de usuário": "Nom du nouveau type d'utilisateur",
    "main.Dê um nome para o novo tipo de usuário": "Donnez un nom au nouveau type d'utilisateur",
    "main.Token Desenvolvedor": "Jeton de développeur",
    "main.O Token Desenvolvedor é um código único e intransferível que permite a realização de alterações": "Le jeton de développeur est un code unique et non transférable qui permet d'apporter des modifications au niveau du développement directement dans le code de la plateforme via des API externes. Elles permettent de personnaliser la plateforme pour mieux répondre aux besoins de votre entreprise.",
    "main.É recomendado que estas alterações sejam realizadas por um profissional da área de tecnologia": "Il est recommandé que ces changements soient effectués par un professionnel du domaine de la technologie.",
    "main.Para acessar o token, informe sua senha": "Pour accéder au jeton, veuillez fournir votre mot de passe.",
    "main.Copie e cole o token na autenticação de todas as requisições.": "Copiez et collez le jeton dans l'authentification de toutes les demandes.",
    "main.Seu Token": "Votre jeton",
    "main.Token copiado com sucesso!": "Jeton copié avec succès !",
    "main.Você deseja cancelar o preenchimento das informações?": "Voulez-vous annuler la saisie des informations?",
    "main.Rever tutoriais": "Revoir les tutoriels",
    "main.Tutoriais resetados com sucesso": "Tutoriels réinitialisés avec succès",
    "main.review.tutorials": "Voulez-vous revoir les tutoriels de la plateforme IOK ?",

    //MENUS
    "menus.Procurar menu": "Menu de recherche ou utilisateur",
    "menus.Procurar menu excluído": "Rechercher le menu supprimé",
    "menus.Menus": "Menus",
    "menus.Lixeira": "Poubelle",
    "menus.CRIAR MENU": "CRÉER UN MENU",
    "menus.IMPORTAR MENU": "IMPORTER UN MENU",
    "menus.Os arquivos serão mantidos na lixeira por 30 dias a contar de sua data de exclusão. Após este período, eles serão excluídos definitivamente.": "Les fichiers seront conservés dans la corbeille pendant 30 jours à partir de la date de suppression. Passé ce délai, ils seront définitivement supprimés.",
    "menus.VOLTAR": "RETOUR",
    "menus.Compatível com: ": "Compatible avec: ",
    "menus.Última atualização: ": "Dernière mise à jour: ",
    "menus.Última atualização": "Dernière mise à jour",
    "menus.EXPORTAR MENU": "EXPORTER LE MENU",
    "menus.EDITAR MENU": "MODIFIER LE MENU",
    "menus.Equipamentos associados": "Équipement associé",
    "menus.Grupos": "Groupes",
    "menus.Procurar grupo": "Rechercher un groupe",
    "menus.CRIAR GRUPO": "CRÉER UN GROUPE",
    "menus.receitas": "Recettes",
    "menus.Nenhum grupo cadastrado": "Aucun groupe enregistré",
    "menus.Nenhum menu cadastrado": "Aucun menu enregistré",
    "menus.Nome": "Nom",
    "menus.Modelo": "Modèle",
    "menus.Atividade": "Activité",
    "menus.Associação": "Association",
    "menus.Exportar": "Exporter",
    "menus.Editar": "Modifier",
    "menus.Excluir": "Supprimer",
    "menus.Equips": "Équiper",
    "menus.Receitas": "Recettes",
    "menus.Compatibilidade": "Compatibilité",
    "menus.Versão": "Version",
    "menus.Origem": "Origine",
    "menus.Excluído por: ": "Supprimé par: ",
    "menus.Restaurar": "Restaurer",
    "menus.Existem campos que não foram preenchidos": "Certains champs n'ont pas été remplis.",
    "menus.Menu criado com sucesso!": "Menu créé avec succès!",
    "menus.Existem campos com informações inválidas": "Certains champs contiennent des informations invalides",
    "menus.SALVAR": "ENREGISTRER",
    "menus.CANCELAR": "ANNULER",
    "menus.Nome do menu:": "Nom du menu: ",
    "menus.Informe o nome do menu": "Informer le nom du menu",
    "menus.Preencha o nome do menu sem caracteres especiais": "Remplir le nom du menu sans caractères spéciaux",
    "menus.Selecionar": "Sélectionner",
    "menus.Idioma": "Langue",
    "menus.Temperatura de pré-aquec 1:": "Température de préchauffage 1:",
    "menus.Informe a temperatura de pré-aquec 1": "Informer la température de préchauffage 1",
    "menus.Temperatura de pré-aquec 2:": "Température de préchauffage 2:",
    "menus.Informe a temperatura de pré-aquec 2": "Informer la température de préchauffage 2",
    "menus.Tempo para estabilização:": "Temps de stabilisation:",
    "menus.Informe o tempo para estabilização": "Informer le temps de stabilisation",
    "menus.Desativar pré-aquec 2": "Désactiver le préchauffage 2",
    "menus.Configurações de data e hora": "Paramètres de date et heure",
    "menus.Data:": "Date:",
    "menus.DD/MM/AAAA": "JJ/MM/AAAA",
    "menus.Hora:": "Heure:",
    "menus.24h": "24h",
    "menus.AM/PM": "AM/PM",
    "menus.EUR: DD/MM/AAAA": "EUR: JJ/MM/AAAA",
    "menus.USA: MM/DD/AAAA": "USA: MM/JJ/AAAA",
    "menus.ISO: AAAA/MM/DD": "ISO : AAAA/MM/JJ",
    "menus.Seleção de acessos para habilitar": "Sélection de l'accès à activer",
    "menus.Modo manual": "Mode manuel",
    "menus.Habilitado": "Activé",
    "menus.Desabilitado": "Désactivé",
    "menus.Favoritos": "Favoris",
    "menus.Aquecer/Dourar": "Chauffe/Doré",
    "menus.Repetir receita": "Répéter la recette",
    "menus.Configurações gerais do menu": "Paramètres généraux du menu",
    "menus.Temperatura": "Température",
    "menus.Temperatura:": "Température:",
    "menus.Celsius (°C)": "Celsius (°C)",
    "menus.Fahrenheit (°F)": "Fahrenheit (°F)",
    "menus.Peso": "Poids",
    "menus.Gramas": "Grammes",
    "menus.Oz": "Onces",
    "menus.Tema:": "Thème:",
    "menus.Dark": "Sombre",
    "menus.Light": "Clair",
    "menus.Introdução:": "Introduction:",
    "menus.Ativada": "Activé",
    "menus.Desativada": "Désactivé",
    "menus.Menu deletado com sucesso!": "Menu supprimé avec succès!",
    "menus.Não foi possivel deletar o menu": "Impossible de supprimer le menu",
    "menus.Sim": "Oui",
    "menus.Não": "Non",
    "menus.Excluir definitivamente": "Supprimer définitivement",
    "menus.SALVAR EM PEN DRIVE": "ENREGISTRER SUR UNE CLÉ USB",
    "menus.EXPORTAR": "EXPORTER",
    "menus.Selecione os modelos e versões dos equipamentos que receberão o menu.": "Sélectionnez les modèles et versions d'équipements pour recevoir le menu",
    "menus.Forno": "Four",
    "menus.Fundo verde": "Fond vert",
    "menus.Fundo preto": "Fond noir",
    "menus.Linux": "Linux",
    "menus.Atenção: antes de transferir o menu de seu pen drive para o equipamento, é necessário descompactar o arquivo, exceto para menus de equipamentos Linux.": "Attention : avant de transférer le menu de votre clé USB à l'équipement, il est nécessaire de décompresser le fichier, sauf pour les menus des équipements Linux.",
    "menus.Selecione os equipamentos para os quais deseja exportar o menu.": "Sélectionnez l'équipement pour lequel vous souhaitez exporter le menu.",
    "menus.Nome do equipamento": "Nom de l'équipement",
    "menus.Selecionar todos": "Sélectionner tout",
    "menus.Selecionar todas": "Sélectionner tout",
    "menus.Categoria: ": "Catégorie: ",
    "menus.Você deseja excluir o menu?": "Souhaitez-vous supprimer le menu?",
    "menus.O menu será deletado da plataforma, mas permanecerá no equipamento. Uma cópia de segurança será criada automaticamente e ficará disponível por 30 dias em Meus Menus - Lixeira.": "Le menu sera supprimé de la plateforme, mais sera conservé dans l'équipement. Une copie de sécurité sera créée automatiquement et sera disponible pendant 30 jours dans Mes Menus - Corbeille.",
    "menus.Você deseja excluir o arquivo definitivamente?": "Souhaitez-vous supprimer le fichier définitivement?",
    "menus.Esta ação não poderá ser desfeita.": "Cette action ne peut pas être annulée.",
    "menus.Menu editado com sucesso!": "Menu édité avec succès!",
    "menus.IMPORTAR": "IMPORTER",
    "menus.VERIFICAÇÃO DE VERSÃO": "VÉRIFICATION DE VERSION",
    "menus.Para conferir a versão da placa de seu equipamento, siga os seguintes passos:": "Pour vérifier la version de la carte de l'équipement, suivez les étapes suivantes:",
    "menus.No forno, acesse Configurações;": "Dans le four, accédez aux Paramètres;",
    "menus.Insira a senha 456789;": "Entrez le mot de passe 456789;",
    "menus.Selecione a opção Sistema;": "Sélectionnez l'option Système;",
    "menus.Selecione Informações do Fabricante;": "Sélectionnez Informations sur le fabricant;",
    "menus.O modelo de sua placa estará na linha Firmware IHM;": "Le modèle de votre carte sera dans la ligne Firmware IHM;",
    "menus.1ª geração A": "1re génération A",
    "menus.Firmware IHM: 12.3.4": "Firmware IHM: 12.3.4",
    "menus.1ª geração B": "1re génération B",
    "menus.2ª geração/Linux:": "2e génération/Linux",
    "menus.Firmware IHM: L1.2.3": "Firmware IHM: L1.2.3",
    "menus.Selecione o modelo/versão do equipamento do qual deseja importar o menu. Saiba como conferir a versão de seu equipamento": "Sélectionnez le modèle/version de l'équipement à partir duquel vous souhaitez importer le menu. Apprenez à vérifier la version de votre équipement ",
    "menus.Selecione o modelo/versão do equipamento": "Sélectionnez le modèle/version de l'équipement",
    "menus.Como verificar a versão da placa": "Comment vérifier la version de la carte",
    "menus.1° Geração": "1re génération",
    "menus.Menu restaurado com sucesso!": "Menu restauré avec succès!",
    "menus.Não foi possivel restaurar o menu": "Impossible de restaurer le menu",
    "menus.RESTAURAR ARQUIVO": "RESTAURER LE FICHIER",
    "menus.Você deseja restaurar o arquivo?": "Souhaitez-vous restaurer le fichier?",
    "menus.O arquivo será restaurado como uma nova versão na plataforma, na guia Todos. Para salvá-lo no equipamento, exporte-o.": "Le fichier sera restauré en tant que nouvelle version sur la plateforme sous l'onglet Tous. Pour l'enregistrer sur votre appareil, exportez-le.",
    "menus.EXCLUIR MENU": "SUPPRIMER LE MENU",
    "menus.Nenhum equipamento associado": "Aucun équipement associé",
    "menus.Nomeie o novo menu": "Nommez le nouveau menu",
    "menus.Nomeie o novo grupo": "Nommez le nouveau groupe",
    "menus.Nome do grupo:": "Nom du groupe: ",
    "menus.Preencha o nome do grupo sem caracteres especiais": "Remplissez le nom du groupe sans caractères spéciaux",
    "menus.Pré-aquecimento": "Préchauffage",
    "menus.Pré-aquecimento:": "Préchauffage:",
    "menus.Grupo criado com sucesso!": "Groupe créé avec succès!",
    "menus.SELECIONE UMA IMAGEM": "SÉLECTIONNER UNE IMAGE",
    "menus.SELECIONAR": "SÉLECTIONNER",
    "menus.Selecionar do computador": "Sélectionner depuis l'ordinateur",
    "menus.Selecionar do banco de imagens": "Sélectionner depuis la banque d'images",
    "menus.Formato .JPG ou .PNG": "Format .JPG ou .PNG",
    "menus.Procurar imagem": "Rechercher une image",
    "menus.Pertence ao menu": "Appartient au menu",
    "menus.Alterar imagem": "Changer l'image",
    "menus.Remover imagem": "Supprimer l'image",
    "menus.EDITAR GRUPO": "ÉDITER LE GROUPE",
    "menus.CRIAR RECEITA": "CRÉER UNE RECETTE",
    "menus.SALVAR DO COOKBOOK": "ENREGISTRER DU LIVRE DE RECETTES",
    "menus.Procurar receita do grupo": "Rechercher la recette du groupe",
    "menus.Ingrediente principal": "Ingrédient principal",
    "menus.Mover receitas": "Déplacer les recettes",
    "menus.Excluir receitas": "Supprimer les recettes",
    "menus.Editar grupo": "Éditer le groupe",
    "menus.Excluir grupo": "Supprimer le groupe",
    "menus.aqui": "ici",
    "menus.Se o código possuir apenas números e o fundo da tela for verde, pertence à 1ª A, se possuir apenas números e o fundo da tela for preta, pertence à 1ª B e, se começar com as letras L, F ou S, sua placa pertence à 2ª geração/Linux.": "Si le code ne contient que des chiffres et que l'arrière-plan est vert, il appartient à la 1re A ; s'il ne contient que des chiffres et que l'arrière-plan est noir, il appartient à la 1re B et, s'il commence par les lettres L, F ou S, sa carte appartient à la 2e génération/Linux.",
    "menus.1ª A - Fundo verde": "1re A - Fond vert",
    "menus.1ª B - Fundo preto": "1re B - Fond noir",
    "menus.2ª - Linux": "2e - Linux",
    "menus.Tivemos um problema. Tente novamente mais tarde.": "Nous avons rencontré un problème. Réessayez plus tard.",
    "menus.Grupo editado com sucesso!": "Groupe édité avec succès!",
    "menus.AINDA NÃO HÁ GRUPOS AQUI :(": "IL N'Y A TOUJOURS PAS DE GROUPES ICI :(",
    "menus.NENHUM MENU NA LIXEIRA": "PAS DE MENU DANS LA CORBEILLE",
    "menus.AINDA NÃO HÁ MENUS AQUI :(": "IL N'Y A TOUJOURS PAS DE MENUS ICI :(",
    "menus.Adicionar imagem": "Ajouter une image",
    "menus.Nome da receita": "Nom de la recette",
    "menus.Origem da receita": "Origine de la recette",
    "menus.Detalhes da receita": "Détails de la recette",
    "menus.Programação da receita": "Programmation de la recette",
    "menus.Nomeie sua receita": "Nommez votre recette",
    "menus.Preencha o nome da receita sem caracteres especiais": "Remplissez le nom de la recette sans caractères spéciaux",
    "menus.Tipo de prato": "Type de repas",
    "menus.SALVAR RECEITAS DO COOKBOOK": "ENREGISTRER LES RECETTES DU LIVRE DE RECETTES",
    "menus.Selecione as receitas que deseja salvar no grupo de seu menu:": "Sélectionnez les recettes que vous souhaitez enregistrer dans votre groupe de menus:",
    "menus.Procurar receita": "Rechercher une recette",
    "menus.Grupo deletado com sucesso!": "Groupe supprimé avec succès!",
    "menus.Não foi possivel deletar o grupo": "Impossible de supprimer le groupe",
    "menus.EXCLUIR GRUPO": "SUPPRIMER LE GROUPE",
    "menus.Você deseja excluir o grupo definitivamente?": "Souhaitez-vous supprimer définitivement le groupe?",
    "menus.AINDA NÃO HÁ RECEITAS AQUI :(": "IL N'Y A TOUJOURS PAS DE RECETTES ICI :(",
    "menus.Temp. de entrada": "Température de départ",
    "menus.Temp. de pré-aquec": "Température de préchauffage",
    "menus.Ingredientes para montagem": "Ingrédients pour l'assemblage",
    "menus.Instruções operacionais": "Instructions de fonctionnement",
    "menus.Passo": "Étape",
    "menus.Temp": "Temp ",
    "menus.Temp.": "Temp. ",
    "menus.Tempo": "Temps",
    "menus.Velocidade do ar": "Vitesse de l'air",
    "menus.Micro-ondas": "Micro-ondes",
    "menus.Resistência inferior": "Résistance inférieure",
    "menus.Informações": "Information",
    "menus.Digite as informações do passo": "Saisir les informations de l'étape",
    "menus.desativar": "Désactiver ",
    "menus.Ingredientes": "Ingrédients",
    "menus.Temperatura de entrada": "Température de départ",
    "menus.Equipamentos compatíveis": "Équipement compatible",
    "menus.SALVAR NO COOKBOOK": "ENREGISTRER DANS LE LIVRE DE CUISINE",
    "menus.ver localização": "voir l'emplacement",
    "menus.SELECIONE RECEITAS PARA EXCLUIR": "SÉLECTIONNER LES RECETTES À SUPPRIMER",
    "menus.Você deseja excluir as receitas selecionadas definitivamente": "Souhaitez-vous supprimer définitivement les recettes sélectionnées?",
    "menus.Receitas deletadas com sucesso!": "Recettes supprimées avec succès!",
    "menus.Informe aqui os ingredientes para montagem": "Indiquez ici les ingrédients pour l'assemblage",
    "menus.Informe aqui as instruções operacionais": "Indiquez ici les instructions de fonctionnement",
    "menus.Adicionar passo": "Ajouter une étape",
    "menus.Receita criada com sucesso!": "Recette créée avec succès!",
    "menus.Ideal para:": "Idéale pour: ",
    "menus.editar detalhes": "modifier les détails",
    "menus.excluir": "supprimer",
    "menus.editar": "modifier",
    "menus.Receita editada com sucesso!": "Recette éditée avec succès!",
    "menus.Passo deletado com sucesso!": "Étape supprimée avec succès!",
    "menus.Não foi possivel deletar o passo": "Impossible de supprimer l'étape",
    "menus.EXCLUIR PASSO": "SUPPRIMER L'ÉTAPE",
    "menus.Você deseja excluir este passo": "Souhaitez-vous supprimer cette étape?",
    "menus.Esta ação não poderá ser desfeita. Caso prefira, é possível apenas desativá-lo, mantendo assim sua configuração.": "Cette action ne peut pas être annulée. Si vous préférez, il est possible de la désactiver uniquement en conservant ses paramètres.",
    "menus.Plataforma": "Plateforme",
    "menus.Receita salva com sucesso!": "Recette enregistrée avec succès!",
    "menus.Exportar receitas": "Exporter les recettes",
    "menus.expandir": "Développer",
    "menus.Vapor combinado": "Vapeur combinée",
    "menus.Ar quente": "Air chaud",
    "menus.Sonda": "Sonde",
    "menus.Tempo (min)": "Temps (min)",
    "menus.Umidade (%)": "Humidité (%)",
    "menus.Injeção de vapor": "Injection de vapeur",
    "menus.Dumper aberto": "Décharge ouverte",
    "menus.Informe o peso": "Indiquer le poids",
    "menus.Informe a temperatura de entrada": "Indiquer la température de départ",
    "menus.Informe os ingredientes para montagem": "Entrer les ingrédients pour l'assemblage",
    "menus.Informe as instruções operacionais": "Entrer les instructions de fonctionnement",
    "menus.Injeção de vapor (seg)": "Injection de vapeur (sec)",
    "menus.Aquecer mais": "Chauffer davantage",
    "menus.Dourar mais": "Dorer davantage",
    "menus.Aquecer e dourar mais": "Chauffer et dorer davantage",
    "menus.Favoritar receita": "Recette favorite",
    "menus.Informe o tempo para aquecer mais de 0 a 59 segundos": "Entrer le temps de chauffage supplémentaire de 0 à 59 secondes",
    "menus.Informe o tempo para dourar mais de 0 a 59 segundos": "Entrer le temps de dorure supplémentaire de 0 à 59 secondes",
    "menus.Informe o tempo para aquecer e dourar mais de 0 a 59 segundos": "Entrer le temps de chauffage et de dorure supplémentaire de 0 à 59 secondes",
    "menus.Apenas favoritos": "Uniquement les favoris",
    "menus.Forno combinado": "Four combiné",
    "menus.Cocção múltipla": "Cuisson multiple",
    "menus.Receitas Technicook": "Recettes Technicook",
    "menus.Editar grupos": "Éditer les groupes",
    "menus.Informe a data": "Entrer la date",
    "menus.Informe a hora": "Entrer l'heure",
    "menus.até 300": "jusqu'à 300",
    "menus.Vapor": "Vapeur",
    "menus.Turbina": "Turbine",
    "menus.Criar grupo": "Créer un groupe",
    "menus.MOVER": "DÉPLACER ",
    "menus.Configurações de acessos": "Accéder aux paramètres:",
    "menus.SELECIONE RECEITAS PARA MOVER": "SÉLECTIONNER LES RECETTES À DÉPLACER",
    "menus.AVANÇAR": "AVANCER",
    "menus.Selecione ou crie um grupo no mesmo menu para mover as receitas selecionadas ou as mova para outro menu. Note que apenas os menus/grupos compatíveis com as predefinições das receitas serão exibidos.": "Sélectionner ou créer un groupe dans le même menu pour déplacer les recettes sélectionnées ou les déplacer vers un autre menu. Notez que seuls les menus/groupes compatibles avec les préréglages de la recette seront affichés.",
    "menus.Mover receitas para o mesmo menu": "Déplacer les recettes vers le même menu",
    "menus.Mover receitas para outro menu": "Déplacer les recettes vers un autre menu",
    "menus.Selecionar grupo": "Sélectionner un groupe",
    "menus.Nenhuma receita foi selecionada": "Aucune recette n'a été sélectionnée",
    "menus.Passo editado com sucesso!": "Étape éditée avec succès!",
    "menus.Menu atualizado": "Menu mis à jour",
    "menus.até 260": "jusqu'à 260",
    "menus.Umidade na câmara": "Humidité de la chambre",
    "menus.Passos": "Étapes",
    "menus.ativar": "Activer",
    "menus.Informe a temperatura de pré-aquec": "Entrer la température de préchauffage",
    "menus.Movas as receitas selecionadas para outro menu. Note que apenas os menus compatíveis com as predefinições das receitas serão exibidos.": "Déplacer les recettes sélectionnées vers un autre menu. Notez que seuls les menus compatibles avec les paramètres prédéfinis de la recette seront affichés.",
    "menus.Sonda (°C)": "Sonde (ºC)",
    "menus.Turbo Grill": "Turbo Grill",
    "menus.Pré-aquecimento padrão, conforme passo 1": "Préchauffage standard, selon l'étape 1",
    "menus.salvar": "Enregistrer",
    "menus.até": "Jusqu'à",
    "menus.Verificação de receita": "Vérification de la recette",
    "menus.Auto import": "Auto-importation",
    "menus.Atualização forçada": "Mise à jour forcée",
    "menus.Lista de receitas exportada com sucesso!": "Liste de recettes exportée avec succès!",
    "menus.Receita movida com sucesso!": "Recette déplacée avec succès!",
    "menus.Receitas movidas com sucesso!": "Recettes déplacées avec succès!",
    "menus.+ Receita": "Recette",
    "menus.Escolha um idioma compatível com o equipamento": "Sélectionner une langue compatible avec l'équipement",
    "menus.MENU IMPORTADO COM SUCESSO!": "Menu importé avec succès!",
    "menus.Dourar": "Brun",
    "menus.Não atribuir a menu": "Ne pas attribuer au menu",
    "menus.Todos": "Tous",
    "menus.Meus menus": "Mes menus",
    "menus.Para salvar uma receita, preencha todos os campos": "Pour enregistrer une recette, remplissez tous les champs",
    "menus.Selecione os grupos de equipamentos para os quais deseja exportar o menu.": "Sélectionnez les groupes d'équipements dont vous souhaitez exporter le menu.",
    "menus.Você deseja excluir este passo?": "Voulez-vous supprimer cette étape?",
    "menus.Formato inválido. São permitidos apenas os formatos .jpeg e .png": "Format invalide. Seuls les formats .jpeg et .png sont autorisés.",
    "menus.Reordenar grupos": "Réorganiser les groupes",
    "menus.Reordenar receitas": "Réorganiser les recettes",
    "menus.ITENS REORDENADOS COM SUCESSO! PARA APLICAR AS ALTERAÇÕES NO FORNO, EXPORTE O MENU.": "ÉLÉMENTS RÉORGANISÉS AVEC SUCCÈS ! POUR APPLIQUER LES MODIFICATIONS AU FOUR, EXPORTER LE MENU.",
    "menus.ARRASTE OS ITENS PARA O LOCAL DESEJADO": "Glissez les éléments à l'endroit souhaité.",
    "menus.Dumper": "Tombereau",
    "menus.Aberto": "Ouvrir",
    "menus.Fechado": "Fermé",
    "menus.Intermitente": "Intermittent",
    "menus.Se o seu menu incluir itens sensíveis à uniformidade, recomendamos definir o Tempo de Estabilização entre 3:00 e 5:00 minutos. Caso contrário, você pode deixá-lo em 0:00 minutos.": "Si votre menu comprend des éléments sensibles à l'uniformité, nous vous recommandons de régler le temps de stabilisation entre 3h00 et 5h00. Sinon, vous pouvez le laisser à 0h00 minutes.",
    "menus.Selecione o menu que deseja exportar:": "Sélectionnez le menu que vous souhaitez exporter :",
    "menus.Temperatura de pré-aquecimento:": "Température de préchauffage:",
    "menus.Temperatura de lastro": "Température du ballast",
    "menus.Temperatura de teto": "Température du plafond",
    "menus.Volume de injeção de vapor (ml)": "Volume d'injection de vapeur (ml)",
    "menus.Parada de turbina": "Arrêt de turbine",
    "menus.Alta": "Élevé",
    "menus.Média": "Moyen",
    "menus.Baixa": "Bas",
    "menus.a": "le",
    "menus.Valor da injeção": "Valeur d'injection",
    "menus.Volume: até 999ml": "Volume : jusqu'à 999 ml",
    "menus.Tempo: até 15 segundos": "Durée : jusqu'à 15 secondes",
    "menus.Tempo de injeção de vapor (seg)": "Temps d'injection de vapeur (sec)",
    "menus.Atenção!": "Attention!",
    "menus.Verifique as receitas do menu e ajuste os valores da Injeção de Vapor após alterar sua unidade de medida.": "Vérifiez les recettes du menu et ajustez les valeurs d'injection de vapeur après avoir modifié votre unité de mesure.",
    "menus.Certifique-se de que a unidade de temperatura (°C ou °F) definida no equipamento seja a mesma que foi selecionada na criação do menu:": "Assurez-vous que l'unité de température (°C ou °F) définie sur l'équipement est la même que celle sélectionnée lors de la création du menu :",
    "menus.Injeção desativada": "Injection désactivée",
    "menus.Selecione a unidade de temperatura que está definida no equipamento:": "Sélectionnez l'unité de température définie sur l'équipement :",
    "menus.Clique para atualizar": "Cliquez pour mettre à jour",
    "menus.A função Atualização Forçada envia automaticamente o menu para os equipamentos selecionados na próxima vez em que forem ligados.": "La fonction Mise à jour forcée envoie automatiquement le menu à l'équipement sélectionné lors de sa prochaine mise sous tension.",
    "menus.As opções selecionadas estarão disponíveis nos equipamentos que receberem este menu, podendo ser utilizadas pelo operador. É possível alterar estas configurações posteriormente no próprio forno ou pela plataforma.": "Les options sélectionnées seront disponibles sur les équipements qui reçoivent ce menu et pourront être utilisées par l'opérateur. Il est possible de modifier ces réglages ultérieurement dans le four lui-même ou via la plateforme.",
    "menus.+ Menu": "+ Menu",
    "menus.Não foi possivel deletar as receitas": "Impossible de supprimer des recettes",

    //COOKBOOK
    "cookbook.RECEITAS DISPONÍVEIS": "RECETTES DISPONIBLES",
    "cookbook.RECEITAS SALVAS": "RECETTES ENREGISTRÉES",
    "cookbook.ENVIAR PARA MENU": "ENVOYER AU MENU",
    "cookbook.SALVAR": "ENREGISTRER",
    "cookbook.salvar": "enregistrer",
    "cookbook.Filtrar por": "Filtrer par",
    "cookbook.Procurar receita do cookbook": "Rechercher une recette dans le livre de cuisine",
    "cookbook.Equipamento": "Équipement",
    "cookbook.Ingrediente principal": "Ingrédient principal",
    "cookbook.Tipo de prato": "Type de repas",
    "cookbook.Região": "Région",
    "cookbook.Pré-aquecimento": "Préchauffage",
    "cookbook.Procurar receita": "Rechercher une recette",
    "cookbook.Menu": "Menu",
    "cookbook.ENVIAR": "SEND",
    "cookbook.Procurar nome do menu": "Rechercher le nom du menu",
    "cookbook.Escolha um grupo deste menu": "Choisissez un groupe de ce menu",
    "cookbook.Selecionar grupo": "Sélectionnez un groupe",
    "cookbook.Tivemos um problema. Tente novamente mais tarde": "Nous avons rencontré un problème. Réessayez plus tard.",
    "cookbook.Receita adicionada com sucesso!": "Recette ajoutée avec succès!",
    "cookbook.Não foi possível adicionar a receita. Tente novamente mais tarde": "Il n'a pas été possible d'ajouter la recette. Réessayez plus tard.",
    "cookbook.Selecione um menu e um grupo para enviar esta receita": "Sélectionnez un menu et un groupe pour envoyer cette recette",
    "cookbook.Selecione o modelo do equipamento primeiro": "Sélectionnez d'abord le modèle d'équipement",
    "cookbook.Grupo": "Groupe",
    "cookbook.Não foi possível salvar os passos da receita": "Il n'a pas été possible d'enregistrer les étapes de la recette",
    "cookbook.Bovina": "Bœuf",
    "cookbook.Frango": "Poulet",
    "cookbook.Ovos": "Œufs",
    "cookbook.Pães": "Pains",
    "cookbook.Peixes": "Poisson",
    "cookbook.Queijo": "Fromage",
    "cookbook.Suína": "Porc",
    "cookbook.Vegetais": "Légumes",
    "cookbook.Outros": "Autres",
    "cookbook.Entradas/aperitivos": "Entrées/amuse-gueules",
    "cookbook.Principais": "Plat principal",
    "cookbook.Snacks": "Collations",
    "cookbook.Pizzas": "Pizzas",
    "cookbook.Massas": "Pâtes",
    "cookbook.Acompanhamentos": "Plats d'accompagnement",
    "cookbook.Sobremesas": "Desserts",
    "cookbook.Manhã": "Petit déjeuner",
    "cookbook.Padaria": "Boulangerie",
    "cookbook.Todos": "Tout",
    "cookbook.Não atribuir origem": "Ne pas attribuer d'origine",
    "cookbook.Brasil": "Brésil",
    "cookbook.Alemanha": "Allemagne",
    "cookbook.França": "France",
    "cookbook.Rússia": "Russie",
    "cookbook.China": "Chine",
    "cookbook.Japão": "Japon",
    "cookbook.Itália": "Italie",
    "cookbook.Egito": "Égypte",
    "cookbook.Grécia": "Grèce",
    "cookbook.Argentina": "Argentine",
    "cookbook.África": "Afrique",
    "cookbook.Estados Unidos": "États-Unis",
    "cookbook.Coreia": "Corée",
    "cookbook.Turquia": "Turquie",
    "cookbook.Ideal para": "Idéal pour",
    "cookbook.Polônia": "Pologne",
    "cookbook.Sanduíches": "Sandwichs",

    //EQUIPAMENTOS
    "equipment.equipamentos": "Équipement",
    "equipment.procurar equipamento": "Rechercher un équipement ou un utilisateur",
    "equipment.adicionar equipamento": "Ajouter un équipement",
    "equipment.atualizar equipamento": "Mettre à jour l'équipement",
    "equipment.+ equipamentos": "Équipement",
    "equipment.buscar equipamento": "Rechercher un équipement",
    "equipment.Desvincular": "Dissocier",
    "equipment.Editar": "Modifier",
    "equipment.sem conteúdo": "Il n'y a toujours aucun équipement ici :(",
    "equipment.editar equipamento": "Modifier l'équipement",
    "equipment.equipamento editado com sucesso!": "Équipement modifié avec succès!",
    "equipment.não foi possível editar o equipamento. Tente novamente mais tarde": "Il n'a pas été possible de modifier l'équipement. Réessayez plus tard",
    "equipment.Menu exportado com sucesso!": "Menu exporté avec succès!",
    "equipment.Não foi possível exportar o menu. Tente novamente mais tarde": "Il n'a pas été possible d'exporter le menu. Réessayez plus tard",
    "equipment.Selecione pelo menos um equipamento para enviar o menu": "Sélectionnez au moins un équipement pour envoyer le menu",
    "equipment.Informações do menu": "Informations sur le menu",
    "equipment.Procurar receita ou grupo": "Rechercher une recette ou un groupe",
    "equipment.Grupo": "Groupe",
    "equipment.Receita": "Recette",
    "equipment.Quantidade": "Quantité",
    "equipment.Receitas mais executadas no equipamento durante o período": "Recettes les plus exécutées sur l'équipement pendant la période",
    "equipment.Informações de limpeza": "Informations de nettoyage",
    "equipment.Data da limpeza": "Date de nettoyage",
    "equipment.Hora da limpeza": "Heure de nettoyage",
    "equipment.nome": "Nom",
    "equipment.modelo": "Modèle",
    "equipment.categoria": "Catégorie",
    "equipment.loja": "Magasin",
    "equipment.desvincular equipamento": "Dissocier l'équipement",
    "equipment.unlink.paragrafo 1": "Souhaitez-vous dissocier l'équipement de la plateforme IOK ?",
    "equipment.unlink.paragrafo 2": "En le faisant, il ne sera pas possible de gérer vos paramètres et votre menu via la plateforme, à moins de lier à nouveau l'équipement en suivant les instructions de connexion.",
    "equipamento desvinculado com sucesso!": "Équipement dissocié avec succès!",
    "equipment.ocorreu um erro. Tente novamente mais tarde": "Une erreur s'est produite. Réessayez plus tard",
    "equipment.sim": "Oui",
    "equipment.não": "Non",
    "equipment.Encerrar acesso remoto": "Terminer l'accès à distance",
    "equipment.Horas sem limpeza": "Heures sans nettoyage",
    "equipment.Histórico de eventos": "Historique des événements",
    "equipment.Evento": "Événement",
    "equipment.Data": "Date",
    "equipment.ERRO SENSOR RI": "Erreur avec le capteur de résistance inférieure",
    "equipment.ERRO COM INV. DE FREQ.": "Erreur avec l'onduleur de fréquence",
    "equipment.ERRO MAGNETRON": "Erreur avec le magnétron",
    "equipment.ERRO SENSOR CAMARA": "Erreur avec le capteur de caméra",
    "equipment.ERRO CAN": "Erreur CAN",
    "equipment.Erro INVERSOR DE FREQUENCIA": "Erreur de l'onduleur de fréquence",
    "equipment.ERRO DO MODELO DO EQUIPAMENTO": "Erreur de modèle d'équipement",
    "equipment.ERRO PAINEL": "Erreur de panneau",
    "equipment.ERRO INV.FREQ.STATUS": "État d'erreur de l'onduleur de fréquence",
    "equipment.ERRO SENSOR BOARD": "Erreur du capteur de la carte",
    "equipment.ERRO ENERGY": "Erreur d'énergie",
    "equipment.ERRO I2C": "Erreur I2C",
    "equipment.EQUIPAMENTO LIGADO": "Équipement allumé",
    "equipment.EQUIPAMENTO DESLIGADO": "Équipement éteint",
    "equipment.Plataforma": "Plateforme",
    "equipment.Forno": "Four",
    "equipment.Origem": "Origine",
    "equipment.Locais": "Emplacements",
    "equipment.não foi possível carregar as entidades": "Il n'a pas été possible de charger les entités",
    "equipment.conceder acesso remoto a": "Accorder l'accès à distance à",
    "equipment.atualizar equipamentos": "Mettre à jour l'équipement",
    "equipment.suporte": "Support",
    "equipment.PIN": "PIN",
    "equipment.nome do equipamento": "Nom de l'équipement",
    "equipment.vinculado à loja": "Lié au magasin",
    "equipment.cadastrar loja": "Enregistrer le magasin",
    "equipment.informe o PIN do forno": "Entrez le PIN du four",
    "equipment.preencha o PIN do forno": "Remplissez le PIN du four",
    "equipment.adicione um nome para facilitar seu reconhecimento": "Ajoutez un nom pour le reconnaître plus facilement",
    "equipment.preencha o nome do equipamento": "Remplissez le nom de l'équipement",
    "equipment.selecionar": "Sélectionnez",
    "equipment.nome fantasia": "Nom fantaisie",
    "equipment.informe o nome fantasia": "Entrez le nom fantaisie",
    "equipment.preencha o nome fantasia": "Remplissez le nom fantaisie",
    "equipment.CNPJ": "CNPJ",
    "equipment.XX.XXX.XXX/0001.XX": "XX.XXX.XXX/0001.XX",
    "equipment.preencha o CNPJ": "Remplissez le CNPJ",
    "equipment.CEP": "Code postal",
    "equipment.00000-000": "00000-000",
    "equipment.preencha o CEP": "Remplissez le code postal",
    "equipment.cidade": "Ville",
    "equipment.informe a cidade": "Entrez la ville",
    "equipment.preencha a cidade": "Remplissez la ville",
    "equipment.UF": "État",
    "equipment.bairro": "Quartier",
    "equipment.informe o bairro": "Entrez le quartier",
    "equipment.endereço": "Adresse",
    "equipment.informe o seu endereço": "Entrez votre adresse",
    "equipment.preencha o seu endereço": "Remplissez votre adresse",
    "equipment.Nº": "N°",
    "equipment.equipamento cadastrado com sucesso!": "Équipement enregistré avec succès!",
    "equipment.ocorreu um erro": "Une erreur s'est produite",
    "equipment.preencha o modelo": "Remplissez le modèle",
    "equipment.selecione uma categoria": "Sélectionnez une catégorie",
    "equipment.selecione uma loja": "Sélectionnez un magasin",
    "equipment.preencha o bairro": "Remplissez le quartier",
    "equipment.não foi possível cadastrar o equipamento. Tente novamente mais tarde": "Il n'a pas été possible d'enregistrer l'équipement. Réessayez plus tard",
    "equipment.loja cadastrada com sucesso": "Magasin enregistré avec succès",
    "equipment.não foi possível cadastrar a loja. Tente novamente mais tarde": "Il n'a pas été possible d'enregistrer le magasin. Réessayez plus tard",
    "equipment.equipment": "équipement",
    "equipment.stores": "magasins",
    "equipment.search equipment": "rechercher un équipement",
    "equipment.add equipment": "ajouter un équipement",
    "equipment.update equipment": "mettre à jour l'équipement",
    "equipment.plus equipment": "équipement",
    "equipment.Procurar equipamento": "Rechercher un équipement",
    "equipment.EQUIPAMENTOS": "ÉQUIPEMENT",
    "equipment.Atualizar equipamento": "Mettre à jour l'équipement",
    "equipment.Atualizar equipamentos": "Mettre à jour l'équipement",
    "equipment.confirmar": "confirmer",
    "equipment.Selecione os modelos que deseja atualizar:": "Sélectionnez les modèles que vous souhaitez mettre à jour",
    "equipment.Como deseja realizar a atualização?": "Comment souhaitez-vous procéder à la mise à jour ?",
    "equipment.Enviar atualização pela plataforma (wi-fi)": "Envoyer la mise à jour via la plateforme (Wi-Fi)",
    "equipment.Salvar arquivo de atualização em pen drive": "Enregistrer la mise à jour sur une clé USB",
    "equipment.Linha Express": "Ligne express",
    "equipment.Escolha como deseja realizar a atualização": "Choisissez comment vous souhaitez procéder avec la mise à jour",
    "equipment.Selecione ao menos um modelo de equipamento para atualizar": "Sélectionnez au moins un modèle d'équipement à mettre à jour",
    "equipment.Status:": "Statut: ",
    "equipment.Número de série:": "Numéro de série",
    "equipment.Menu:": "Menu",
    "equipment.Loja:": "Magasin",
    "equipment.End:": "Fin: ",
    "equipment.Cidade:": "Ville: ",
    "equipment.CEP:": "Code postal: ",
    "equipment.Atualização de software enviada com sucesso!": "Mise à jour logicielle envoyée avec succès!",
    "equipment.Não foi possível enviar a atualização de sodtware. Tente novamente mais tarde": "Il n'a pas été possible d'envoyer la mise à jour logicielle. Réessayez plus tard",
    "equipment.Download realizado com sucesso": "Téléchargement terminé avec succès",
    "equipment.Não foi possível realizar o download. Tente novamente mais tarde": "Il n'a pas été possible de terminer le téléchargement. Réessayez plus tard",
    "equipment.name": "Nom",
    "equipment.model": "Modèle",
    "equipment.category": "Catégorie",
    "equipment.store": "Magasin",
    "FIT Express/FIT ST": "FIT Express/FIT ST",
    "Copa/Single Mag/Rocket": "Copa/Single Mag/Rocket",
    "Forza STi": "Forza STi",
    "TSi": "TSi",
    "FIT Express": "FIT Express",
    "FIT ST": "FIT ST",
    "equipment.Preencha o nome da versão": "Remplissez le nom de la version",
    "equipment.Informe aqui todas as alterações desta nova versão": "Entrez ici tous les changements de cette nouvelle version",
    "equipment.Nova versão de software atualizada com sucesso!": "Nouvelle version du logiciel mise à jour avec succès!",
    "equipment.Informe pelo menos uma mudança nesta versão": "Entrez au moins un changement dans cette version",
    "equipment.Falha ao ler o arquivo": "Échec de la lecture du fichier",
    "equipment.selecionar tipo": "sélectionnez le type",
    "equipment.selecione o tipo da versão": "sélectionnez le type de version",
    "equipment.atualização": "MISE À JOUR",
    "equipment.Selecione abaixo o que deseja fazer:": "Sélectionnez ci-dessous ce que vous souhaitez faire :",
    "equipment.Atualizar meus equipamentos": "Mettre à jour mon équipement",
    "equipment.Enviar atualização para usuários selecionados": "Envoyer la mise à jour aux utilisateurs sélectionnés",
    "equipment.ENVIAR": "ENVOYER",
    "equipment.ENVIAR ATUALIZAÇÃO": "ENVOYER LA MISE À JOUR",
    "equipment.Selecione os e-mails para os quais deseja enviar a atualização:": "Sélectionnez les e-mails auxquels vous souhaitez envoyer la mise à jour :",
    "equipment.A atualização disponibilizada no dia": "La mise à jour disponible du jour",
    "equipment.referente ao equipamento": "concernant l'équipement",
    "equipment.contempla o seguinte:": "considère ce qui suit :",
    "equipment.Selecionar arquivo para envio": "Sélectionnez le fichier à envoyer",
    "equipment.Por favor, selecione um arquivo .tar": "Veuillez sélectionner un fichier .tar",
    "equipment.Selecione o modelo do equipamento:": "Sélectionnez le modèle d'équipement :",
    "equipment.Selecione uma opção": "Sélectionnez une option",
    "equipment.Nomeie a versão": "Nommez la version",
    "equipment.Clique sobre o arquivo que deseja baixar:": "Cliquez sur le fichier que vous souhaitez télécharger:",
    "equipment.Baixar atualização": "Télécharger la mise à jour",
    "equipment.Versões:": "Versions:",
    "equipment.e superiores": "et plus haut",
    "FIT Express, FIT ST": "FIT Express, FIT ST",
    "Copa Express, Rocket Express, Copa Single Mag": "Copa Express, Rocket Express, Copa Single Mag",
    "equipment.e": "et",
    "equipment.Últimas versões de software:": "Dernières versions du logiciel: ",
    "equipment.Você não possui nenhum equipamento desatualizado": "Vous n'avez pas de matériel obsolète",
    "equipment.grupos": "groupes",
    "equipment.não foi possível carregar os grupos de equipamentos da companhia": "Impossible de charger les groupes d'équipements de l'entreprise",
    "equipment.vinculado ao grupo": "Lié au groupe",
    "equipment.selecione um grupo": "Sélectionnez un groupe",
    "equipment.cadastrar grupo": "Inscrire un groupe",
    "equipment.Número de Série": "NS",
    "equipment.Versão": "Version",
    "equipment.não foi possível cadastrar o equipamento. Selecione ou cadastre um grupo": "Impossible d'enregistrer l'équipement. Sélectionnez ou enregistrez un groupe",
    "equipment.CRIE GRUPOS DE EQUIPAMENTOS!": "CRÉEZ DES GROUPES D'ÉQUIPEMENT !",
    "equipment.Para que os usuários vinculados à conta possam visualizar os equipamentos você precisa:": "Pour que les utilisateurs liés au compte puissent visualiser l'équipement, vous devez :",
    "equipment.1) Criar grupo(s);": "1) Créer un ou plusieurs groupes ;",
    "equipment.2) Atribuir equipamento(s) ao grupo;": "2) Attribuer le(s) équipement(s) au groupe ;",
    "equipment.3) Atribuir usuário(s) ao grupo.": "3) Attribuez des utilisateurs au groupe.",
    "equipment.Status software": "État du logiciel",
    "equipment.Status menu": "État du menu",
    "equipment.Pendente": "En attente",
    "equipment.Aceito": "Accepté",
    "equipment.Recusado": "Refusé",
    "equipment.Atualizar": "Mettre à jour",
    "equipment.Deseja enviar a atualização para o equipamento selecionado?": "Voulez-vous envoyer la mise à jour à l'équipement sélectionné?",
    "equipment.Companhia": "Entreprise",
    "equipment.Exportar menu": "Menu Exporter",
    "equipment.companhias": "entreprises",
    "equipment.Nome da companhia": "Nom de l'entreprise",
    "equipment.Procurar equipamento, usuário ou companhia": "Rechercher un équipement, un utilisateur ou une entreprise",
    "equipment.Versão da power": "Version électrique",
    "equipment.AINDA NÃO HÁ DADOS DE RECEITAS AQUI :(": "IL N'Y A PAS ENCORE DE DONNÉES DE RECETTE ICI :(",
    "equipment.AINDA NÃO HÁ DADOS DE LIMPEZAS AQUI :(": "IL N'Y A PAS ENCORE DE DONNÉES DE NETTOYAGE ICI :(",
    "equipment.AINDA NÃO HÁ DADOS DE EVENTOS AQUI :(": "IL N'Y A PAS ENCORE DE DONNÉES D'ÉVÉNEMENT ICI :(",
    "ID": "ID",
    "equipment.Hora": "Heure",
    "equipment.EVENTOS MAIS OCORRIDOS NO EQUIPAMENTO DURANTE O PERÍODO": "ÉVÉNEMENTS LES PLUS SURVENUS SUR L'ÉQUIPEMENT PENDANT LA PÉRIODE",
    "equipment.hour": "h",
    "equipment.minutes": "Min",
    "equipment.date format": "JJ/MM/AAAA",
    "equipment.executada": "exécutée",
    "equipment.vezes": "fois",
    "equipment.vez": "fois",
    "equipment.Ver": "Voir",
    "equipment.Os equipamentos enviarão os dados à plataforma ao serem ligados. A cada dois meses, os dados recebidos serão automaticamente excluídos.": "L'équipement enverra des données à la plateforme lorsqu'il sera allumé. Tous les deux mois, les données reçues seront automatiquement supprimées.",
    "equipment.Ligado": "Allumé",
    "equipment.Desligado": "Éteint",
    "equipment.CAN ERROR": "Erreur CAN",
    "equipment.CAMERA SENSOR ERROR": "Erreur du capteur de la caméra",
    "equipment.STEAM/RI SENSOR ERROR": "Erreur du capteur RI",
    "equipment.PROBE 1 ERROR": "Alerte sonde 1",
    "equipment.PROBE 2 ERROR": "Alerte sonde 2",
    "equipment.PROBE 3 ERROR": "Alerte de sonde 3",
    "equipment.PROBE 4 ERROR": "Alerte de sonde 4",
    "equipment.PROBE 5 ERROR": "Alerte de sonde 5",
    "equipment.PROBE 6 ERROR": "Alerte de sonde 6",
    "equipment.GENERAL PROBE ERROR": "Erreur générale de sonde",
    "equipment.WATER LACK ERROR": "Alerte de faible niveau d'eau",
    "equipment.HIGH PANEL TEMPERATURE ERROR": "Alerte de température élevée du panneau",
    "equipment.FREQUENCY INVERTER GENERAL ERROR": "Erreur du variateur de fréquence",
    "equipment.FREQUENCY INVERTER COMMUNICATION ERROR": "Alerte de communication dans le variateur de fréquence",
    "equipment.FREQUENCY INVERTER STATUS ERROR": "Alerte d'état du variateur de fréquence",
    "equipment.CLEANING NOT COMPLETED ERROR": "Alerte de nettoyage incomplet",
    "equipment.MAGNETRON ERROR": "Alerte magnétron",
    "equipment.EQUIPMENT FAMILY/MODEL ERROR": "Erreur de modèle d'équipement",
    "equipment.BOARD TEMPERATURE SENSOR ERROR": "Alerte du capteur de température de la carte",
    "equipment.ENERGY MODULE ERROR": "Erreur du module d'énergie",
    "equipment.POWER BOARD I2C BUS ERROR": "Erreur de la carte d'alimentation",
    "equipment.GAS SYSTEM ERROR": "Erreur de gaz",
    "equipment.VERY HIGH PANEL TEMPERATURE ERROR": "Erreur de température de panneau très élevée",
    "equipment.POWER ON": "On",
    "equipment.POWER OFF": "Off",
    "equipment.DOOR OPENED": "Porte ouverte",
    "equipment.DOOR CLOSED": "Porte fermée",
    "equipment.CLEANING CANCELED ERROR": "Désinfection annulée par l'utilisateur",
    "equipment.PIN não encontrado. Por favor, verifique e tente novamente": "Code PIN introuvable. S'il vous plaît, vérifiez et essayez à nouveau",
    "equipment.Online": "En ligne",
    "equipment.Offline": "Hors ligne",
    "equipment.Exibir apenas eventos": "Afficher uniquement les événements",
    "equipment.Endereço": "Adresse",
    "equipment.EQUIPMENT CONNECTED TO THE PLATFORM": "Équipements connectés à la plateforme",
    "equipment.EQUIPMENT DISCONNECTED FROM THE PLATFORM": "Équipement déconnecté de la plateforme",
    "equipment.MENU RECEIVED FROM THE PLATFORM": "Menu reçu de la plateforme",
    "equipment.MENU REJECTED BY THE USER": "Menu rejeté par l'utilisateur",
    "equipment.CONNECTED TO THE INTERNET": "Connecté à Internet",
    "equipment.DISCONNECTED FROM THE INTERNET": "Déconnecté d'Internet",
    "equipment.Infelizmente não há versões que realizem o envio de dados compatíveis com este equipamento.": "Malheureusement, il n'y a pas de versions qui effectuent des envois de données compatibles avec cet équipement.",
    "equipment.O equipamento": "L'équipement",
    "equipment.deve ser atualizado para a versão mais recente de software para que passe a enviar os dados para a plataforma": "doit être mis à jour vers la dernière version du logiciel pour commencer à envoyer des données à la plateforme",
    "equipment.Versão do seu equipamento": "Version de votre équipement",
    "equipment.Versão disponível": "Version disponible",
    "equipment.Selecione abaixo como deseja enviar a atualização para seu equipamento": "Sélectionnez ci-dessous comment vous souhaitez envoyer la mise à jour à votre équipement",
    "equipment.Salvar em pen drive": "Enregistrer sur clé USB",
    "equipment.Enviar por wi-fi": "Envoyer par Wi-Fi",
    "equipment.Cliente": "Client",
    "equipment.NÚMERO DE SÉRIE NÃO ENCONTRADO": "NUMÉRO DE SÉRIE INTROUVABLE",
    "equipment.BUSCAR": "RECHERCHE",
    "equipment.ACESSADOS RECENTEMENTE": "RÉCEMMENT ACCÉDÉ",
    "equipment.SEM BUSCAS RECENTES": "AUCUNE RECHERCHE RÉCENTE",
    "equipment.Procurar equipamento por NS": "Recherche de matériel par NS",
    "equipment.AIR FILTER CLEANING COMPLETED": "Nettoyage du filtre à air terminé",
    "equipment.AIR FILTER CLEANING REJECTED": "Nettoyage du filtre à air rejeté",
    "equipment.CATALYTIC FILTER CLEANING COMPLETED": "Nettoyage du filtre catalytique terminé",
    "equipment.CATALYTIC FILTER CLEANING REJECTED": "Nettoyage du filtre catalytique refusé",
    "equipment.ID do equipamento": "ID de l'équipement",
    "equipment.Todo equipamento deve ser vinculado a um grupo. Selecione um grupo da lista ou cadastre um novo clicando em + Cadastrar grupo.": "Tous les équipements doivent être liés à un groupe. Sélectionnez un groupe dans la liste ou enregistrez-en un nouveau en cliquant sur + Enregistrer le groupe.",
    "equipment.Nomeie seu forno": "Nommez votre four",
    "equipment.Para visualizar o PIN, acesse nas configurações do equipamento a Plataforma Virtual IOK.": "Pour afficher le code PIN, accédez à la plateforme virtuelle IOK dans les paramètres de l'équipement.",
    "equipment.localização": "emplacement",
    "equipment.AINDA NÃO HÁ NENHUMA LOCALIZAÇÃO CADASTRADA :(": "IL N'Y A PAS ENCORE D'EMPLACEMENT ENREGISTRÉ :(",
    "equipment.Procurar empresa ou local": "Rechercher une entreprise ou un emplacement",
    "equipment.Adicionar localização": "Ajouter un emplacement",
    "equipment.espaço": "Espace",
    "equipment.local": "Locale",
    "equipment.EXCLUIR LOCALIZAÇÃO": "SUPPRIMER L'EMPLACEMENT",
    "equipment.Você deseja excluir esta localização?": "Voulez-vous supprimer cet emplacement ?",
    "equipment.Ao fazer isso, os equipamentos atrelados à ela não exibirão nenhuma localização, sendo necessário informar um novo local para eles.": "Ce faisant, les équipements qui y sont liés n'afficheront aucun emplacement, ce qui nécessitera de leur fournir un nouvel emplacement.",
    "equipment.Localização deletada com sucesso!": "Emplacement supprimé avec succès !",
    "equipment.Erro ao deletar a localização. Tente novamente mais tarde": "Erreur lors de la suppression de l'emplacement. Réessayez plus tard",
    "equipment.Não foi possivel deletar a localização": "Impossible de supprimer l'emplacement",
    "equipment.Localização adicionada com sucesso!": "Emplacement ajouté avec succès !",
    "equipment.Não foi possível adicionar a localização. Tente novamente mais tarde": "Impossible d'ajouter un emplacement. Réessayez plus tard",
    "equipment.Nome do espaço": "Nom de l'espace",
    "equipment.Informe o nome do espaço": "Entrez le nom de l'espace",
    "equipment.Preencha o nome do espaço": "Remplissez le nom de l'espace",
    "equipment.país": "Pays",
    "equipment.Endereço (rua, avenida, rodovia)": "Adresse (rue, avenue, autoroute)",
    "equipment.Nº/Km": "Nbre/Km",
    "equipment.informe o país": "Entrez le pays",
    "equipment.preencha o país": "Remplissez le pays",
    "equipment.Localização editada com sucesso!": "Emplacement modifié avec succès !",
    "equipment.Não foi possível editar a localização. Tente novamente mais tarde": "Impossible de modifier l'emplacement. Réessayez plus tard",
    "equipment.Editar localização": "Modifier l'emplacement",
    "equipment.Pico de execuções": "Pic des exécutions",
    "equipment.informações da semana": "les informations de la semaine",
    "equipment.horários de pico": "heures de pointe",
    "equipment.horário de pico": "heure de pointe",
    "equipment.execuções do dia": "exécutions du jour",
    "equipment.Horário": "Temps",
    "equipment.Execuções": "exécutions",
    "equipment.dia selecionado": "jour sélectionné",
    "equipment.Vinculação IOK": "Liaison IOK",
    "equipment.Última execução": "Dernier tour",
    "equipment.A limpeza adequada e diária do forno auxilia em seu funcionamento correto e impacta na garantia.": "Un nettoyage approprié et quotidien du four contribue à son bon fonctionnement et a un impact sur la garantie.",
    "equipment.Total de limpezas realizadas:": "Nettoyage total effectué :",
    "equipment.Limpezas não concluídas:": "Ménages non terminés :",
    "equipment.Desde a vinculação do forno a IOK": "De la connexion du four à IOK",
    "equipment.Equipamento ligado há:": "Les équipements connectés sont :",
    "equipment.Total de receitas executadas:": "Total des revenus exécutés :",
    "equipment.Receitas executadas:": "Revenus exécutés :",
    "equipment.Aberturas de porta:": "Ouvertures de portes :",
    "equipment.Desde a última vez que o forno foi ligado": "Depuis la dernière fois que le four a été allumé",
    "equipment.Execução": "Exécution",
    "equipment.Nenhuma localização criada": "Aucun emplacement créé",
    "equipment.Acesso remoto": "Accès à distance",
    "equipment.Informe o ID da empresa que poderá acessar remotamente seu forno. Use ; para separar IDs.": "Saisissez l'identifiant de l'entreprise qui peut accéder à distance à votre four. Utiliser ; pour séparer les identifiants.",
    "equipment.O acesso remoto permite que as empresas selecionadas enviem menus e atualizações para seu equipamento.": "L'accès à distance permet aux entreprises sélectionnées d'envoyer des menus et des mises à jour à votre équipement.",
    "equipment.Concedo acesso remoto à Prática oficial.": "J'accorde l'accès à distance à la Prática officielle.",
    "equipment.Deseja selecionar outros equipamentos vinculados à sua conta para conceder acesso remoto a eles?": "Souhaitez-vous sélectionner d'autres appareils liés à votre compte pour leur accorder un accès à distance ?",
    "equipment.Selecione abaixo os equipamentos para os quais deseja conceder acesso remoto à": "Sélectionnez ci-dessous l'équipement pour lequel vous souhaitez accorder l'accès à distance au",
    "equipment.Acesso remoto concedido com sucesso!": "Accès à distance accordé avec succès !",
    "equipment.Selecione pelo menos um equipamento": "Sélectionnez au moins un appareil",
    "equipment.Não foi possível editar os equipamentos. Tente novamente mais tarde": "Impossible de modifier l'équipement. Réessayez plus tard",
    "equipment.Solicitar atendimento": "Demander un service",
    "equipment.Relate um problema": "Signaler un problème",
    "equipment.Descreva aqui o problema de seu equipamento": "Décrivez ici votre problème d'équipement",
    "equipment.Não foi possível enviar sua solicitação de atendimento. Tente mais tarde": "Nous n'avons pas pu envoyer votre demande de service. Essayez plus tard",
    "equipment.Solicitação técnica enviada com sucesso!": "Demande technique envoyée avec succès !",
    "equipment.Enviar": "Envoyer",
    "equipment.Solicitação de atendimento": "Demande de service",
    "equipment.Informações do magnetron": "Informations sur le magnétron",
    "equipment.Vida útil aproximada": "Durée de vie approximative",
    "equipment.MAGNETRON USAGE HOURS": "Heures d'utilisation du magnétron",
    "equipment.Atendimento": "Service",
    "equipment.Eventos mais ocorridos no equipamento durante o período": "Evénements les plus survenus sur les équipements au cours de la période",
    "equipment.EQUIPAMENTO SEM NÚMERO DE SÉRIE": "ÉQUIPEMENT SANS NUMÉRO DE SÉRIE",
    "equipment.Filtros": "Filtres",
    "equipment.Filtrar": "Filtrer",
    "equipment.Limpar filtros": "Effacer les filtres",
    "equipment.Procurar por modelo, versão ou país": "Rechercher par modèle, version ou pays",
    "equipment.Procurar equipamento, NS, modelo, versão ou país": "Rechercher un équipement, NS, modèle, version ou pays",
    "equipment.Os seguintes IDs não pertencem a uma companhia do tipo 'Distribuidor' e foram removidos": "Les ID suivants n'appartiennent pas à une entreprise de type 'Distributeur' et ont été supprimés",
    "equipment.BAIXAR VERSÕES": "VERSIONS À TÉLÉCHARGER",
    "equipment.Compatível com todas as versões da IHM.": "Compatible avec toutes les versions d'IHM.",
    "equipment.Compatível apenas com a versão 2.0.0C da Power.": "Compatible avec la version Power 2.0.0C uniquement.",

    //LOJAS
    "store.Procurar loja": "Rechercher un magasin",
    "store.Excluir": "Supprimer",
    "store.Editar": "Modifier",
    "store.nome": "Nom",
    "store.equipamentos": "Équipement",
    "store.cidade": "Ville",
    "store.estado": "État",
    "store.salvar": "Enregistrer",
    "store.cadastrar loja": "Enregistrer le magasin",
    "store.Nome fantasia": "Nom fantaisie",
    "store.Preencha o nome da loja": "Remplissez le nom du magasin",
    "store.CNPJ": "CNPJ",
    "store.Preencha o CNPJ da loja": "Remplissez le CNPJ du magasin",
    "store.CEP": "Code postal",
    "store.Preencha o CEP da loja": "Remplissez le code postal du magasin",
    "store.Informe o nome fantasia": "Remplissez le nom fantaisie",
    "store.Cidade": "Ville",
    "store.Informe a cidade": "Entrez la ville",
    "store.Preencha a cidade": "Remplissez la ville",
    "store.UF": "État",
    "store.Preencha o UF da loja": "Remplissez l'état du magasin",
    "store.Bairro": "Quartier",
    "store.Informe o bairro": "Entrez le quartier",
    "store.Preencha o bairro da loja": "Remplissez le quartier du magasin",
    "store.Endereço": "Adresse",
    "store.Informe o endereço": "Entrez l'adresse",
    "store.Preencha o endereço da loja": "Remplissez l'adresse du magasin",
    "store.Nº": "N°",
    "store.Loja cadastrada com sucesso": "Magasin enregistré avec succès",
    "store.Erro ao adicionar loja. Tente novamente": "Erreur lors de l'ajout du magasin. Réessayez",
    "store.Loja deletada com sucesso": "Magasin supprimé avec succès",
    "store.Erro ao deletar loja. Tente novamente mais tarde": "Erreur lors de la suppression du magasin. Réessayez plus tard",
    "store.AINDA NÃO HÁ NENHUMA LOJA CADASTRADA :(": "IL N'Y A TOUJOURS AUCUN MAGASIN ENREGISTRÉ :(",
    "store.EXCLUIR LOJA": "SUPPRIMER LE MAGASIN",
    "store.Você deseja excluir a loja da plataforma IOK?": "Souhaitez-vous supprimer le magasin de la plateforme IOK ?",
    "store.Ao fazer isso, os equipamentos atrelados à ela não terão vínculo com nenhuma loja, a menos que os vincule novamente.": "En le faisant, l'équipement lié à celui-ci ne sera lié à aucun magasin, à moins que vous ne les relinkiez.",
    "store.loja editada com sucesso": "Magasin modifié avec succès",
    "store.não foi possível editar a loja. Tente novamente mais tarde": "Il n'a pas été possible de modifier le magasin. Réessayez plus tard.",
    "store.editar loja": "Modifier le magasin",
    "store.Procurar grupo de equipamentos": "Groupe d'équipement de recherche",
    "store.cadastrar grupo": "inscrire un groupe",
    "store.AINDA NÃO HÁ NENHUM GRUPO CADASTRADO :(": "IL N'Y A PAS ENCORE DE GROUPE ENREGISTRÉ :(",
    "store.cadastrar grupo de equipamentos": "enregistrer un groupe d'équipement",
    "store.grupo de equipamentos cadastrado com sucesso!": "Groupe d'équipement enregistré avec succès!",
    "store.não foi possível cadastrar o grupo. Tente novamente mais tarde": "Impossible d'enregistrer le groupe. Réessayez plus tard",
    "store.Nome do grupo": "Nom du groupe",
    "store.informe o nome do grupo": "Entrez le nom du groupe",
    "store.preencha o nome do grupo": "Remplissez le nom du groupe",
    "store.Grupo de equipamentos deletado com sucesso!": "Groupe d'équipements supprimé avec succès!",
    "store.Erro ao deletar o grupo. Tente novamente mais tarde": "Erreur lors de la suppression du groupe. Réessayez plus tard",
    "store.EXCLUIR GRUPO DE EQUIPAMENTOS": "SUPPRIMER LE GROUPE D'ÉQUIPEMENT",
    "store.Você deseja excluir este grupo de equipamentos da plataforma IOK?": "Souhaitez-vous supprimer ce groupe d'équipements de la plateforme IOK ?",
    "store.Ao fazer isso, os equipamentos atrelados à ele não terão vínculo com nenhum grupo, a menos que os vincule novamente.": "Lorsque vous faites cela, les équipements qui y sont liés ne seront liés à aucun groupe, à moins que vous ne les liez à nouveau.",
    "store.grupo de equipamentos editado com sucesso!": "Groupe d'équipements modifié avec succès!",
    "store.não foi possível editar o grupo. Tente novamente mais tarde": "Impossible de modifier le groupe. Réessayez plus tard",
    "store.editar grupo de equipamentos": "modifier le groupe d'équipement",
    "store.CEP não encontrado no Brasil": "Code postal introuvable au Brésil",

    //NAVBAR
    "navbar.home": "Accueil",
    "navbar.cookbook": "Livre de recettes",
    "navbar.my menus": "Mes menus",
    "navbar.equipment": "Équipement",
    "navbar.users": "Utilisateurs",
    "navbar.calls": "Services",
    "navbar.settings": "Paramètres",
    "navbar.help": "Aide",
    "navbar.logout": "Déconnexion",
    "navbar.Editar conta": "Modifier le compte",
    "navbar.API Externa": "API externe",

    //FOOTERBAR
    "footerBar.menu": "Menu",
    "footerBar.cookbook": "Livre de recettes",
    "footerBar.home": "Accueil",
    "footerBar.menus": "Menus",
    "footerBar.notifications": "Notifications",

    //ATENDIMENTOS
    "calls.ATENDIMENTOS EM ABERTO": "SERVICES EN COURS",
    "calls.ATENDIMENTOS ENCERRADOS": "SERVICES FERMÉS",

    //MAPA
    "map.título": "Emplacement",
    "map.erro": "Erreur",
    "map.mensagem de erro": "Impossible de charger la carte",
    "map.Localização não disponível": "Localisation non disponible",

    //MODAL
    "modal.CONFIRMAR": "CONFIRMER",
    "modal.Salvar alterações": "Enregistrer les modifications",
    "Modal.Há alterações não salvas nos parâmetros dos passos": "Il y a des modifications non enregistrées aux paramètres de l'étape.",
    "Modal.Como deseja proceder?": "Comment souhaitez-vous procéder ?",
    "Modal.Avaliar alterações realizadas": "Évaluer les modifications apportées",
    "Modal.Descartar alterações": "Ignorer les modifications",

    //USUÁRIOS
    "user.Nome": "Nom",
    "user.E-mail": "E-mail",
    "user.Permissão": "Autorisation",
    "user.Loja": "Magasin",
    "user.Status": "Statut",
    "user.Excluir": "Supprimer",
    "user.Editar": "Éditer",
    "user.Procurar usuário": "Rechercher un utilisateur",
    "user.ADICIONAR USUÁRIO": "AJOUTER UN UTILISATEUR",
    "user.AINDA NÃO HÁ NENHUM USUÁRIO CADASTRADO :(": "IL N'Y A PAS ENCORE D'UTILISATEURS ENREGISTRÉS :(",
    "user.+ Usuário": "+ Utilisateur",
    "user.Informe o nome do usuário": "Entrez le nom d'utilisateur",
    "user.Preencha o nome do usuário": "Remplissez le nom d'utilisateur",
    "user.Informe o e-mail do usuário": "Entrez l'e-mail de l'utilisateur",
    "user.Preencha com um e-mail válido": "Remplissez un email valide",
    "user.Administrador": "Administrateur",
    "user.Usuário": "Utilisateur",
    "user.Preencha o telefone do usuário": "Remplissez le numéro de téléphone de l'utilisateur",
    "user.Selecione a permissão do usuário": "Sélectionnez l'autorisation de l'utilisateur",
    "user.Ativo": "Actif",
    "user.Inativo": "Inactif",
    "user.Usuário deletado da plataforma com sucesso!": "Utilisateur supprimé avec succès de la plateforme!",
    "user.Ocorreu um erro. Tente novamente mais tarde": "Une erreur est survenue. Réessayez plus tard",
    "user.EXCLUIR USUÁRIO": "SUPPRIMER L'UTILISATEUR",
    "user.delete.paragrafo 1": "Souhaitez-vous supprimer l'utilisateur de la plateforme IOK?",
    "user.delete.paragrafo 2": "En faisant cela, ils perdront l'accès à tous les outils de la plateforme, sauf s'ils y sont ajoutés à nouveau en suivant les instructions d'inscription.",
    "user.EDITAR USUÁRIO": "MODIFIER L'UTILISATEUR",
    "user.Usuário editado com sucesso!": "Utilisateur modifié avec succès!",
    "user.Não foi possível editar este usuário. Por favor, tente mais tarde": "Il n'a pas été possible de modifier cet utilisateur. Veuillez réessayer plus tard.",
    "user.INFORMAÇÕES DO USUÁRIO": "INFORMATIONS SUR L'UTILISATEUR",
    "user.Telefone": "Téléphone",
    "user.Não foi possível cadastrar este usuário. Por favor, tente mais tarde": "Il n'a pas été possible d'enregistrer cet utilisateur. Veuillez réessayer plus tard.",
    "user.Usuário adicionado com sucesso!": "Utilisateur ajouté avec succès!",
    "user.CRIAR TIPO DE USUÁRIO": "CRÉER UN TYPE D'UTILISATEUR",
    "user.Criar novo tipo de usuário": "Créer un nouveau type d'utilisateur",
    "user.Adicionar novo usuário": "Ajouter un nouvel utilisateur",
    "user.Permissões": "Permissions",
    "user.Preencha o nome do novo tipo de usuário": "Remplissez le nom du nouveau type d'utilisateur",
    "user.Selecione pelo menos uma permissão": "Sélectionnez au moins une autorisation",
    "user.Não foi possível carregar as permissões": "Il n'a pas été possible de charger les autorisations",
    "user.Tipo de usuário criado com sucesso!": "Type d'utilisateur créé avec succès!",
    "user.Não foi possível criar este novo tipo de usuário. Por favor, tente mais tarde": "Il n'a pas été possible de créer ce nouveau type d'utilisateur. Veuillez réessayer plus tard.",
    "user.Não foi possível carregar os tipos de usuário da companhia": "Il n'a pas été possible de charger les types d'utilisateurs de l'entreprise",
    "user.Tipo de usuário": "Type d'utilisateur",
    "user.E-mail já cadastrado na plataforma. Informe um novo e-mail": "E-mail déjà enregistré sur la plateforme. Entrez un nouvel e-mail",
    "user.UNIR CONTAS": "REJOINDRE LES COMPTES",
    "user.ID da companhia": "Identifiant de l'entreprise",
    "user.Digite o ID da companhia que deseja unir a sua conta": "Entrez l'ID de l'entreprise avec laquelle vous souhaitez fusionner votre compte",
    "user.Preencha o ID da companhia": "Remplissez l'identifiant de l'entreprise",
    "user.O administrador da conta com a qual deseja se unir poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.":
      "L'administrateur du compte que vous souhaitez rejoindre pourra retrouver l'identifiant en cliquant sur votre nom d'utilisateur, situé dans le menu du haut, dans le coin droit de l'écran.",
    "user.O administrador da outra conta receberá um e-mail para aceitar sua solicitação. Assim que for aceita, a união das duas contas será realizada!":
      "L'administrateur de l'autre compte recevra un email pour accepter votre demande. Une fois acceptés, les deux comptes seront fusionnés!",
    "user.Não foi possível solicitar a união de contas. Por favor, confirme o ID ou tente mais tarde": "Impossible de demander la fusion de comptes. Veuillez confirmer votre pièce d'identité ou réessayer plus tard.",
    "user.SOLICITAÇÃO APROVADA COM SUCESSO": "DEMANDE APPROUVÉE AVEC SUCCÈS",
    "user.AGUARDE ENQUANTO REALIZAMOS A UNIÃO DAS CONTAS. ESTE PROCESSO PODE LEVAR ALGUNS MINUTOS...": "VEUILLEZ ATTENDRE PENDANT QUE NOUS CONNECTONS LES COMPTES. CE PROCESSUS PEUT DURER QUELQUES MINUTES...",
    "user.UNIÃO DE CONTAS FINALIZADA COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "UNION DES COMPTES RÉALISÉE AVEC SUCCÈS ! CLIQUEZ SUR LE BOUTON CI-DESSOUS POUR ACCÉDER À LA PLATEFORME.",
    "user.NÃO CONSEGUIMOS REALIZAR A UNIÃO DAS CONTAS. POR FAVOR, TENTE NOVAMENTE. SE O PROBLEMA PERSISTIR, ENTRE EM CONTATO COM NOSSO SUPORTE.":
      "NOUS NE POUVONS PAS FUSIONNER LES COMPTES. VEUILLEZ RÉESSAYER. SI LE PROBLÈME PERSISTE, VEUILLEZ CONTACTER NOTRE SUPPORT.",
    "user.possui todos os acessos": "a tous accès",
    "user.possui o acesso das abas cookbook, meus menus e equipamentos": "a accès au livre de recettes, à mes menus et aux onglets équipements",
    "user.possui apenas o acesso da aba cookbook": "n'a accès qu'à l'onglet du livre de recettes",
    "user.possui apenas o acesso da aba equipamentos": "n'a accès qu'à l'onglet équipement",
    "user.possui apenas o acesso da aba meus menus": "n'a accès qu'à l'onglet mes menus",
    "user.Grupo de equipamentos": "Groupe d'équipement",
    "user.Informe o telefone do usuário": "Entrez le numéro de téléphone de l'utilisateur",
    "user.Nenhum grupo criado": "Aucun groupe créé",
    "user.Digite o ID da companhia que terá acesso à sua conta": "Saisissez l'identifiant de l'entreprise qui aura accès à votre compte",
    "user.Ao informar um ID para unir contas com sua companhia, esteja ciente de que o novo usuário também se tornará Administrador da conta de sua companhia. É importante informar que a partir do momento em que um usuário aceitar unir contas com sua companhia, a companhia pertencente a ele deixará de existir.":
      "Lorsque vous fournissez un identifiant pour fusionner des comptes avec votre entreprise, sachez que le nouvel utilisateur deviendra également administrateur du compte de votre entreprise. Il est important d'informer qu'à partir du moment où un utilisateur accepte de rejoindre des comptes dans votre entreprise, l'entreprise qui lui appartient cessera d'exister.",
    "user.Exemplo: Sua conta: 001 | Outra conta: 111. Depois de unir contas com você, a outra conta passará a ser 001.": "Exemple : Votre compte : 001 | Un autre compte : 111. Après avoir fusionné les comptes avec vous, l'autre compte deviendra 001.",
    "user.O administrador da outra conta poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.": "L'administrateur de l'autre compte pourra localiser l'identifiant en cliquant sur votre nom d'utilisateur, situé dans le menu supérieur, dans le coin droit de l'écran.",

    //TUTORIAIS 
    "tutorial.Fechar": "Fermer",
    "tutorial.Fim": "Terminer",
    "tutorial.Próximo": "Suivant",
    "tutorial.Abrir": "Ouvrir",
    "tutorial.Pular tutorial": "Ignorer le tutoriel",
    "tutorial.Editar detalhes da receita": "Modifier les détails de la recette",
    "tutorial.Agora você pode adicionar mais detalhes às suas receitas e deixá-las ainda mais completas. Clique em Editar detalhes e preencha as informações necessárias.": "Maintenant, vous pouvez ajouter plus de détails à vos recettes et les rendre encore plus complètes. Cliquez sur Modifier les détails et remplissez les informations nécessaires.",
    "tutorial.Salvar edição da receita": "Enregistrer les modifications de la recette",
    "tutorial.Depois de informar mais detalhes da receita, clique em Salvar.": "Après avoir ajouté plus de détails à votre recette, cliquez sur Enregistrer.",
    "tutorial.Editar registro de um equipamento": "Modifier l'enregistrement de l'équipement",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Categoria e Loja.": "Pour compléter l'enregistrement de votre équipement, ouvrez le menu latéral, cliquez sur Modifier et remplissez les informations de catégorie et de magasin.",
    "tutorial.Editar usuário": "Modifier l'utilisateur",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente a Loja a qual ele faz parte.": "Pour compléter l'enregistrement de l'utilisateur, ouvrez le menu latéral, cliquez sur Modifier et remplissez les informations concernant le magasin auquel il appartient.",
    "tutorial.Salvar edições de um equipamento": "Enregistrer les modifications de l'équipement",
    "tutorial.Depois de completar o registro de seus equipamentos, clique em Salvar.": "Après avoir complété l'enregistrement de votre équipement, cliquez sur Enregistrer.",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em uma loja, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "En cliquant sur la ligne d'un équipement enregistré dans un magasin, vous pouvez consulter plus de statistiques et d'informations à son sujet, en plus de pouvoir demander un service ou déclencher des mises à jour.",
    "tutorial.Para visualizar mais informações sobre a loja, como localização e receitas mais executadas, clique sobre a linha da unidade que deseja analisar.": "Pour consulter plus d'informations sur un magasin, telles que l'emplacement et les recettes les plus exécutées, cliquez sur la ligne de l'unité que vous souhaitez analyser.",
    "tutorial.Criar receita": "Créer une recette",
    "tutorial.No cookbook você pode criar suas próprias receitas! Clique em Criar Receita e preencha todas as informações necessárias.": "Dans le livre de recettes, vous pouvez créer vos propres recettes ! Cliquez sur Créer une recette et remplissez toutes les informations nécessaires.",
    "tutorial.Editar ou excluir um usuário": "Modifier ou supprimer un utilisateur",
    "tutorial.Para excluir ou editar as informações de um usuário já cadastrado, clique no menu lateral e selecione a opção desejada.": "Pour supprimer ou modifier les informations d'un utilisateur enregistré, cliquez sur le menu latéral et sélectionnez l'option désirée.",
    "tutorial.Gerenciamento de usuários": "Gestion des utilisateurs",
    "tutorial.Aqui você pode gerenciar seus usuários com mais facilidade. Ao clicar em + Usuário, você poderá adicionar um novo usuário ou criar um novo tipo de usuário.": "Ici, vous pouvez gérer vos utilisateurs plus facilement. En cliquant sur + Utilisateur, vous pouvez ajouter un nouvel utilisateur ou créer un nouveau type d'utilisateur.",
    "tutorial.Suas receitas": "Vos recettes",
    "tutorial.Pelo cookbook você também encontra suas próprias receitas, além de poder criá-las! Clique em Receitas Salvas para visualizar as receitas que foram salvas do cookbook da Prática e também as criadas por você.": "À travers le livre de recettes, vous pouvez également trouver vos propres recettes, en plus de pouvoir les créer ! Cliquez sur Recettes enregistrées pour voir les recettes enregistrées dans le livre de recettes de Prática et celles créées par vous.",
    "tutorial.Filtros do Cookbook": "Filtres du livre de recettes",
    "tutorial.Para facilitar a navegação pelas receitas, você pode aplicar filtros a elas. Basta clicar no filtro desejado e escolher uma de suas opções.": "Pour faciliter la navigation dans les recettes, vous pouvez appliquer des filtres. Il vous suffit de cliquer sur le filtre désiré et de choisir l'une de ses options.",
    "tutorial.Cookbook": "Livre de recettes",
    "tutorial.No cookbook, em Receitas Disponíveis, você encontrará as receitas criadas pelos Chefs da Prática e poderá utilizá-las sempre que quiser.": "Dans le livre de recettes, dans les Recettes disponibles, vous trouverez les recettes créées par les chefs de Prática et pourrez les utiliser quand vous le souhaitez.",
    "tutorial.Atualizar equipamentos": "Mettre à jour l'équipement",
    "tutorial.Para enviar uma atualização a seus equipamentos, clique em Atualizar Equipamentos, selecione os modelos que deseja atualizar e a forma como deseja realizar essa atualização - via wi-fi ou pen drive. Depois disso, clique em Confirmar.": "Pour envoyer une mise à jour à votre équipement, cliquez sur Mettre à jour l'équipement, sélectionnez les modèles que vous souhaitez mettre à jour et la manière dont vous souhaitez le faire - via Wi-Fi ou clé USB. Ensuite, cliquez sur Confirmer.",
    "tutorial.Adicionar equipamento": "Ajouter un équipement",
    "tutorial.Para adicionar um equipamento à sua conta, clique em Adicionar Equipamento e preencha as informações do modal. Depois disso, clique em Salvar.": "Pour ajouter un équipement à votre compte, cliquez sur Ajouter un équipement et remplissez les informations du formulaire. Ensuite, cliquez sur Enregistrer.",
    "tutorial.Editar equipamento": "Modifier l'équipement",
    "tutorial.Para desvincular um equipamento ou editar suas informações, clique no menu lateral e selecione a opção desejada.": "Pour dissocier un équipement ou modifier ses informations, cliquez sur le menu latéral et sélectionnez l'option désirée.",
    "tutorial.Seus equipamentos": "Votre équipement",
    "tutorial.Aqui você visualizará todos os equipamentos cadastrados em sua plataforma.": "Ici, vous verrez tous les équipements enregistrés sur votre plateforme.",
    "tutorial.Restaurar arquivos": "Restaurer des fichiers",
    "tutorial.Para restaurar um arquivo excluído ou deletá-lo permanentemente, clique no menu lateral e selecione a opção desejada.": "Pour restaurer un fichier supprimé ou le supprimer définitivement, cliquez sur le menu latéral et sélectionnez l'option désirée.",
    "tutorial.Lixeira": "Corbeille",
    "tutorial.Os menus excluídos serão mantidos na Lixeira por 30 dias. Depois desse prazo serão excluídos permanentemente.": "Les menus supprimés seront conservés dans la corbeille pendant 30 jours. Passé ce délai, ils seront définitivement supprimés.",
    "tutorial.Salvar edições de usuário": "Enregistrer les modifications de l'utilisateur",
    "tutorial.Depois de completar o cadastro dos usuários, clique em Salvar.": "Après avoir complété l'enregistrement de l'utilisateur, cliquez sur Enregistrer.",
    "tutorial.Criar menu": "Créer un menu",
    "tutorial.Para criar e personalizar um menu, clique em Criar Menu e preencha as informações necessárias no modal. Você pode editar um menu a qualquer momento.": "Pour créer et personnaliser un menu, cliquez sur Créer un menu et remplissez les informations nécessaires dans le formulaire. Vous pouvez modifier un menu à tout moment.",
    "tutorial.Importar menu": "Importer un menu",
    "tutorial.Para importar um menu, clique em Importar Menu e selecione o modelo e versão do equipamento.": "Pour importer un menu, cliquez sur Importer un menu et sélectionnez le modèle et la version de l'équipement.",
    "tutorial.Criar grupo": "Créer un groupe",
    "tutorial.Para organizar suas receitas em grupos, clique em Criar Grupo e preencha as informações necessárias no modal.": "Pour gérer vos recettes de groupe, cliquez sur Créer un groupe et remplissez les informations nécessaires dans le formulaire.",
    "tutorial.Tipos de usuário": "Types d'utilisateurs",
    "tutorial.Agora você também pode criar tipos de usuários com permissões específicas para atender melhor a sua organização.": "Vous pouvez désormais également créer des types d'utilisateurs dotés d'autorisations spécifiques pour mieux servir votre organisation.",
    "tutorial.Gerenciamento de contas": "Gestion de compte",
    "tutorial.Se caso outra pessoa da sua organização também migrou a conta principal da empresa, aqui você pode unir as duas contas.": "Si quelqu'un d'autre dans votre organisation a également migré son compte principal d'entreprise, vous pouvez fusionner ici les deux comptes.",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Grupo.": "Pour terminer l'enregistrement de votre équipement, ouvrez le menu latéral, cliquez sur Modifier et remplissez les informations du groupe.",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente ao Grupo de equipamentos a qual ele faz parte.": "Pour terminer l'enregistrement de l'utilisateur, ouvrez le menu latéral, cliquez sur Modifier et remplissez les informations concernant le groupe d'équipements auquel il appartient.",
    "tutorial.Grupos de equipamentos": "Groupes d'équipements",
    "tutorial.Na aba Grupos você pode visualizar os grupos cadastrados e os equipamentos distribuídos entre eles. Além disso, é possível cadastrar novos grupos e editar os já cadastrados.": "Dans l'onglet Groupes, vous pouvez visualiser les groupes enregistrés et les équipements répartis entre eux. De plus, il est possible d'enregistrer de nouveaux groupes et de modifier ceux déjà enregistrés.",
    "tutorial.Detalhes de grupos": "Détails du groupe",
    "tutorial.Para visualizar mais informações sobre o grupo, como localização e receitas mais executadas, clique sobre a linha que deseja analisar.": "Pour afficher plus d'informations sur le groupe, telles que l'emplacement et les recettes les plus exécutées, cliquez sur la ligne que vous souhaitez analyser.",
    "tutorial.Equipamentos em grupos": "Équipement en groupe",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em um grupo, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "En cliquant sur la ligne d'un appareil enregistré dans un groupe, vous pouvez visualiser plus de statistiques et d'informations le concernant, en plus de pouvoir demander un service ou déclencher des mises à jour.",
    "tutorial.Grupos": "Groupes",
    "tutorial.Atualização e cache": "Mise à jour et cache",
    "tutorial.Para atualizar a plataforma e corrigir eventuais problemas de cache, clique aqui.": "Pour mettre à jour la plateforme et corriger les éventuels problèmes de cache, cliquez ici.",
    "tutorial.Dados dos equipamentos": "Données des équipements",
    "tutorial.Ao clicar sobre a linha de um equipamento, você pode visualizar mais estatísticas e informações sobre ele, como as receitas mais executadas, limpezas realizadas e outros dados relevantes.": "En cliquant sur la ligne d'un équipement, vous pouvez voir plus de statistiques et d'informations à son sujet, telles que les recettes les plus exécutées, les nettoyages effectués et d'autres données pertinentes.",
    "tutorial.Para alterar o idioma de sua plataforma, clique em Idioma e selecione a linguagem desejada.": "Pour changer la langue de votre plateforme, cliquez sur Langue et sélectionnez la langue souhaitée.",
    "tutorial.Alterar idioma": "Changer la langue",
    "tutorial.Para editar ou configurar sua conta, clique em seu nome e selecione a opção desejada.": "Pour modifier ou configurer votre compte, cliquez sur votre nom et sélectionnez l'option souhaitée.",
    "tutorial.Editar conta": "Modifier le compte",
    "tutorial.Total de Usuários Cadastrados em sua conta.": "Nombre total d'utilisateurs enregistrés dans votre compte.",
    "tutorial.Home": "Home",
    "tutorial.Localização de seus equipamentos, sendo possível expandir o mapa para melhor visualização;": "Localisation de votre équipement, permettant d'agrandir la carte pour une meilleure visualisation ;",
    "tutorial.Atualizações referentes a versão dos equipamentos vinculados a sua conta;": "Mises à jour concernant la version de l'équipement lié à votre compte ;",
    "tutorial.Total de Receitas Cadastradas;": "Revenu total enregistré ;",
    "tutorial.Menus Disponíveis;": "Menus disponibles ;",
    "tutorial.Esta é a sua home! Aqui você encontra um resumo geral sobre sua conta, como quantidade de Equipamentos Vinculados;": "Ceci est votre page d'accueil ! Vous trouverez ici un récapitulatif général de votre compte, tel que le nombre d'équipements liés ;",
    "tutorial.Clique nas abas do menu para explorar a plataforma e conhecer tudo o que ela pode oferecer.": "Cliquez sur les onglets du menu pour explorer la plateforme et découvrir tout ce qu'elle peut offrir.",
    "tutorial.Exportar menu": "Menu Exporter",
    "tutorial.Depois de personalizar seu menu, exporte-o para seus equipamentos pela internet ou por pen drive.": "Après avoir personnalisé votre menu, exportez-le sur vos appareils via Internet ou via une clé USB.",
    "tutorial.Na aba Localização você pode visualizar os endereços já cadastrados, além de criar localizações precisas para vincular seus equipamentos a elas.": "Dans l'onglet Localisation, vous pouvez visualiser les adresses déjà enregistrées, en plus de créer des localisations précises pour y relier vos équipements.",
    "tutorial.Localização": "Emplacement",

    //RECOVER ACCOUNT
    "recoverAccount.Para prosseguir, selecione a categoria na qual você se encaixa:": "Pour continuer, sélectionnez la catégorie qui vous correspond:",
    "recoverAccount.Deseja acessar a plataforma como DISTRIBUIDOR? Crie sua conta clicando em EMPRESA e entre em contato conosco para liberarmos os demais acessos.":
      "Souhaitez-vous accéder à la plateforme en tant que DISTRIBUTEUR? Créez votre compte en cliquant sur SOCIÉTÉ et contactez-nous pour libérer les autres accès.",
    "recoverAccount.A qual organização você pertence": "À quelle organisation appartenez-vous?",

    //RECOVER PASSWORD
    "recoverPassword.E-mail para recuperação de senha": "Souhaitez-vous accéder à la plateforme en tant que DISTRIBUTEUR? Créez votre compte en cliquant sur SOCIÉTÉ et contactez-nous pour libérer les autres accès.",
    "recoverPassword.Informe o e-mail cadastrado na plataforma": "À quelle organisation appartenez-vous?",
    "recoverPassword.E-mail inválido. Por favor, verifique": "E-mail pour la récupération du mot de passe",
    "recoverPassword.RECUPERAR SENHA": "Indiquez l'e-mail enregistré sur la plateforme",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL COM O LINK DE RECUPERAÇÃO DE SENHA": "E-mail invalide. Veuillez vérifier.",
    "recoverPassword.Login": "RÉCUPÉRER LE MOT DE PASSE",
    "recoverPassword.Informe seu e-mail": "DANS UN INSTANT, VOUS RECEVREZ UN E-MAIL AVEC LE LIEN DE RÉCUPÉRATION DU MOT DE PASSE",
    "recoverPassword.Crie uma nova senha": "Connexion",
    "recoverPassword.O campo senha deve conter no mínimo 6 caracteres": "Indiquez votre e-mail",
    "recoverPassword.Confirme sua senha": "Créez un nouveau mot de passe",
    "recoverPassword.As senhas devem ser iguais": "Le champ du mot de passe doit comporter au moins 6 caractères",
    "recoverPassword.ENTRAR": "Confirmez le mot de passe",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL PARA CONFIRMAR SUA IDENTIDADE": "Les mots de passe doivent correspondre",
    "recoverPassword.CONTINUAR": "ENTRER",
    "recoverPassword.CONFIRMAR": "CONFIRMER",

    // EXPORT
    "export.Limpezas": "Nettoyages",
    "export.Eventos": "Événements",
    "export.Lista de receitas executadas": "Liste des recettes exécutées",
    "export.Lista de limpezas executadas": "Liste des nettoyages effectués",
    "export.Lista de eventos executados": "Liste des événements exécutés",
    "export.Exportar dados": "Exporter les données",
    "export.Informações do equipamento": "Informations sur l'équipement",
    "export.Dados exportados com sucesso!": "Données exportées avec succès!",
    "export.Não foi possível exportar os dados. Por favor, tente novamente mais tarde.": "Impossible d'exporter les données. Veuillez réessayer plus tard.",
    "export.Detalhes do menu": "Détails du menu",
    "export.Detalhes de receitas": "Détails des recettes",
    "export.Detalhes e Passos": "Détails et Étapes",
    "export.Exportando dados. Em breve o arquivo estará disponível!": "Exportation des données. Le fichier sera bientôt disponible!",
    "export.Resumo dos passos": "enregistrer l'image",
    "export.salvar imagem": "Résumé des étapes"
  },
};
export default fr;
