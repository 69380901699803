/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { ChangeEvent, FC, useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';
import * as Styled from './styles';
import { validate } from '../../../utils/validators';
import { validatorType } from '../../../utils/validators';

type InputRadioProps = {
  label: string;
  name?: string;
  type: string;
  value?: any;
  id: string;
  disabled?: boolean;
  default?: boolean;
  checked?: boolean;
  initialValue?: any;
  initialValid?: boolean;
  validators?: validatorType[];
  isDisabled?: boolean;
  onInput?: (id: string, value: string, isValid: boolean) => void;
  onChecked?: (event: ChangeEvent<HTMLInputElement>) => void;
  labelName?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  hover?: boolean;
  translateLabel?: boolean;
}

type StateType = {
  value: string;
  isValid: boolean;
  isTouched: boolean;
};

type ChangeActionType = {
  type: 'CHANGE';
  payload: {
    value: string;
    validators: validatorType[] | undefined;
  };
};


type TouchActionType = {
  type: 'TOUCH';
};

const inputReducer = (
  state: StateType,
  action: ChangeActionType | TouchActionType
) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        value: action.payload.value,
        isValid: action.payload.validators
          ? validate(action.payload.value, action.payload.validators)
          : true,
      };
    case 'TOUCH':
      return {
        ...state,
        isTouched: true,
      };

    default:
      return state;
  }
};

const InputChecked: FC<InputRadioProps> = (props) => {
  const intl = useIntl();
  const [state, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || '',
    isValid: props.initialValid || false,
    isTouched: false,
  });
  const shouldTranslate = props.translateLabel !== false;

  const { id, onInput, onChecked } = props;
  const { value, isValid } = state;

  useEffect(() => {
    onInput ? onInput(id, value, isValid) : ''
  }, [id, value, isValid, onInput]);


  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE',
      payload: {
        value: (props.value !== ' ' ? event.target.value : String(event.target.checked ? 1 : 0)),
        validators: props.validators,
      },
    });
    if (onChecked) {
      onChecked(event);
    }
  };

  const touchHandler = () => {
    dispatch({ type: 'TOUCH' });
  };

  const clickHandler = (cls: string) => {
    const el: HTMLInputElement = document.getElementsByClassName(cls)[0] as HTMLInputElement;
    el.click();
  }

  const InputRadio = (
    <>
      <input id={props.id} className={props.labelName} type="radio" value={props.value} name={props.name} defaultChecked={props.default} onChange={(e: any) => onInput!(props.id, e.target.value, true)} />
      <a onClick={() => clickHandler(props.labelName!)}>
        <label>{shouldTranslate ? intl.formatMessage({ id: props.label }) : props.label}</label>
      </a>
    </>
  );

  const StepInputRadio = (
    <>
      <input id={props.id} className={props.labelName} type="radio" disabled={props.isDisabled} value={props.value} name={props.name} defaultChecked={props.default} onChange={props.onChange} />
      <a onClick={() => clickHandler(props.labelName!)}>
        <label>{shouldTranslate ? intl.formatMessage({ id: props.label }) : props.label}</label>
      </a>
    </>
  );

  const InputCheckbox = (
    <>
      {/* <input id={props.id} className={props.labelName} type="checkbox" name={props.name} disabled={props.isDisabled} defaultChecked={props.default} onBlur={touchHandler} onChange={changeHandler} />
      <a className='checkbox' onClick={() => clickHandler(props.labelName!)}>
        <label>{intl.formatMessage({ id: props.label })}</label>
      </a> */}
      <div className='checkbox' onClick={touchHandler}>
        <input
          id={props.id}
          className={props.labelName}
          type="checkbox"
          name={props.name}
          disabled={props.isDisabled}
          defaultChecked={props.default}
          onBlur={touchHandler}
          onChange={changeHandler}
        />
        <label htmlFor={props.id} onClick={() => clickHandler(props.labelName!)}>{shouldTranslate ? intl.formatMessage({ id: props.label }) : props.label}</label>
      </div>
    </>
  );

  return (
    <>
      <Styled.Div id={props.id} hover={props.hover ? props.hover : false}>
        {props.type === "stepRadio" && StepInputRadio}
        {props.type === "radio" && InputRadio}
        {props.type === "checkbox" && InputCheckbox}
      </Styled.Div>
    </>
  );
}

export default InputChecked;
