import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Company, LoggedUser, UserConfigs } from '../db/db'
import { Equipment } from '../models/equipment';
import CookbookModel from '../models/cookbook';

import Layout from '../pages/Layout';

const LoginPage = lazy(() => import('../pages/Login'));
const MaintenancePage = lazy(() => import('../pages/Maintenance'));

const HomePage = lazy(() => import('../pages/Home'));
const HomeSupportPage = lazy(() => import('../pages/HomeSupport'));
const HomeMasterPage = lazy(() => import('../pages/Home/Master'));
const RegisterPage = lazy(() => import('../pages/Register'));
const ActivatePage = lazy(() => import('../pages/Activate'));
const Menus = lazy(() => import('../pages/Menus'));
const Menu = lazy(() => import('../pages/Menu'));
const CookbookPage = lazy(() => import('../pages/Cookbook'));
const EquipmentPage = lazy(() => import('../pages/Equipment'));
const UsersPage = lazy(() => import('../pages/Users'));
const CallsPage = lazy(() => import('../pages/Calls'));
const Group = lazy(() => import('../pages/Group'));
const MenuSimpleRecipe = lazy(() => import('../pages/MenuSimpleRecipe'));
const CreateRecipe = lazy(() => import('../pages/CreateRecipe'));
const Recipe = lazy(() => import('../pages/Recipe'));
const EditRecipe = lazy(() => import('../pages/EditRecipe'));
const CookbookRecipe = lazy(() => import('../pages/CookbookRecipe'));
const CreateCookbook = lazy(() => import('../pages/CreateCookbook'));
const EditCookbook = lazy(() => import('../pages/EditCookbook'));
const RecoverPasswordPage = lazy(() => import('../pages/RecoverPassword'));
const PasswordResetPage = lazy(() => import('../pages/PasswordReset'));
const CreateSimpleRecipe = lazy(() => import('../pages/CreateSimpleRecipe'));
const SimpleRecipe = lazy(() => import('../pages/SimpleRecipe'));
const EditSimpleRecipe = lazy(() => import('../pages/EditSimpleRecipe'));
const EquipmentDetails = lazy(() => import('../pages/EquipmentDetails'));
const EntityPage = lazy(() => import('../pages/EntityPage'));
const RecoverAccountPage2 = lazy(() => import('../pages/RecoverAccount2'));
const ActivateRecoverUserPage = lazy(() => import('../pages/Activate-recover-user'));
const DeleteRecuseUserPage = lazy(() => import('../pages/Recuse-user'));
const DeveloperTokenResetPage = lazy(() => import('../pages/RecuseDeveloperToken'));
const AnalyticsPage = lazy(() => import('../pages/Analytics'));

const MergeAccountsPage = lazy(() => import('../pages/MergeAccounts'));

interface Props {
	user?: LoggedUser[]
	company?: Company[]
	userConfigs?: UserConfigs[]
	recipe?: any[]
	cookbook?: CookbookModel[]
	steps?: any[]
	stepsCmax?: any[]
	equipment?: Equipment[]
}

const AppRoutes = ({ user, userConfigs, recipe, company, cookbook, steps, stepsCmax, equipment }: Props) => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout user={user} configs={userConfigs} company={company} />}>
					<Route path='maintenance' element={<Suspense fallback={<div>Loading...</div>}><MaintenancePage /></Suspense>} />
					<Route path='login' element={<Suspense fallback={<div>Loading...</div>}><LoginPage /></Suspense>} />
					<Route path='recoverPassword' element={<Suspense fallback={<div>Loading...</div>}><RecoverPasswordPage /></Suspense>} />
					<Route path='resetPassword' element={<Suspense fallback={<div>Loading...</div>}><PasswordResetPage /></Suspense>} />
					<Route path='resetDeveloperToken' element={<Suspense fallback={<div>Loading...</div>}><DeveloperTokenResetPage user={user} /></Suspense>} />
					<Route path='teste' element={<Suspense fallback={<div>Loading...</div>}><HomePage /></Suspense>} />
					<Route path='home' element={<Suspense fallback={<div>Loading...</div>}><HomePage user={user} userConfigs={userConfigs} /></Suspense>} />
					<Route path='home/support' element={<Suspense fallback={<div>Loading...</div>}><HomeSupportPage user={user} userConfigs={userConfigs} /></Suspense>} />
					<Route path='home/master' element={<Suspense fallback={<div>Loading...</div>}><HomeMasterPage user={user} userConfigs={userConfigs} /></Suspense>} />
					<Route path='*' element={<Suspense fallback={<div>Loading...</div>}><LoginPage /></Suspense>} />
					<Route path='register' element={<Suspense fallback={<div>Loading...</div>}><RegisterPage userConfigsDB={userConfigs && userConfigs[0]} /></Suspense>} />
					<Route path='activate' element={<Suspense fallback={<div>Loading...</div>}><ActivatePage /></Suspense>} />
					<Route path='activate/recover/user' element={<Suspense fallback={<div>Loading...</div>}><ActivateRecoverUserPage /></Suspense>} />
					<Route path='recuse/user' element={<Suspense fallback={<div>Loading...</div>}><DeleteRecuseUserPage /></Suspense>} />
					<Route path='menu' element={<Suspense fallback={<div>Loading...</div>}><Menus user={user} /></Suspense>} />
					<Route path='menu/:idMenu' element={<Suspense fallback={<div>Loading...</div>}><Menu user={user} /></Suspense>} />
					<Route path='menu/simple/:idMenu' element={<Suspense fallback={<div>Loading...</div>}><MenuSimpleRecipe /></Suspense>} />
					<Route path='menu/simple/:idMenu/recipe/:idRecipe' element={<Suspense fallback={<div>Loading...</div>}><SimpleRecipe /></Suspense>} />
					<Route path='menu/simple/:idMenu/recipe/:idRecipe/editRecipe' element={<Suspense fallback={<div>Loading...</div>}><EditSimpleRecipe stepsDb={stepsCmax ? stepsCmax[0] : ''} /></Suspense>} />
					<Route path='menu/simple/:idMenu/createSimpleRecipe/:quantRecipe' element={<Suspense fallback={<div>Loading...</div>}><CreateSimpleRecipe user={user} /></Suspense>} />
					<Route path='menu/:idMenu/group/:idGroup' element={<Suspense fallback={<div>Loading...</div>}><Group user={user} /></Suspense>} />
					<Route path='menu/:idMenu/group/:idGroup/createRecipe/:quantRecipe' element={<Suspense fallback={<div>Loading...</div>}><CreateRecipe user={user} /></Suspense>} />
					<Route path='menu/:idMenu/group/:idGroup/recipe/:idRecipe' element={<Suspense fallback={<div>Loading...</div>}><Recipe user={user} /></Suspense>} />
					<Route path='menu/:idMenu/group/:idGroup/recipe/:idRecipe/editRecipe' element={<Suspense fallback={<div>Loading...</div>}><EditRecipe recipe={recipe ? recipe : []} /></Suspense>} />
					<Route path='cookbook' element={<Suspense fallback={<div>Loading...</div>}><CookbookPage user={user} company={company} /></Suspense>} />
					<Route path='cookbook/company/:companyId/createCookbook' element={<Suspense fallback={<div>Loading...</div>}><CreateCookbook user={user} /></Suspense>} />
					<Route path='cookbook/recipe/:idRecipe' element={<Suspense fallback={<div>Loading...</div>}><CookbookRecipe user={user} cookbook={cookbook} /></Suspense>} />
					<Route path='cookbook/recipe/:idRecipe/update' element={<Suspense fallback={<div>Loading...</div>}><EditCookbook cookbook={cookbook} /></Suspense>} />
					<Route path='equipment' element={<Suspense fallback={<div>Loading...</div>}><EquipmentPage user={user} company={company} userConfigs={userConfigs} /></Suspense>} />
					<Route path='users' element={<Suspense fallback={<div>Loading...</div>}><UsersPage user={user} company={company} userConfigs={userConfigs} /></Suspense>} />
					<Route path='analytics' element={<Suspense fallback={<div>Loading...</div>}><AnalyticsPage user={user} /></Suspense>} />
					<Route path='calls' element={<Suspense fallback={<div>Loading...</div>}><CallsPage /></Suspense>} />
					<Route path='equipment/:NS' element={<Suspense fallback={<div>Loading...</div>}><EquipmentDetails user={user} /></Suspense>} />
					<Route path='entity' element={<Suspense fallback={<div>Loading...</div>}><EntityPage /></Suspense>} />
					<Route path='recoverAccount' element={<Suspense fallback={<div>Loading...</div>}><RecoverAccountPage2 /></Suspense>} />
					<Route path='companyMerge' element={<Suspense fallback={<div>Loading...</div>}><MergeAccountsPage /></Suspense>} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export default AppRoutes;
