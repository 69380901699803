import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import translate from '../../i18n/translate';
import { DivSelectLangStyle } from "./styles";
import { db } from '../../../db/db';
import ptFlag from "../../../assets/image/flags/pt.png";
import enFlag from "../../../assets/image/flags/en.png";
import esFlag from "../../../assets/image/flags/es.png";
import frFlag from "../../../assets/image/flags/fr.png";
import deFlag from "../../../assets/image/flags/de.png";
import plFlag from "../../../assets/image/flags/pl.png";
import ruFlag from "../../../assets/image/flags/ru.png";
import grFlag from "../../../assets/image/flags/gr.png";
import zhFlag from "../../../assets/image/flags/china.png";
import worldFlag from "../../../assets/image/flags/world.png";
import { useLiveQuery } from "dexie-react-hooks";
import { updateConfigUser } from "../../../services";
import JoyRide from "../../JoyRide";
import { AccountTutorial } from "../../../utils/Constants/joyrideSteps";

const changeFlag = (lang: string) => {
  switch (lang) {
    case "PT":
      return ptFlag;
    case "EN":
      return enFlag;
    case "ES":
      return esFlag;
    case "FR":
      return frFlag;
    case "DE":
      return deFlag;
    case "PL":
      return plFlag;
    case "RU":
      return ruFlag;
    case "EL":
      return grFlag;
    case "ZH":
      return zhFlag;
    default:
      return worldFlag;
  }
};

type SelectLangProps = {
  isLogin: boolean;
}

const SelectLang: FC<SelectLangProps> = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownEl = useRef<HTMLUListElement>(null);
  const userDb = useLiveQuery(() => db.user.toArray())
  const userConfigsDb = useLiveQuery(() => db.userConfigs.toArray());
  const id = userConfigsDb !== undefined && userConfigsDb.length !== 0 ? (userConfigsDb[0].id ? userConfigsDb[0].id : 0) : 0;
  const lang = userConfigsDb !== undefined && userConfigsDb.length !== 0 ? (userConfigsDb[0].language ? userConfigsDb[0].language : 'PT') : 'PT';

  const handleClickOutSide = useCallback(
    (e) => {
      if (
        showDropdown &&
        e.target.closest(".dropdown") !== dropdownEl.current
      ) {
        setShowDropdown(false);
      }
    },
    [showDropdown, dropdownEl]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide);
    return () => {
      document.removeEventListener("click", handleClickOutSide);
    };
  }, [handleClickOutSide]);

  const chooseLanguageHandler = (value: string) => {
    const submit = {
      userConfigs: {
        id: userConfigsDb && userConfigsDb[0].id,
        userId: userConfigsDb && userConfigsDb[0].userId,
        language: value
      }
    }
    props.isLogin ?
      db.userConfigs.update(id, { language: value })
      :
      updateConfigUser(submit, userDb ? userDb[0].accessToken : '')
        .then(resp => {
          db.userConfigs.update(id, submit.userConfigs)
        })
        .catch(error => {
          console.log(error);
        })
    setShowDropdown(false);
  };

  return (
    <>
      <DivSelectLangStyle id="joyride-language" isLogin={props.isLogin}>
        <p className="selected" onClick={() => setShowDropdown(!showDropdown)}>
          <span>{translate("main.Idioma")}:</span>
          <img src={changeFlag(lang)} alt="Logo do idioma" />
          <span className={showDropdown ? 'up-arrow' : 'down-arrow'}></span>
        </p>
        {showDropdown && (
          <ul className="dropdown" ref={dropdownEl}>
            <li onClick={() => chooseLanguageHandler("PT")}>Português</li>
            <li onClick={() => chooseLanguageHandler("EN")}>English</li>
            <li onClick={() => chooseLanguageHandler("ES")}>Español</li>
            <li onClick={() => chooseLanguageHandler("FR")}>Français</li>
            <li onClick={() => chooseLanguageHandler("DE")}>Deutsch</li>
            <li onClick={() => chooseLanguageHandler("PL")}>Polskie</li>
            <li onClick={() => chooseLanguageHandler("EL")}>Ελληνικά</li>
            <li onClick={() => chooseLanguageHandler("RU")}>Русский</li>
            <li onClick={() => chooseLanguageHandler("ZH")}>中文</li>
          </ul>
        )}
      </DivSelectLangStyle>
    </>
  );
};

export default SelectLang;



