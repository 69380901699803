import { Colors, Shadow } from '../StyleTypes';

const dark = {
  title: 'dark',
  colors: {
    background: Colors.LOW_DARK,
    textPrimary: Colors.HIGH_PURE,
    textSecondary: Colors.HIGH_PURE,
    header: Colors.LOW_PURE,
    textHeader: Colors.HIGH_PURE,
    iconHeader: Colors.HIGH_PURE,
    modalHeaders: Colors.PRIMARY_LIGHT,
    navbar: Colors.LOW_DARK,
    navbarHoverItem: Colors.LOW_PURE,
    dropdownBackground: Colors.LOW_PURE,
    dropdownHover: Colors.LOW_DARK,
    solidButton: Colors.PRIMARY_LIGHT,
    outlineSearch: Colors.PRIMARY_LIGHT,
    outlineActiveButton: Colors.HIGH_PURE, // high_pure
    outlineInactiveButton: Colors.HIGH_PURE,
    cancellationButton: Colors.CANCELLATION,
    backgroundInput: '#272727',
    backgroundInputError: '#2F2020',
    textErrorInput: Colors.ERROR_WARNING,
    placeholderInput: Colors.PLACEHOLDER,
    placeholderInputPassword: '#535353',
    navbarShadow: '0px 3px 6px #2F72CF',
    backgroundButton: Colors.PRIMARY_LIGHT,
    backgroundButtonModal: Colors.PRIMARY_LIGHT,
    backgroundHeaderModal: Colors.LOW_PURE,
    backgroundBox: Colors.LOW_PURE, // PRIMARY_LIGHT
    iconsSvg: '#717171',
    boxShadow: '#000000',
    boxGroup: '#232323',
    boxGroupBorder: '#333333',
    boxText: Colors.HIGH_PURE,
    backgroundTable: Colors.LOW_PURE,
    whiteDarkBlueLight: '#F8F9FA',
    backgroundCompatibilityButtons: '#6A6A6A',
    textSelect: Colors.HIGH_DARK,
    textSteps: Colors.HIGH_PURE,
    textTitle: Colors.PRIMARY_LIGHT,
    backgroundSteps: Colors.STEP_DARK,
    backgroundGradient: Colors.LOW_GRADIENT,
    imageOptions: Colors.LOW_PURE,
    imageOptionsText: Colors.HIGH_PURE,
    backgroundHeaderTable: Colors.INFO_GRADIENT,
    textInfoHome: Colors.LOW_PURE,
    backgroundGraphic: Colors.LOW_PURE,
    backgroundInfoMap: Colors.LOW_PURE,
    infoMap: Colors.HIGH_PURE,
  },

  shadows: {
    modalShadow: Shadow.DARK_MODAL_SHADOW,
    modalGroupShadow: Shadow.WHITE_BOX_SHADOW,
  },
  mapStyle: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#212121',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#212121',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#181818',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#1b1b1b',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#2c2c2c',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#8a8a8a',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#373737',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#3c3c3c',
        },
      ],
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'geometry',
      stylers: [
        {
          color: '#4e4e4e',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#000000',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#3d3d3d',
        },
      ],
    },
  ],
};

export default dark;