import { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Section,
  Container,
  SideMenu,
  Main,
  Header,
  Aside,
  FooterNav,
  Canvas,
  DivLang
} from './styles';
import { Company, db, LoggedUser, UserConfigs } from '../../db/db';
import NavBar from '../../components/UIelements/NavBar';
import RotateBanner from '../../components/UIelements/RotateBanner';
import FooterBar from './../../components/UIelements/FooterBar/index';
import SelectLang from '../../components/UIelements/SelectLang';
import Loading from '../../components/Loading';
import ModalRecoverAccount from '../../components/Modals/ModalRecoverAccount';
import imageLogin1 from "../../assets/image/backgrounds/imageLogin1.jpg";
import imageLogin2 from "../../assets/image/backgrounds/imageLogin2.jpg";
import imageLogin3 from "../../assets/image/backgrounds/imageLogin3.jpg";

interface Props {
  user?: LoggedUser[];
  configs?: UserConfigs[];
  company?: Company[];
}

const Layout = ({ user, configs, company }: Props) => {
  const [showNavbar, setShowNavbar] = useState(false);
  const navigate = useNavigate();
  const [unmadeTutorials, setUnmadeTutorials] = useState<any>();
  const [modalRecoverAccount, setModalRecoverAccount] = useState<boolean>(false);
  const [alreadyShown, setAlreadyShown] = useState<boolean>(false);
  const id: any = (configs ? (configs[0]?.id ?? 1) : 1);
  const maintenance: Boolean = false;

  if ((configs && (configs[0] === undefined || configs.length === 0))) {
    db.userConfigs.clear()
    db.userConfigs.add({
      id: 1,
      userId: 1,
      language: 'PT',
      tempUnit: '°C',
      weightUnit: 'Gramas',
      theme: 'Light',
      showAgain: true,
      recover: true,
      tutorialsUnmade: unmadeTutorials
    })
  }

  useEffect(() => {
    if (configs && !alreadyShown) {
      setModalRecoverAccount(configs[0]?.showAgain ?? false)
      setAlreadyShown(true);
    }
  }, [configs, id])


  useEffect(() => {
    if (user && user[0] && configs && company && company[0]) {
      if (user[0].accessToken && window.location.pathname === '/login') {
        /* console.log(configs[0].permission)
        if (configs[0].permission?.find(Permissions => Permissions.permission === 'menus')) navigate('/menu');
        else if (configs[0].permission?.find(Permissions => Permissions.permission === 'equipment')) navigate('/equipment');
        else if (configs[0].permission?.find(Permissions => Permissions.permission === 'cookbook')) navigate('/cookbook');
        else if (configs[0].permission?.find(Permissions => Permissions.permission === 'users')) navigate('/users');
        else navigate('/menu') */
        switch (company[0].companyType) {
          case 'Suporte':
            navigate('/home/support');
            break;
          case 'Master':
            navigate('/home/master');
            break;
          default:
            navigate('/home')
            break;
        }
      }
    } else if (window.location.pathname === '/login') {
      navigate('/login');
    } else if (window.location.pathname === '/register') {
      navigate('/register');
    } else if (window.location.pathname === '/recoverPassword') {
      navigate('/recoverPassword');
    } else if (window.location.pathname === '/resetPassword') {

    } else if (window.location.pathname === '/resetDeveloperToken') {

    } else if (window.location.pathname === '/activate') {

    } else if (window.location.pathname === '/activate/recover/user') {

    } else if (window.location.pathname === '/recuse/user') {

    } else if (window.location.pathname === '/recoverAccount') {

    } else if (window.location.pathname === '/companyMerge') {

    } else if (window.location.pathname === '/requestAccess') {
      navigate('/requestAccess')
    } else if (window.location.pathname === '/recoverAccount') {
      navigate('/recoverAccount')
    } else if (window.location.pathname === '/entity') {

    } else if (user && user[0] && window.location.pathname === '/menu') {
      navigate('/menu');
    } else if (user && user[0] && window.location.pathname === '/equipment') {
      navigate('/equipment');
    } else if (window.location.pathname === '/') {
      if (maintenance === true) {
        navigate('/maintenance')
      }
      else {
        navigate('/login')
      }
    } else {
      navigate(window.location.pathname);
    }
  }, [user, configs, company]);

  const handleToggleNavbar = useCallback(
    () => setShowNavbar((prevState) => !prevState),
    []
  );

  const handleHideNavbar = useCallback(() => setShowNavbar(false), []);

  useEffect(() => {
    const handleBackButtonEvent = (e: any) => {
      e.preventDefault();
      if (window.location.pathname === '/login') {
        db.user.clear();
        db.userConfigs.clear();
        db.company.clear();
        setTimeout(function () {
          navigate('/login')
          window.location.reload()
        }, 50);
      }
    };
    window.addEventListener('popstate', handleBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', handleBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById('header');
      if (window.pageYOffset > 100) {
        if (header) header.style.display = 'none';
      } else {
        if (header) header.style.display = 'block';
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!user && window.location.pathname !== '/resetPassword') return <Loading />

  return (
    <>
      {
        user && user.length > 0 && window.location.pathname !== '/resetPassword' && window.location.pathname !== '/activate/recover/user' && window.location.pathname !== '/activate' && window.location.pathname !== '/recoverAccount' && window.location.pathname !== '/entity' && window.location.pathname !== '/companyMerge' && window.location.pathname !== '/register' && window.location.pathname !== '/recuse/user' && window.location.pathname !== '/resetDeveloperToken' ? (
          <>

            <Container>
              <Header />
              <SideMenu className={showNavbar ? 'active' : ''}>
                <NavBar handleHideNavbar={handleHideNavbar} />
              </SideMenu>
              <Section>
                <Outlet />
              </Section>
              <FooterNav>
                <FooterBar
                  handleToggleNavbar={handleToggleNavbar}
                  handleHideNavbar={handleHideNavbar}
                  navbarIsVisible={showNavbar}
                />
              </FooterNav>
            </Container>
            <Canvas width='160px' height='100px' id="canvas" />
          </>
        ) : (
          <>
            {window.location.pathname !== '/register' &&
              <DivLang id='div-lang'>
                <SelectLang isLogin />
              </DivLang>
            }
            {window.location.pathname === '/maintenance'
              ? <Outlet />
              : <Main>
                <Outlet />
              </Main>
            }
            {window.location.pathname !== '/maintenance' && <Aside>
              <RotateBanner isLoginPage images={[imageLogin1, imageLogin2, imageLogin3]} showProgress={false} interval={3000}/>
            </Aside>}

            {modalRecoverAccount && window.location.pathname === '/login' && (
              <div className="modal">
                <ModalRecoverAccount
                  onCancel={() => setModalRecoverAccount(false)}
                  id={id} />
              </div>
            )}
          </>
        )
      }
    </>
  );
};

export default Layout;
