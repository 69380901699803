import React, { useEffect, useState } from 'react';
import {ThemeProvider} from 'styled-components';
import { I18nPropvider, LOCALES } from '../i18n';
import light from '../../assets/styles/themes/light'
import dark from '../../assets/styles/themes/dark'

const ProviderWrapper = ({ children, userConfigs }) => {
    const [theme, setTheme] = useState('Light')
    const [language, setLanguage] = useState('PT')

    /* console.log('here', userConfigs)
    const ThemeObject = !userConfigs? undefined : userConfigs
    const language = userConfigs? userConfigs[0].language : 'PT'
    console.log(ThemeObject, language) */
    useEffect(() => {
        if (userConfigs && userConfigs.length !== 0) {
            setTheme(userConfigs[0].theme)
            setLanguage(userConfigs[0].language)
        } else {
            setTheme('Light')
            setLanguage('PT')
        }
    }, [userConfigs])
    return (
        <ThemeProvider theme={theme === 'Dark'? dark : light}>
            <I18nPropvider locale={LOCALES[language]}>
                { children }
            </I18nPropvider>
        </ThemeProvider>
    );
}

export default ProviderWrapper;
