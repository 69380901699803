import { LOCALES } from "../constants";

const pt = {
  [LOCALES.PT]: {
    //LOGIN PAGE
    "login.Login": "Login",
    "login.Informe seu e-mail": "Informe seu e-mail",
    "login.E-mail inválido. Por favor, verifique":
      "E-mail inválido. Por favor, verifique",
    "login.Senha": "Senha",
    "login.Sua senha deve possuir no mínimo 6 caracteres":
      "Sua senha deve possuir no mínimo 6 caracteres",
    "login.Esqueci minha senha": "Esqueci minha senha",
    "login.ENTRAR": "ENTRAR",
    "login.CRIAR CONTA": "CRIAR CONTA",
    "login.Não foi possÍvel fazer o login. Verifique seus dados e tente novamente":
      "Não foi possÍvel fazer o login. Verifique seus dados e tente novamente",
    "login.A PLATAFORMA IOK MUDOU!": "A PLATAFORMA IOK MUDOU!",
    "login.Não exibir este aviso novamente": "Não exibir este aviso novamente",
    "login.Se você já possui um cadastro na antiga plataforma, ": "Se você já possui um cadastro na antiga plataforma, ",
    "login.clique aqui": "clique aqui",
    "login. para": " para ",
    "login.migrar": "migrar ",
    "login.seus dados e aproveitar toda a experiência IOK.": "seus dados e aproveitar toda a experiência IOK.",
    "login.Sua conta não foi ativada. Por gentileza, verifique seu e-mail para realizar a ativação": "Sua conta não foi ativada. Por gentileza, verifique seu e-mail para realizar a ativação",
    "login.PLATAFORMA EM MANUTENÇÃO": "PLATAFORMA EM MANUTENÇÃO",
    "login.ESTAMOS PREPARANDO A NOVA PLATAFORMA IOK E EM BREVE ELA ESTARÁ PRONTA!": "ESTAMOS PREPARANDO A NOVA PLATAFORMA IOK E EM BREVE ELA ESTARÁ PRONTA!",
    "login.Migrar Conta": "Migrar Conta",
    "login.Se você já possui um cadastro na antiga plataforma, clique aqui em Migrar Conta para migrar seus dados e aproveitar toda a experiência IOK": "Se você já possui um cadastro na antiga plataforma, clique aqui em Migrar Conta para migrar seus dados e aproveitar toda a experiência IOK",

    // REGISTER
    "DISTRIBUIDOR": "DISTRIBUIDOR:Sou um distribuidor de equipamentos Prática.",
    "REDE": "REDE:Sou proprietário de uma rede.",
    "DONO": "EMPRESA:Possuo minha própria empresa/faço parte de uma rede.",
    "register.Informe seu e-mail cadastrado na plataforma IOK anterior": "Informe seu e-mail cadastrado na plataforma IOK anterior",
    "register.E-mail inválido. Por favor, verifique": "E-mail inválido. Por favor, verifique",
    "register.BUSCAR DADOS": "BUSCAR DADOS",
    "register.Nome da organização": "Nome da organização",
    "register.Informe o nome de sua organização": "Informe o nome de sua organização",
    "register.O campo nome da organização é obrigatório": "O campo nome da organização é obrigatório",
    "register.Este nome já existe. Por favor, escolha outro": "Este nome já existe. Por favor, escolha outro",
    "register.RECUPERAR CONTA": "RECUPERAR CONTA",
    "register.O ADMINISTRADOR DA ORGANIZAÇÃO RECEBERÁ UM E-MAIL PARA ATIVAR SUA CONTA DE USUÁRIO. POR GENTILIZA, AGUARDE": "O ADMINISTRADOR DA ORGANIZAÇÃO RECEBERÁ UM E-MAIL PARA ATIVAR SUA CONTA DE USUÁRIO. POR GENTILIZA, AGUARDE",
    "register.E-mail não encontrado": "E-mail não encontrado",
    "register.Digite seu e-mail da plataforma IOK antiga": "Digite seu e-mail da plataforma IOK antiga",
    "register.Não foi possível recuperar sua conta. Verifique seus dados e tente novamente": "Não foi possível recuperar sua conta. Verifique seus dados e tente novamente",
    "register.Não foi possível recuperar seus dados. Verifique e tente novamente": "Não foi possível recuperar seus dados. Verifique e tente novamente",
    "register.PROSSEGUIR": "PROSSEGUIR",
    "register.VOCÊ ESTÁ SOLICITANDO ACESSO PARA A ORGANIZAÇÃO:": "VOCÊ ESTÁ SOLICITANDO ACESSO PARA A ORGANIZAÇÃO: ",
    "register.COM O ADMINISTRADOR:": " COM O ADMINISTRADOR: ",
    "register.E-mail já cadastrado na plataforma. Informe um novo e-mail ou acesse a plataforma com o e-mail já cadastrado": "E-mail já cadastrado na plataforma. Informe um novo e-mail ou acesse a plataforma com o e-mail já cadastrado",
    "register.Empresa já cadastrada na plataforma. Por favor, informe o nome de uma nova empresa": "Empresa já cadastrada na plataforma. Por favor, informe o nome de uma nova empresa",
    "register.VOCÊ NÃO RECONHECEU O USUÁRIO {userName} COMO PARTE DA SUA ORGANIZAÇÃO E POR ISSO A CONTA DELE FOI DELETADA.": "VOCÊ NÃO RECONHECEU O USUÁRIO {userName} COMO PARTE DA SUA ORGANIZAÇÃO E POR ISSO A CONTA DELE FOI DELETADA.",
    "register.NÂO FOI POSSÍVEL DELETAR O USÁRIO {userName}. POR GENTILEZA, TENTE NOVAMENTE MAIS TARDE.": "NÃO FOI POSSÍVEL DELETAR O USUÁRIO {userName}. POR GENTILEZA, TENTE NOVAMENTE MAIS TARDE.",
    "register.CONFIRMAR": "CONFIRMAR",
    "register.Não foi possível fazer o cadastro. Verifique seus dados e tente novamente":
      "Não foi possível fazer o cadastro. Verifique seus dados e tente novamente",
    "register.VOCÊ RECEBERÁ UM E-MAIL PARA CONCLUIR SEU CADASTRO E ATIVAR SUA CONTA DE ADMINISTRADOR":
      "VOCÊ RECEBERÁ UM E-MAIL PARA CONCLUIR SEU CADASTRO E ATIVAR SUA CONTA DE ADMINISTRADOR",
    "register.Nome": "Nome",
    "register.Informe seu nome": "Informe seu nome",
    "register.O campo nome é obrigatório": "O campo nome é obrigatório",
    "register.E-mail": "E-mail",
    "register.Informe seu e-mail": "Informe seu e-mail",
    "register.E-mail inválido. Por favor, verifique novamente":
      "E-mail inválido. Por favor, verifique novamente",
    "register.Telefone": "Telefone",
    "register.Informe seu telefone": "Informe seu telefone",
    "register.Crie sua senha": "Crie sua senha",
    "register.Informe sua senha": "Informe sua senha",
    "register.O campo senha deve conter no mínimo 6 caracteres":
      "O campo senha deve conter no mínimo 6 caracteres",
    "register.Confirme sua senha": "Confirme sua senha",
    "register.As senhas devem ser iguais": "As senhas devem ser iguais",
    "register.CRIAR CONTA": "CRIAR CONTA",
    "register.CONTA ATIVADA COM SUCESSO": "CONTA ATIVADA COM SUCESSO",
    "register.ACESSAR": "ACESSAR",
    "register.NÃO FOI POSSÍVEL ATIVAR SUA CONTA":
      "NÃO FOI POSSÍVEL ATIVAR SUA CONTA",
    "register.Digite seu telefone": "Digite seu telefone",
    "register.AGUARDE ENQUANTO TRANSFERIMOS SEUS DADOS. ESTE PROCESSO DEVE LEVAR ALGUNS MINUTOS. CASO VOCÊ POSSUA UMA GRANDE QUANTIDADE DE DADOS, A TRANSFERÊNCIA PODE LEVAR ATÉ UMA HORA.": "AGUARDE ENQUANTO TRANSFERIMOS SEUS DADOS. ESTE PROCESSO DEVE LEVAR ALGUNS MINUTOS. CASO VOCÊ POSSUA UMA GRANDE QUANTIDADE DE DADOS, A TRANSFERÊNCIA PODE LEVAR ATÉ UMA HORA.",
    "register.DADOS TRANSFERIDOS COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "DADOS TRANSFERIDOS COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.",
    "register.EMAIL INVÁLIDO. POR GENTILEZA, VERIFIQUE SE SUA CONTA JÁ FOI ATIVADA.": "EMAIL INVÁLIDO. POR GENTILEZA, VERIFIQUE SE SUA CONTA JÁ FOI ATIVADA.",
    "register.Ainda não tenho uma organização. Criar": "Ainda não tenho uma organização. Criar",
    "register.Informe o ID da organização": "Informe o ID da organização",
    "register.O campo com ID é obrigatório": "O campo com ID é obrigatório",
    "register.O ADMINISTRADOR DA ORGANIZAÇÃO RECEBERÁ UM E-MAIL PARA ATIVAR SUA CONTA. POR GENTILIZA, AGUARDE": "O ADMINISTRADOR DA ORGANIZAÇÃO RECEBERÁ UM E-MAIL PARA ATIVAR SUA CONTA. POR GENTILIZA, AGUARDE",
    "register.A nova plataforma IOK agora está dividida em organizações e possui diferentes tipos de conta, que ajudam a organizar melhor as grandes corporações, empresas e redes.": "A nova plataforma IOK agora está dividida em organizações e possui diferentes tipos de conta, que ajudam a organizar melhor as grandes corporações, empresas e redes.",
    "register.O administrador da minha organização já realizou a migração da conta e temos um ID. Desejo migrar meus dados de usuário.": "O administrador da minha organização já realizou a migração da conta e temos um ID. Desejo migrar meus dados de usuário.",
    "register.Desejo migrar a conta da minha organização e me tornar o administrador.": "Desejo migrar a conta da minha organização e me tornar o administrador.",
    "register.Conta da organização já migrada": "Conta da organização já migrada",
    "register.Migrar conta da organização": "Migrar conta da organização",
    "register.A nova plataforma IOK agora conta com novas formas de acesso - que são referentes ao tipo de organização em que você atua. O responsável pela sua organização deve migrar a conta principal da empresa, se tornando o administrador, enquanto o restante dos funcionários devem se vincular a ela pelo ID fornecido.": "A nova plataforma IOK agora conta com novas formas de acesso - que são referentes ao tipo de organização em que você atua. O responsável pela sua organização deve migrar a conta principal da empresa, se tornando o administrador, enquanto o restante dos funcionários devem se vincular a ela pelo ID fornecido.",
    "register.ID informado não corresponde a nenhuma companhia. Verifique e tente novamente": "ID informado não corresponde a nenhuma companhia. Verifique e tente novamente",
    "register.VOCÊ POSSUI MUITOS MENUS, NÃO CONSEGUIMOS REALIZAR A IMPORTAÇÃO AUTOMÁTICA. SUA CONTA SERÁ ATIVADA, PORÉM OS MENUS PRECISARÃO SER MIGRADOS MANUALMENTE.": "VOCÊ POSSUI MUITOS MENUS, NÃO CONSEGUIMOS REALIZAR A IMPORTAÇÃO AUTOMÁTICA. SUA CONTA SERÁ ATIVADA, PORÉM OS MENUS PRECISARÃO SER MIGRADOS MANUALMENTE.",
    "register.PARA MIGRAR SEUS MENUS, APÓS O LOGIN, CLIQUE NO SEU NOME NO CANTO SUPERIOR DIREITO, CLIQUE EM EDITAR CONTA E DEPOIS EM IMPORTAR MENUS.": "PARA MIGRAR SEUS MENUS, APÓS O LOGIN, CLIQUE NO SEU NOME NO CANTO SUPERIOR DIREITO, CLIQUE EM EDITAR CONTA E DEPOIS EM IMPORTAR MENUS.",
    "register.Atenção:": "Atenção:",
    "register.apenas uma pessoa da organização pode criar a conta e, depois de ativá-la, convidar os demais usuários da empresa para acessá-la.": "apenas uma pessoa da organização pode criar a conta e, depois de ativá-la, convidar os demais usuários da empresa para acessá-la.",
    "register.TOKEN DESENVOLVEDOR CANCELADO": "TOKEN DESENVOLVEDOR CANCELADO",

    //HOME
    "home.Menus disponíveis na plataforma": "Menus disponíveis na plataforma",
    "home.Criados na plataforma": "Criados na plataforma",
    "home.Recebidos dos fornos": "Recebidos dos fornos",
    "home.Equipamentos vinculados": "Equipamentos vinculados",
    "home.Total de receitas cadastradas": "Total de receitas cadastradas",
    "home.Receitas": "Receitas",
    "home.Receitas em menus": "Receitas em menus",
    "home.Em menus": "Em menus",
    "home.Receitas salvas no Cookbook": "Receitas salvas no Cookbook",
    "home.No Cookbook": "No Cookbook",
    "home.Atualizações": "Atualizações",
    "home.Copa": "Copa",
    "home.Rocket": "Rocket",
    "home.Single Mag": "Single Mag",
    "home.Versão instalada": "Versão instalada",
    "home.Versão disponível": "Versão disponível",
    "home.Usuários cadastrados": "Usuários cadastrados",
    "home.Aceitação pendente": "Aceitação pendente",
    "home.Atualizado": "Atualizado",
    "home.OLÁ": "OLÁ",
    "home.É muito bom ter você aqui!": "É muito bom ter você aqui!",
    "home.Com a Plataforma IOK é possível administrar seus fornos de onde estiver, criar e gerenciar receitas e menus, fazer backups, analisar os dados de seus equipamentos e muito mais!": "Com a Plataforma IOK é possível administrar seus fornos de onde estiver, criar e gerenciar receitas e menus, fazer backups, analisar os dados de seus equipamentos e muito mais!",
    "home.Conheça a plataforma": "Conheça a plataforma",
    "home.Taxa de erro por modelo": "Taxa de erro por modelo",
    "home.Erro": "Erro",
    "home.QUEREMOS SABER SUA OPINIÃO SOBRE": "QUEREMOS SABER SUA OPINIÃO SOBRE",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a Plataforma {model} foi criado!": "Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a Plataforma {model} foi criado!",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a linha {model} foi criado!": "Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a linha {model} foi criado!",
    "home.Suas respostas nos ajudarão a aprimorar nossos serviços, garantindo que continuemos a oferecer a você a melhor experiência possível.": "Suas respostas nos ajudarão a aprimorar nossos serviços, garantindo que continuemos a oferecer a você a melhor experiência possível.",
    "home.Reserve uns minutinhos de seu dia e participe!": "Reserve uns minutinhos de seu dia e participe!",
    "home.Responder a pesquisa": "Responder a pesquisa",
    "equipment.QUEREMOS SABER SUA OPINIÃO SOBRE O": "QUEREMOS SABER SUA OPINIÃO SOBRE O",
    "home.Modelos em evidência": "Modelos em evidência",
    "home.Modelos": "Modelos",
    "home.Total de equipamentos vinculados": "Total de equipamentos vinculados",
    "home.Total de equipamentos online": "Total de equipamentos online",
    "home.Equipamentos online": "Equipamentos online",
    "home.Países com Prática": "Países com Prática",
    "home.Países": "Países",
    "home.Aguarde enquanto carregamos os dados": "Aguarde enquanto carregamos os dados",
    "home.Quase lá! Aguarde um pouco mais": "Quase lá! Aguarde um pouco mais",

    //MAIN
    "main.HOME": "HOME",
    "main.COOKBOOK": "COOKBOOK",
    "main.MEUS MENUS": "MEUS MENUS",
    "main.EQUIPAMENTOS": "EQUIPAMENTOS",
    "main.USUÁRIOS": "USUÁRIOS",
    "main.ATENDIMENTOS": "ATENDIMENTOS",
    "main.Idioma": "Idioma",
    "main.Editar conta": "Editar conta",
    "main.Configurações": "Configurações",
    "main.Sair": "Sair",
    "main.Configurações salvas com sucesso!": "Configurações salvas com sucesso!",
    "main.SALVAR": "SALVAR",
    "main.CONFIGURAÇÕES": "CONFIGURAÇÕES",
    "main.Tema dark": "Tema dark",
    "main.Notificações": "Notificações",
    "main.Informações para as receitas": "Informações para as receitas",
    "main.Peso:": "Peso:",
    "main.Gramas": "Gramas",
    "main.Oz": "Oz",
    "main.Temperatura:": "Temperatura:",
    "main.Fahrenheit (°F)": "Fahrenheit (°F)",
    "main.Conta editada com sucesso!": "Conta editada com sucesso!",
    "main.EDITAR CONTA": "EDITAR CONTA",
    "main.Nome": "Nome",
    "main.Preencha com o seu nome": "Preencha com o seu nome",
    "main.Telefone": "Telefone",
    "main.Preencha com o seu telefone": "Preencha com o seu telefone",
    "main.E-mail": "E-mail",
    "main.Preencha com o seu e-mail": "Preencha com o seu e-mail",
    "main.Alterar Senha": "Alterar Senha",
    "main.ALTERAR SENHA": "ALTERAR SENHA",
    "main.Senha atual": "Senha atual",
    "main.Nova senha": "Nova senha",
    "main.Confirmar nova senha": "Confirmar nova senha",
    "main.Informe sua senha atual": "Informe sua senha atual",
    "main.O campo senha deve conter no mínimo 6 caracteres":
      "O campo senha deve conter no mínimo 6 caracteres",
    "main.Informe sua nova senha": "Informe sua nova senha",
    "main.Confirme sua nova senha": "Confirme sua nova senha",
    "main.Sua nova senha deve conter no mínimo 6 caracteres":
      "Sua nova senha deve conter no mínimo 6 caracteres",
    "main.INFORME SUA SENHA": "INFORME SUA SENHA",
    "main.Informe sua senha para realizar a alteração de e-mail da plataforma:":
      "Informe sua senha para realizar a alteração de e-mail da plataforma:",
    "main.CONFIRMAR": "CONFIRMAR",
    "main.Existem campos que não foram preenchidos corretamente":
      "Existem campos que não foram preenchidos corretamente",
    "main.Informe sua senha para prosseguir com a alteração de e-mail":
      "Informe sua senha para prosseguir com a alteração de e-mail",
    "main.Senha alterada com sucesso": "Senha alterada com sucesso",
    "main.Informe sua senha atual corretamente": "Informe sua senha atual corretamente",
    "main.As senhas devem ser iguais": "As senhas devem ser iguais",
    "main.Nome do novo tipo de usuário": "Nome do novo tipo de usuário",
    "main.Dê um nome para o novo tipo de usuário": "Dê um nome para o novo tipo de usuário",
    "main.Token Desenvolvedor": "Token Desenvolvedor",
    "main.O Token Desenvolvedor é um código único e intransferível que permite a realização de alterações": "O Token Desenvolvedor é um código único e intransferível que permite a realização de alterações a nível de desenvolvimento, diretamente no código da plataforma, por meio de APIs externas. Elas possibilitam a personalização da plataforma de forma a atender melhor as necessidades de sua empresa.",
    "main.É recomendado que estas alterações sejam realizadas por um profissional da área de tecnologia": "É recomendado que estas alterações sejam realizadas por um profissional da área de tecnologia.",
    "main.Para acessar o token, informe sua senha": "Para acessar o token, informe sua senha",
    "main.Copie e cole o token na autenticação de todas as requisições.": "Copie e cole o token na autenticação de todas as requisições.",
    "main.Seu Token": "Seu Token",
    "main.Token copiado com sucesso!": "Token copiado com sucesso!",
    "main.Você deseja cancelar o preenchimento das informações?": "Você deseja cancelar o preenchimento das informações?",
    "main.Rever tutoriais": "Rever tutoriais",
    "main.Tutoriais resetados com sucesso": "Tutoriais resetados com sucesso",
    "main.review.tutorials": "Deseja rever os tutoriais da Plataforma IOK?",

    //MEUS MENUS
    "menus.Procurar menu": "Procurar menu ou usuário",
    "menus.Procurar menu excluído": "Procurar menu excluído",
    "menus.Menus": "Menus",
    "menus.Lixeira": "Lixeira",
    "menus.CRIAR MENU": "CRIAR MENU",
    "menus.IMPORTAR MENU": "IMPORTAR MENU",
    "menus.Os arquivos serão mantidos na lixeira por 30 dias a contar de sua data de exclusão. Após este período, eles serão excluídos definitivamente.":
      "Os arquivos serão mantidos na lixeira por 30 dias a contar de sua data de exclusão. Após este período, eles serão excluídos definitivamente.",
    "menus.VOLTAR": "voltar",
    "menus.Compatível com: ": "Compatível com: ",
    "menus.Última atualização: ": "Última atualização: ",
    "menus.EXPORTAR MENU": "EXPORTAR MENU",
    "menus.EDITAR MENU": "EDITAR MENU",
    "menus.Equipamentos associados": "Equipamentos associados",
    "menus.Grupos": "Grupos",
    "menus.Procurar grupo": "Procurar grupo",
    "menus.CRIAR GRUPO": "CRIAR GRUPO",
    "menus.receitas": "receitas",
    "menus.Nenhum grupo cadastrado": "Nenhum grupo cadastrado",
    "menus.Nenhum menu cadastrado": "Nenhum menu cadastrado",
    "menus.Nome": "Nome",
    "menus.Modelo": "Modelo",
    "menus.Atividade": "Atividade",
    "menus.Associação": "Associação",
    "menus.Exportar": "Exportar",
    "menus.Editar": "Editar",
    "menus.Excluir": "Excluir",
    "menus.Equips": "Equips",
    "menus.Receitas": "Receitas",
    "menus.Compatibilidade": "Compatibilidade",
    "menus.Última atualização": "Última atualização",
    "menus.Versão": "Versão",
    "menus.Origem": "Origem",
    "menus.Excluído por: ": "Excluído por: ",
    "menus.Restaurar": "Restaurar",
    "menus.Existem campos que não foram preenchidos":
      "Existem campos que não foram preenchidos",
    "menus.Menu criado com sucesso!": "Menu criado com sucesso!",
    "menus.Existem campos com informações inválidas":
      "Existem campos com informações inválidas",
    "menus.SALVAR": "SALVAR",
    "menus.CANCELAR": "CANCELAR",
    "menus.Nome do menu:": "Nome do menu: ",
    "menus.Informe o nome do menu": "Informe o nome do menu",
    "menus.Preencha o nome do menu sem caracteres especiais": "Preencha o nome do menu sem caracteres especiais",
    "menus.Selecionar": "Selecionar",
    "menus.Idioma": "Idioma",
    "menus.Temperatura de pré-aquec 1:": "Temperatura de pré-aquec 1:",
    "menus.Informe a temperatura de pré-aquec 1":
      "Informe a temperatura de pré-aquec 1",
    "menus.Temperatura de pré-aquec 2:": "Temperatura de pré-aquec 2:",
    "menus.Informe a temperatura de pré-aquec 2":
      "Informe a temperatura de pré-aquec 2",
    "menus.Tempo para estabilização:": "Tempo para estabilização:",
    "menus.Informe o tempo para estabilização":
      "Informe o tempo para estabilização",
    "menus.Desativar pré-aquec 2": "Desativar pré-aquec 2",
    "menus.Configurações de data e hora": "Configurações de data e hora",
    "menus.Data:": "Data:",
    "menus.DD/MM/AAAA": "DD/MM/AAAA",
    "menus.Hora:": "Hora:",
    "menus.24h": "24h",
    "menus.AM/PM": "AM/PM",
    "menus.EUR: DD/MM/AAAA": "EUR: DD/MM/AAAA",
    "menus.USA: MM/DD/AAAA": "USA: MM/DD/AAAA",
    "menus.ISO: AAAA/MM/DD": "ISO: AAAA/MM/DD",
    "menus.Seleção de acessos para habilitar":
      "Seleção de acessos para habilitar",
    "menus.Modo manual": "Modo manual",
    "menus.Habilitado": "Habilitado",
    "menus.Desabilitado": "Desabilitado",
    "menus.Favoritos": "Favoritos",
    "menus.Aquecer/Dourar": "Aquecer/Dourar",
    "menus.Repetir receita": "Repetir receita",
    "menus.Configurações gerais do menu": "Configurações gerais do menu",
    "menus.Temperatura:": "Temperatura:",
    "menus.Fahrenheit (°F)": "Fahrenheit (°F)",
    "menus.Gramas": "Gramas",
    "menus.Oz": "Oz",
    "menus.Tema:": "Tema:",
    "menus.Dark": "Dark",
    "menus.Light": "Light",
    "menus.Introdução:": "Introdução:",
    "menus.Ativada": "Ativada",
    "menus.Desativada": "Desativada",
    "menus.Menu deletado com sucesso!": "Menu deletado com sucesso!",
    "menus.Não foi possivel deletar o menu": "Não foi possivel deletar o menu",
    "menus.Sim": "Sim",
    "menus.Não": "Não",
    "menus.Excluir definitivamente": "Excluir definitivamente",
    "menus.SALVAR EM PEN DRIVE": "SALVAR EM PEN DRIVE",
    "menus.EXPORTAR": "EXPORTAR",
    "menus.Selecione os modelos e versões dos equipamentos que receberão o menu.":
      "Selecione os modelos e versões dos equipamentos que receberão o menu.",
    "menus.Forno": "Forno",
    "menus.Fundo verde": "Fundo verde",
    "menus.Fundo preto": "Fundo preto",
    "menus.Linux": "Linux",
    "menus.Atenção: antes de transferir o menu de seu pen drive para o equipamento, é necessário descompactar o arquivo, exceto para menus de equipamentos Linux.":
      "Atenção: antes de transferir o menu de seu pen drive para o equipamento, é necessário descompactar o arquivo, exceto para menus de equipamentos Linux.",
    "menus.Selecione os equipamentos para os quais deseja exportar o menu.":
      "Selecione os equipamentos para os quais deseja exportar o menu.",
    "menus.Nome do equipamento": "Nome do equipamento",
    "menus.Selecionar todos": "Selecionar todos",
    "menus.Selecionar todas": "Selecionar todas",
    "menus.Categoria: ": "Categoria: ",
    "menus.Você deseja excluir o menu?": "Você deseja excluir o menu?",
    "menus.O menu será deletado da plataforma, mas permanecerá no equipamento. Uma cópia de segurança será criada automaticamente e ficará disponível por 30 dias em Meus Menus - Lixeira.":
      "O menu será deletado da plataforma, mas permanecerá no equipamento. Uma cópia de segurança será criada automaticamente e ficará disponível por 30 dias em Meus Menus - Lixeira.",
    "menus.Você deseja excluir o arquivo definitivamente?":
      "Você deseja excluir o arquivo definitivamente?",
    "menus.Esta ação não poderá ser desfeita.":
      "Esta ação não poderá ser desfeita.",
    "menus.Menu editado com sucesso!": "Menu editado com sucesso!",
    "menus.IMPORTAR": "IMPORTAR",
    "menus.VERIFICAÇÃO DE VERSÃO": "VERIFICAÇÃO DE VERSÃO",
    "menus.Para conferir a versão da placa de seu equipamento, siga os seguintes passos:":
      "Para conferir a versão da placa de seu equipamento, siga os seguintes passos:",
    "menus.No forno, acesse Configurações;": "No forno, acesse Configurações;",
    "menus.Insira a senha 456789;": "Insira a senha 456789;",
    "menus.Selecione a opção Sistema;": "Selecione a opção Sistema;",
    "menus.Selecione Informações do Fabricante;":
      "Selecione Informações do Fabricante;",
    "menus.O modelo de sua placa estará na linha Firmware IHM;":
      "O modelo de sua placa estará na linha Firmware IHM;",
    "menus.1ª geração A": "1ª geração A",
    "menus.Firmware IHM: 12.3.4": "Firmware IHM: 12.3.4",
    "menus.1ª geração B": "1ª geração B",
    "menus.2ª geração/Linux:": "2ª geração/Linux:",
    "menus.Firmware IHM: L1.2.3": "Firmware IHM: L1.2.3",
    "menus.Selecione o modelo/versão do equipamento do qual deseja importar o menu. Saiba como conferir a versão de seu equipamento":
      "Selecione o modelo/versão do equipamento do qual deseja importar o menu. Saiba como conferir a versão de seu equipamento ",
    "menus.Como verificar a versão da placa":
      "Como verificar a versão da placa",
    "menus.1° Geração": "1° Geração",
    "menus.Menu restaurado com sucesso!": "Menu restaurado com sucesso!",
    "menus.Não foi possivel restaurar o menu":
      "Não foi possivel restaurar o menu",
    "menus.RESTAURAR ARQUIVO": "RESTAURAR ARQUIVO",
    "menus.Você deseja restaurar o arquivo?":
      "Você deseja restaurar o arquivo?",
    "menus.O arquivo será restaurado como uma nova versão na plataforma, na guia Todos. Para salvá-lo no equipamento, exporte-o.":
      "O arquivo será restaurado como uma nova versão na plataforma, na guia Todos. Para salvá-lo no equipamento, exporte-o.",
    "menus.EXCLUIR MENU": "EXCLUIR MENU",
    "menus.Nenhum equipamento associado": "Nenhum equipamento associado",
    "menus.Nomeie o novo menu": "Nomeie o novo menu",
    "menus.Nomeie o novo grupo": "Nomeie o novo grupo",
    "menus.Nome do grupo:": "Nome do grupo: ",
    "menus.Preencha o nome do grupo sem caracteres especiais": "Preencha o nome do grupo sem caracteres especiais",
    "menus.Pré-aquecimento:": "Pré-aquecimento:",
    "menus.Grupo criado com sucesso!": "Grupo criado com sucesso!",
    "menus.SELECIONE UMA IMAGEM": "SELECIONE UMA IMAGEM",
    "menus.SELECIONAR": "SELECIONAR",
    "menus.Selecionar do computador": "Selecionar do computador",
    "menus.Selecionar do banco de imagens": "Selecionar do banco de imagens",
    "menus.Formato .JPG ou .PNG": "Formato .JPG ou .PNG",
    "menus.Procurar imagem": "Procurar imagem",
    "menus.Pertence ao menu": "Pertence ao menu",
    "menus.Alterar imagem": "Alterar imagem",
    "menus.Remover imagem": "Remover imagem",
    "menus.EDITAR GRUPO": "EDITAR GRUPO",
    "menus.Nome:": "Nome:",
    "menus.CRIAR RECEITA": "CRIAR RECEITA",
    "menus.SALVAR DO COOKBOOK": "SALVAR DO COOKBOOK",
    "menus.Procurar receita do grupo": "Procurar receita do grupo",
    "menus.Ingrediente principal": "Ingrediente principal",
    "menus.Mover receitas": "Mover receitas",
    "menus.Excluir receitas": "Excluir receitas",
    "menus.Editar grupo": "Editar grupo",
    "menus.Excluir grupo": "Excluir grupo",
    "menus.aqui": "aqui",
    "menus.Se o código possuir apenas números e o fundo da tela for verde, pertence à 1ª A, se possuir apenas números e o fundo da tela for preta, pertence à 1ª B e, se começar com as letras L, F ou S, sua placa pertence à 2ª geração/Linux.":
      "Se o código possuir apenas números e o fundo da tela for verde, pertence à 1ª A, se possuir apenas números e o fundo da tela for preta, pertence à 1ª B e, se começar com as letras L, F ou S, sua placa pertence à 2ª geração/Linux.",
    "menus.1ª A - Fundo verde": "1ª A - Fundo verde",
    "menus.1ª B - Fundo preto": "1ª B - Fundo preto",
    "menus.2ª - Linux": "2ª - Linux",
    "menus.Tivemos um problema. Tente novamente mais tarde.":
      "Tivemos um problema. Tente novamente mais tarde.",
    "menus.Grupo editado com sucesso!": "Grupo editado com sucesso!",
    "menus.AINDA NÃO HÁ GRUPOS AQUI :(": "AINDA NÃO HÁ GRUPOS AQUI :(",
    "menus.NENHUM MENU NA LIXEIRA": "NENHUM MENU NA LIXEIRA",
    "menus.AINDA NÃO HÁ MENUS AQUI :(": "AINDA NÃO HÁ MENUS AQUI :(",
    "menus.Adicionar imagem": "Adicionar imagem",
    "menus.Nome da receita": "Nome da receita",
    "menus.Detalhes da receita": "Detalhes da receita",
    "menus.Programação da receita": "Programação da receita",
    "menus.Nomeie sua receita": "Nomeie sua receita",
    "menus.Preencha o nome da receita sem caracteres especiais": "Preencha o nome da receita sem caracteres especiais",
    "menus.Tipo de prato": "Tipo de prato",
    "menus.SALVAR RECEITAS DO COOKBOOK": "SALVAR RECEITAS DO COOKBOOK",
    "menus.Selecione as receitas que deseja salvar no grupo de seu menu:":
      "Selecione as receitas que deseja salvar no grupo de seu menu:",
    "menus.Procurar receita": "Procurar receita",
    "menus.Grupo deletado com sucesso!": "Grupo deletado com sucesso!",
    "menus.Não foi possivel deletar o grupo":
      "Não foi possivel deletar o grupo",
    "menus.EXCLUIR GRUPO": "EXCLUIR GRUPO",
    "menus.Você deseja excluir o grupo definitivamente?":
      "Você deseja excluir o grupo definitivamente?",
    "menus.AINDA NÃO HÁ RECEITAS AQUI :(": "AINDA NÃO HÁ RECEITAS AQUI :(",
    "menus.Temp. de entrada": "Temp. de entrada",
    "menus.Temp. de pré-aquec": "Temp. de pré-aquec",
    "menus.Ingredientes para montagem": "Ingredientes para montagem",
    "menus.Instruções operacionais": "Instruções operacionais",
    "menus.Passo": "Passo",
    "menus.Temp": "Temp ",
    "menus.Temp.": "Temp. ",
    "menus.Tempo": "Tempo",
    "menus.Velocidade do ar": "Velocidade do ar",
    "menus.Micro-ondas": "Micro-ondas",
    "menus.Resistência inferior": "Resistência inferior",
    "menus.Informações": "Informações",
    "menus.Digite as informações do passo": "Digite as informações do passo",
    "menus.desativar": "desativar",
    "menus.Ingredientes": "Ingredientes",
    "menus.Temperatura de entrada": "Temperatura de entrada",
    "menus.Equipamentos compatíveis": "Equipamentos compatíveis",
    "menus.SALVAR NO COOKBOOK": "SALVAR NO COOKBOOK",
    "menus.ver localização": "ver localização",
    "menus.SELECIONE RECEITAS PARA EXCLUIR": "SELECIONE RECEITAS PARA EXCLUIR",
    "menus.Você deseja excluir as receitas selecionadas definitivamente?":
      "Você deseja excluir as receitas selecionadas definitivamente?",
    "menus.Receitas deletadas com sucesso!": "Receitas deletadas com sucesso!",
    "menus.Informe aqui os ingredientes para montagem":
      "Informe aqui os ingredientes para montagem",
    "menus.Informe aqui as instruções operacionais":
      "Informe aqui as instruções operacionais",
    "menus.Adicionar passo": "Adicionar passo",
    "menus.Receita criada com sucesso!": "Receita criada com sucesso!",
    "menus.Ideal para:": "Ideal para: ",
    "menus.editar detalhes": "editar detalhes",
    "menus.excluir": "excluir",
    "menus.editar": "editar",
    "menus.Receita editada com sucesso!": "Receita editada com sucesso!",
    "menus.Passo deletado com sucesso!": "Passo deletado com sucesso!",
    "menus.Não foi possivel deletar o passo":
      "Não foi possivel deletar o passo",
    "menus.EXCLUIR PASSO": "EXCLUIR PASSO",
    "menus.Você deseja excluir este passo?": "Você deseja excluir este passo?",
    "menus.Esta ação não poderá ser desfeita. Caso prefira, é possível apenas desativá-lo, mantendo assim sua configuração.":
      "Esta ação não poderá ser desfeita. Caso prefira, é possível apenas desativá-lo, mantendo assim sua configuração.",
    "menus.Peso": "Peso",
    "menus.Origem da receita": "Origem da receita",
    "menus.Pré-aquecimento": "Pré-aquecimento",
    "menus.Plataforma": "Plataforma",
    "menus.Receita salva com sucesso!": "Receita salva com sucesso!",
    "menus.Exportar receitas": "Exportar receitas",
    "menus.expandir": "expandir",
    "menus.Temperatura": "Temperatura",
    "menus.Vapor combinado": "Vapor combinado",
    "menus.Ar quente": "Ar quente",
    "menus.Sonda": "Sonda",
    "menus.Tempo (min)": "Tempo (min)",
    "menus.Umidade (%)": "Umidade (%)",
    "menus.Injeção de vapor": "Injeção de vapor",
    "menus.Dumper aberto": "Dumper aberto",
    "menus.Informe o peso": "Informe o peso",
    "menus.Informe a temperatura de entrada":
      "Informe a temperatura de entrada",
    "menus.Informe os ingredientes para montagem":
      "Informe os ingredientes para montagem",
    "menus.Informe as instruções operacionais":
      "Informe as instruções operacionais",
    "menus.Injeção de vapor (seg)": "Injeção de vapor (seg)",
    "menus.Aquecer mais": "Aquecer mais",
    "menus.Dourar mais": "Dourar mais",
    "menus.Aquecer e dourar mais": "Aquecer e dourar mais",
    "menus.Favoritar receita": "Favoritar receita",
    "menus.Informe o tempo para aquecer mais de 0 a 59 segundos":
      "Informe o tempo para aquecer mais de 0 a 59 segundos",
    "menus.Informe o tempo para dourar mais de 0 a 59 segundos":
      "Informe o tempo para dourar mais de 0 a 59 segundos",
    "menus.Informe o tempo para aquecer e dourar mais de 0 a 59 segundos":
      "Informe o tempo para aquecer e dourar mais de 0 a 59 segundos",
    "menus.Apenas favoritos": "Apenas favoritos",
    "menus.Forno combinado": "Forno combinado",
    "menus.Cocção múltipla": "Cocção múltipla",
    "menus.Receitas Technicook": "Receitas Technicook",
    "menus.Editar grupos": "Editar grupos",
    "menus.Informe a data": "Informe a data",
    "menus.Informe a hora": "Informe a hora",
    "menus.até 300": "até 300",
    "menus.Vapor": "Vapor",
    "menus.Turbina": "Turbina",
    "menus.Criar grupo": "Criar grupo",
    "menus.MOVER": "MOVER",
    "menus.Configurações de data e hora:": "Configurações de data e hora:",
    "menus.Configurações de acessos:": "Configurações de acessos:",
    "menus.Modo manual:": "Modo manual:",
    "menus.Favoritos:": "Favoritos:",
    "menus.Aquecer/dourar:": "Aquecer/dourar:",
    "menus.Repetir receita:": "Repetir receita:",
    "menus.Configurações gerais do menu:": "Configurações gerais do menu:",
    "menus.SELECIONE RECEITAS PARA MOVER": "SELECIONE RECEITAS PARA MOVER",
    "menus.AVANÇAR": "AVANÇAR",
    "menus.Selecione ou crie um grupo no mesmo menu para mover as receitas selecionadas ou as mova para outro menu. Note que apenas os menus/grupos compatíveis com as predefinições das receitas serão exibidos.": "Selecione ou crie um grupo no mesmo menu para mover as receitas selecionadas ou as mova para outro menu. Note que apenas os menus/grupos compatíveis com as predefinições das receitas serão exibidos.",
    "menus.Mover receitas para o mesmo menu": "Mover receitas para o mesmo menu",
    "menus.Mover receitas para outro menu": "Mover receitas para outro menu",
    "menus.Selecionar grupo": "Selecionar grupo",
    "menus.Nenhuma receita foi selecionada": "Nenhuma receita foi selecionada",
    "menus.Passo editado com sucesso!": "Passo editado com sucesso!",
    "menus.Menu atualizado": "Menu atualizado",
    "menus.até 260": "até 260",
    "menus.Umidade na câmara": "Umidade na câmara",
    "menus.Passos": "Passos",
    "menus.ativar": "ativar",
    "menus.Informe a temperatura de pré-aquec": "Informe a temperatura de pré-aquec",
    "menus.Movas as receitas selecionadas para outro menu. Note que apenas os menus compatíveis com as predefinições das receitas serão exibidos.":
      "Movas as receitas selecionadas para outro menu. Note que apenas os menus compatíveis com as predefinições das receitas serão exibidos.",
    "menus.Sonda (°C)": "Sonda (°C)",
    "menus.Turbo Grill": "Turbo Grill",
    "menus.Pré-aquecimento padrão, conforme passo 1": "Pré-aquecimento padrão, conforme passo 1",
    "menus.salvar": "salvar",
    "menus.até": "até",
    "menus.Verificação de receita": "Verificação de receita",
    "menus.Auto import": "Auto import",
    "menus.Atualização forçada": "Atualização forçada",
    "menus.Lista de receitas exportada com sucesso!": "Lista de receitas exportada com sucesso!",
    "menus.Receita movida com sucesso!": "Receita movida com sucesso!",
    "menus.Receitas movidas com sucesso!": "Receitas movidas com sucesso!",
    "menus.+ Menu": "+ Menu",
    "menus.+ Receita": "+ Receita",
    'menus.Selecione o modelo/versão do equipamento': 'Selecione o modelo/versão do equipamento',
    "menus.Escolha um idioma compatível com o equipamento": "Escolha um idioma compatível com o equipamento",
    "menus.MENU IMPORTADO COM SUCESSO!": "Menu importado com sucesso!",
    "menus.Dourar": "Dourar",
    "menus.Não atribuir a menu": "Não atribuir a menu",
    "menus.Todos": "Todos",
    "menus.Meus menus": "Meus menus",
    "menus.Para salvar uma receita, preencha todos os campos": "Para salvar uma receita, preencha todos os campos",
    "menus.Selecione os grupos de equipamentos para os quais deseja exportar o menu.": "Selecione os grupos de equipamentos para os quais deseja exportar o menu.",
    "menus.Formato inválido. São permitidos apenas os formatos .jpeg e .png": "Formato inválido. São permitidos apenas os formatos .jpeg e .png",
    "menus.Reordenar grupos": "Reordenar grupos",
    "menus.Reordenar receitas": "Reordenar receitas",
    "menus.ITENS REORDENADOS COM SUCESSO! PARA APLICAR AS ALTERAÇÕES NO FORNO, EXPORTE O MENU.": "ITENS REORDENADOS COM SUCESSO! PARA APLICAR AS ALTERAÇÕES NO FORNO, EXPORTE O MENU.",
    "menus.ARRASTE OS ITENS PARA O LOCAL DESEJADO": "ARRASTE OS ITENS PARA O LOCAL DESEJADO",
    "menus.Dumper": "Dumper",
    "menus.Aberto": "Aberto",
    "menus.Fechado": "Fechado",
    "menus.Intermitente": "Intermitente",
    "menus.Se o seu menu incluir itens sensíveis à uniformidade, recomendamos definir o Tempo de Estabilização entre 3:00 e 5:00 minutos. Caso contrário, você pode deixá-lo em 0:00 minutos.": "Se o seu menu incluir itens sensíveis à uniformidade, recomendamos definir o Tempo de Estabilização entre 3:00 e 5:00 minutos. Caso contrário, você pode deixá-lo em 0:00 minutos.",
    "menus.Selecione o menu que deseja exportar:": "Selecione o menu que deseja exportar:",
    "menus.Temperatura de pré-aquecimento:": "Temperatura de pré-aquecimento:",
    "menus.Temperatura de lastro": "Temperatura de lastro",
    "menus.Temperatura de teto": "Temperatura de teto",
    "menus.Volume de injeção de vapor (ml)": "Volume de injeção de vapor (ml)",
    "menus.Parada de turbina": "Parada de turbina",
    "menus.Alta": "Alta",
    "menus.Média": "Média",
    "menus.Baixa": "Baixa",
    "menus.a": "a",
    "menus.Valor da injeção": "Valor da injeção",
    "menus.Volume: até 999ml": "Volume: até 999ml",
    "menus.Tempo: até 15 segundos": "Tempo: até 15 segundos",
    "menus.Tempo de injeção de vapor (seg)": "Tempo de injeção de vapor (seg)",
    "menus.Atenção!": "Atenção!",
    "menus.Verifique as receitas do menu e ajuste os valores da Injeção de Vapor após alterar sua unidade de medida.": "Verifique as receitas do menu e ajuste os valores da Injeção de Vapor após alterar sua unidade de medida.",
    "menus.Certifique-se de que a unidade de temperatura (°C ou °F) definida no equipamento seja a mesma que foi selecionada na criação do menu:": "Certifique-se de que a unidade de temperatura (°C ou °F) definida no equipamento seja a mesma que foi selecionada na criação do menu:",
    "menus.Injeção desativada": "Injeção desativada",
    "menus.Selecione a unidade de temperatura que está definida no equipamento:": "Selecione a unidade de temperatura que está definida no equipamento:",
    "menus.Clique para atualizar": "Clique para atualizar",
    "menus.A função Atualização Forçada envia automaticamente o menu para os equipamentos selecionados na próxima vez em que forem ligados.": "A função Atualização Forçada envia automaticamente o menu para os equipamentos selecionados na próxima vez em que forem ligados.",
    "menus.As opções selecionadas estarão disponíveis nos equipamentos que receberem este menu, podendo ser utilizadas pelo operador. É possível alterar estas configurações posteriormente no próprio forno ou pela plataforma.": "As opções selecionadas estarão disponíveis nos equipamentos que receberem este menu, podendo ser utilizadas pelo operador. É possível alterar estas configurações posteriormente no próprio forno ou pela plataforma.",
    "menus.Não foi possivel deletar as receitas": "Não foi possivel deletar as receitas",

    //COOKBOOK
    "cookbook.RECEITAS DISPONÍVEIS": "RECEITAS DISPONÍVEIS",
    "cookbook.RECEITAS SALVAS": "RECEITAS SALVAS",
    "cookbook.ENVIAR PARA MENU": "ENVIAR PARA MENU",
    "cookbook.SALVAR": "SALVAR",
    "cookbook.salvar": "salvar",
    "cookbook.Filtrar por": "Filtrar por",
    "cookbook.Procurar receita do cookbook": "Procurar receita do cookbook",
    "cookbook.Equipamento": "Equipamento",
    "cookbook.Ingrediente principal": "Ingrediente principal",
    "cookbook.Tipo de prato": "Tipo de prato",
    "cookbook.Região": "Região",
    "cookbook.Pré-aquecimento": "Pré-aquecimento",
    "cookbook.Procurar receita": "Procurar receita",
    "cookbook.Menu": "Menu",
    "cookbook.ENVIAR": "ENVIAR",
    "cookbook.Procurar nome do menu": "Procurar nome do menu",
    "cookbook.Escolha um grupo deste menu": "Escolha um grupo deste menu",
    "cookbook.Selecionar grupo": "Selecionar grupo",
    "cookbook.Tivemos um problema. Tente novamente mais tarde":
      "Tivemos um problema. Tente novamente mais tarde",
    "cookbook.Receita adicionada com sucesso!": "Receita adicionada com sucesso!",
    "cookbook.Não foi possível adicionar a receita. Tente novamente mais tarde":
      "Não foi possível adicionar a receita. Tente novamente mais tarde",
    "cookbook.Selecione um menu e um grupo para enviar esta receita":
      "Selecione um menu e um grupo para enviar esta receita",
    "cookbook.Selecione o modelo do equipamento primeiro": "Selecione o modelo do equipamento primeiro",
    "cookbook.Grupo": "Grupo",
    "cookbook.Não foi possível salvar os passos da receita": "Não foi possível salvar os passos da receita",
    "cookbook.Bovina": "Bovina",
    "cookbook.Frango": "Frango",
    "cookbook.Ovos": "Ovos",
    "cookbook.Pães": "Pães",
    "cookbook.Peixes": "Peixes",
    "cookbook.Queijo": "Queijo",
    "cookbook.Suína": "Suína",
    "cookbook.Vegetais": "Vegetais",
    "cookbook.Outros": "Outros",
    "cookbook.Entradas/aperitivos": "Entradas/aperitivos",
    "cookbook.Principais": "Principais",
    "cookbook.Snacks": "Snacks",
    "cookbook.Pizzas": "Pizzas",
    "cookbook.Massas": "Massas",
    "cookbook.Acompanhamentos": "Acompanhamentos",
    "cookbook.Sobremesas": "Sobremesas",
    "cookbook.Manhã": "Manhã",
    "cookbook.Padaria": "Padaria",
    "cookbook.Todos": "Todos",
    "cookbook.Não atribuir origem": "Não atribuir origem",
    "cookbook.Brasil": "Brasil",
    "cookbook.Alemanha": "Alemanha",
    "cookbook.França": "França",
    "cookbook.Rússia": "Rússia",
    "cookbook.China": "China",
    "cookbook.Japão": "Japão",
    "cookbook.Itália": "Itália",
    "cookbook.Egito": "Egito",
    "cookbook.Grécia": "Grécia",
    "cookbook.Argentina": "Argentina",
    "cookbook.África": "África",
    "cookbook.Estados Unidos": "Estados Unidos",
    "cookbook.Coreia": "Coreia",
    "cookbook.Turquia": "Turquia",
    "cookbook.Ideal para": "Ideal para",
    "cookbook.Polônia": "Polônia",
    "cookbook.Sanduíches": "Sanduíches",

    //EQUIPAMENTOS
    "equipment.equipamentos": "equipamentos",
    "equipment.procurar equipamento": "Procurar equipamento ou usuário",
    "equipment.adicionar equipamento": "adicionar equipamento",
    "equipment.atualizar equipamento": "atualizar equipamento",
    "equipment.+ equipamentos": "+ equipamentos",
    "equipment.buscar equipamento": "Buscar equipamento",
    "equipment.Desvincular": "Desvincular",
    "equipment.Editar": "Editar",
    "equipment.sem conteúdo": "ainda não há equipamentos aqui :(",
    "equipment.editar equipamento": "editar equipamento",
    "equipment.equipamento editado com sucesso!": "Equipamento editado com sucesso!",
    "equipment.não foi possível editar o equipamento. Tente novamente mais tarde": "Não foi possível editar o equipamento. Tente novamente mais tarde",
    "equipment.Menu exportado com sucesso!": "Menu exportado com sucesso!",
    "equipment.Não foi possível exportar o menu. Tente novamente mais tarde": "Não foi possível exportar o menu. Tente novamente mais tarde",
    "equipment.Selecione pelo menos um equipamento para enviar o menu": "Selecione pelo menos um equipamento para enviar o menu",
    "equipment.Informações do menu": "Informações do menu",
    "equipment.Procurar receita ou grupo": "Procurar receita ou grupo",
    "equipment.Grupo": "Grupo",
    "equipment.Receita": "Receita",
    "equipment.Quantidade": "Quantidade",
    "equipment.Receitas mais executadas no equipamento durante o período": "Receitas mais executadas no equipamento durante o período",
    "equipment.Informações de limpeza": "Informações de limpeza",
    "equipment.Data da limpeza": "Data da limpeza",
    "equipment.Hora da limpeza": "Hora da limpeza",
    "equipment.nome": "Nome",
    "equipment.modelo": "Modelo",
    "equipment.categoria": "Categoria",
    "equipment.loja": "Loja",
    "desvincular equipamento": "Desvincular equipamento",
    "equipment.unlink.paragrafo 1":
      "Você deseja desvincular o equipamento da plataforma IOK?",
    "equipment.unlink.paragrafo 2":
      "Ao fazer isso, não será mais possível gerir suas configurações e menu pela plataforma, a menos que vincule o equipamento novamente, seguindo as instruções de conexão.",
    "equipamento desvinculado com sucesso!":
      "Equipamento desvinculado com sucesso!",
    "equipment.ocorreu um erro. Tente novamente mais tarde":
      "Ocorreu um erro. Tente novamente mais tarde",
    "equipment.sim": "Sim",
    "equipment.não": "Não",
    "equipment.Encerrar acesso remoto": "Encerrar acesso remoto",
    "equipment.Horas sem limpeza": "Horas sem limpeza",
    "equipment.Histórico de eventos": "Histórico de eventos",
    "equipment.Evento": "Evento",
    "equipment.Data": "Data",
    "equipment.ERRO SENSOR RI": "Erro com sensor de resistência inferior",
    "equipment.ERRO COM INV. DE FREQ.": "Erro com inversor de frequência",
    "equipment.ERRO MAGNETRON": "Erro magnetron",
    "equipment.ERRO SENSOR CAMARA": "Erro com sensor de câmara",
    "equipment.ERRO CAN": "Erro CAN",
    "equipment.Erro INVERSOR DE FREQUENCIA": "Erro inversor de frequência",
    "equipment.ERRO DO MODELO DO EQUIPAMENTO": "Erro do modelo do equipamento",
    "equipment.ERRO PAINEL": "Erro painel",
    "equipment.ERRO INV.FREQ.STATUS": "Erro inversor de frequência status",
    "equipment.ERRO SENSOR BOARD": "Erro com sensor da placa",
    "equipment.ERRO ENERGY": "Erro de energia",
    "equipment.ERRO I2C": "Erro I2C",
    "equipment.EQUIPAMENTO LIGADO": "Equipamento ligado",
    "equipment.EQUIPAMENTO DESLIGADO": "Equipamento desligado",
    "equipment.Plataforma": "Plataforma",
    "equipment.Forno": "Forno",
    "equipment.Origem": "Origem",
    "equipment.Locais": "Locais",
    "equipment.não foi possível carregar as entidades": "Não foi possível carregar as entidades",
    "equipment.conceder acesso remoto a": "Conceder acesso remoto a",
    "equipment.atualizar equipamentos": "atualizar equipamentos",
    "equipment.suporte": "suporte",
    "equipment.PIN": "PIN",
    "equipment.nome do equipamento": "Nome do equipamento",
    "equipment.vinculado à loja": "Vinculado à loja",
    "equipment.cadastrar loja": "Cadastrar loja",
    "equipment.informe o PIN do forno": "Informe o PIN do forno",
    "equipment.preencha o PIN do forno": "Preencha o PIN do forno",
    "equipment.adicione um nome para facilitar seu reconhecimento":
      "Adicione um nome para facilitar seu reconhecimento",
    "equipment.preencha o nome do equipamento":
      "Preencha o nome do equipamento",
    "equipment.selecionar": "Selecionar",
    "equipment.nome fantasia": "Nome fantasia",
    "equipment.informe o nome fantasia": "Informe o nome fantasia",
    "equipment.preencha o nome fantasia": "Preencha o nome fantasia",
    "equipment.CNPJ": "CNPJ",
    "equipment.XX.XXX.XXX/0001.XX": "XX.XXX.XXX/0001.XX",
    "equipment.preencha o CNPJ": "Preencha o CNPJ",
    "equipment.CEP": "CEP",
    "equipment.00000-000": "00000-000",
    "equipment.preencha o CEP": "Preencha o CEP",
    "equipment.cidade": "Cidade",
    "equipment.informe a cidade": "Informe a cidade",
    "equipment.preencha a cidade": "Preencha a cidade",
    "equipment.UF": "UF",
    "equipment.bairro": "Bairro",
    "equipment.informe o bairro": "Informe o bairro",
    "equipment.endereço": "Endereço",
    "equipment.informe o seu endereço": "Informe o seu endereço",
    "equipment.preencha o seu endereço": "Preencha o seu endereço",
    "equipment.Nº": "Nº",
    "equipment.equipamento cadastrado com sucesso!":
      "Equipamento cadastrado com sucesso!",
    "equipment.ocorreu um erro": "Ocorreu um erro",
    "equipment.preencha o modelo": "Preencha o modelo",
    "equipment.selecione uma categoria": "Selecione uma categoria",
    "equipment.selecione uma loja": "Selecione uma loja",
    "equipment.preencha o bairro": "Preencha o bairro",
    "equipment.não foi possível cadastrar o equipamento. Tente novamente mais tarde":
      "Não foi possível cadastrar o equipamento. Tente novamente mais tarde",
    "equipment.loja cadastrada com sucesso": "Loja cadastrada com sucesso",
    "equipment.não foi possível cadastrar a loja. Tente novamente mais tarde":
      "Não foi possível cadastrar a loja. Tente novamente mais tarde",
    "equipment.equipment": "equipamentos",
    "equipment.search equipment": "procurar equipamento",
    "equipment.add equipment": "adicionar equipamento",
    "equipment.update equipment": "atualizar equipamento",
    "equipment.plus equipment": "+ equipamento",
    "equipment.Procurar equipamento": "Procurar equipamento",
    "equipment.EQUIPAMENTOS": "EQUIPAMENTOS",
    "equipment.Atualizar equipamento": "Atualizar equipamento",
    "equipment.Atualizar equipamentos": "Atualizar equipamentos",
    "equipment.confirmar": "confirmar",
    "equipment.Selecione os modelos que deseja atualizar:": "Selecione os modelos que deseja atualizar:",
    "equipment.Como deseja realizar a atualização?": "Como deseja realizar a atualização?",
    "equipment.Enviar atualização pela plataforma (wi-fi)": "Enviar atualização pela plataforma (wi-fi)",
    "equipment.Salvar arquivo de atualização em pen drive": "Salvar arquivo de atualização em pen drive",
    "equipment.Linha Express": "Linha Express",
    "equipment.Escolha como deseja realizar a atualização": "Escolha como deseja realizar a atualização",
    "equipment.Selecione ao menos um modelo de equipamento para atualizar": "Selecione ao menos um modelo de equipamento para atualizar",
    "equipment.Status: ": "Status: ",
    "equipment.Número de série:": "Número de série",
    "equipment.Menu:": "Menu",
    "equipment.Status:": "Status: ",
    "equipment.Número de série": "Número de série",
    "equipment.Menu": "Menu",
    "equipment.Loja: ": "Loja: ",
    "equipment.End:": "End: ",
    "equipment.Cidade:": "Cidade: ",
    "equipment.CEP:": "CEP: ",
    "equipment.Atualização de software enviada com sucesso!": "Atualização de software enviada com sucesso!",
    "equipment.Não foi possível enviar a atualização de software. Tente novamente mais tarde": "Não foi possível enviar a atualização de software. Tente novamente mais tarde",
    "equipment.Download realizado com sucesso": "Download realizado com sucesso",
    "equipment.Não foi possível realizar o download. Tente novamente mais tarde": "Não foi possível realizar o download. Tente novamente mais tarde",
    "equipment.Solicitação processada com erro. Tente novamente mais tarde": "Solicitação processada com erro. Tente novamente mais tarde",
    "equipment.Preencha o nome da versão": "Preencha o nome da versão",
    "equipment.Informe aqui todas as alterações desta nova versão": "Informe aqui todas as alterações desta nova versão",
    "equipment.Nova versão de software atualizada com sucesso!": "Nova versão de software atualizada com sucesso!",
    "equipment.Informe pelo menos uma mudança nesta versão": "Informe pelo menos uma mudança nesta versão",
    "equipment.Falha ao transformar o arquivo em Blob": "Falha ao transformar o arquivo em Blob",
    "equipment.Erro ao criar Blob a partir do arquivo": "Erro ao criar Blob a partir do arquivo",
    "equipment.Falha ao ler o arquivo": "Falha ao ler o arquivo",
    "equipment.selecionar tipo": "selecionar tipo",
    "equipment.selecione o tipo da versão": "selecione o tipo da versão",
    "equipment.atualização": "ATUALIZAÇÃO",
    "equipment.Selecione abaixo o que deseja fazer:": "Selecione abaixo o que deseja fazer:",
    "equipment.Atualizar meus equipamentos": "Atualizar meus equipamentos",
    "equipment.Enviar atualização para usuários selecionados": "Enviar atualização para usuários selecionados",
    "equipment.ENVIAR": "ENVIAR",
    "equipment.ENVIAR ATUALIZAÇÃO": "ENVIAR ATUALIZAÇÃO",
    "equipment.Selecione os e-mails para os quais deseja enviar a atualização:": "Selecione os e-mails para os quais deseja enviar a atualização:",
    "equipment.A atualização disponibilizada no dia": "A atualização disponibilizada no dia ",
    "equipment.referente ao equipamento": "referente ao equipamento ",
    "equipment.contempla o seguinte:": "contempla o seguinte:",
    "equipment.Selecionar arquivo para envio": "Selecionar arquivo para envio",
    "equipment.Por favor, selecione um arquivo .tar": "Por favor, selecione um arquivo .tar",
    "equipment.Selecione o modelo do equipamento:": "Selecione o modelo do equipamento:",
    "equipment.Selecione uma opção": "Selecione uma opção",
    "equipment.Nomeie a versão": "Nomeie a versão",
    "equipment.Clique sobre o arquivo que deseja baixar:": "Clique sobre o arquivo que deseja baixar:",
    "equipment.Baixar atualização": "Baixar atualização",
    "equipment.Versões:": "Versões:",
    "equipment.e superiores": "e superiores",
    "equipment.name": "nome",
    "equipment.model": "modelo",
    "equipment.category": "categoria",
    "equipment.store": "loja",
    "FIT Express/FIT ST": "FIT Express/FIT ST",
    "Copa/Single Mag/Rocket": "Copa/Single Mag/Rocket",
    "Forza STi": "Forza STi",
    "TSi": "TSi",
    "FIT Express": "FIT Express",
    "FIT ST": "FIT ST",
    "FIT Express, FIT ST": "FIT Express, FIT ST",
    "Copa Express, Rocket Express, Copa Single Mag": "Copa Express, Rocket Express, Copa Single Mag",
    "equipment.e": "e",
    "equipment.Últimas versões de software:": "Últimas versões de software: ",
    "equipment.Você não possui nenhum equipamento desatualizado": "Você não possui nenhum equipamento desatualizado",
    "equipment.grupos": "grupos",
    "equipment.não foi possível carregar os grupos de equipamentos da companhia": "Não foi possível carregar os grupos de equipamentos da companhia",
    "equipment.vinculado ao grupo": "Vinculado ao grupo",
    "equipment.selecione um grupo": "Selecione um grupo",
    "equipment.cadastrar grupo": "Cadastrar grupo",
    "equipment.Número de Série": "NS",
    "equipment.Versão": "Versão",
    "equipment.não foi possível cadastrar o equipamento. Selecione ou cadastre um grupo": "Não foi possível cadastrar o equipamento. Selecione ou cadastre um grupo",
    "equipment.CRIE GRUPOS DE EQUIPAMENTOS!": "CRIE GRUPOS DE EQUIPAMENTOS!",
    "equipment.Para que os usuários vinculados à conta possam visualizar os equipamentos você precisa:": "Para que os usuários vinculados à conta possam visualizar os equipamentos você precisa:",
    "equipment.1) Criar grupo(s);": "1) Criar grupo(s);",
    "equipment.2) Atribuir equipamento(s) ao grupo;": "2) Atribuir equipamento(s) ao grupo;",
    "equipment.3) Atribuir usuário(s) ao grupo.": "3) Atribuir usuário(s) ao grupo.",
    "equipment.Status software": "Status Software",
    "equipment.Status menu": "Status Menu",
    "equipment.Pendente": "Pendente",
    "equipment.Aceito": "Aceito",
    "equipment.Recusado": "Recusado",
    "equipment.Atualizar": "Atualizar",
    "equipment.Deseja enviar a atualização para o equipamento selecionado?": "Deseja enviar a atualização para o equipamento selecionado?",
    "equipment.Companhia": "Companhia",
    "equipment.Exportar menu": "Exportar menu",
    "equipment.companhias": "companhias",
    "equipment.Nome da companhia": "Nome da companhia",
    "equipment.Procurar equipamento, usuário ou companhia": "Procurar equipamento, usuário ou companhia",
    "equipment.Versão da power": "Versão da power",
    "equipment.AINDA NÃO HÁ DADOS DE RECEITAS AQUI :(": "AINDA NÃO HÁ DADOS DE RECEITAS AQUI :(",
    "equipment.AINDA NÃO HÁ DADOS DE LIMPEZAS AQUI :(": "AINDA NÃO HÁ DADOS DE LIMPEZAS AQUI :(",
    "equipment.AINDA NÃO HÁ DADOS DE EVENTOS AQUI :(": "AINDA NÃO HÁ DADOS DE EVENTOS AQUI :(",
    "ID": "ID",
    "equipment.Hora": "Hora",
    "equipment.EVENTOS MAIS OCORRIDOS NO EQUIPAMENTO DURANTE O PERÍODO": "EVENTOS MAIS OCORRIDOS NO EQUIPAMENTO DURANTE O PERÍODO",
    "equipment.hour": "h",
    "equipment.minutes": "min",
    "equipment.date format": "DD/MM/AAAA",
    "equipment.executada": "executada",
    "equipment.vezes": "vezes",
    "equipment.vez": "vez",
    "equipment.Ver": "Ver",
    "equipment.Os equipamentos enviarão os dados à plataforma ao serem ligados. A cada dois meses, os dados recebidos serão automaticamente excluídos.": "Os equipamentos enviarão os dados à plataforma ao serem ligados. A cada dois meses, os dados recebidos serão automaticamente excluídos.",
    "equipment.Ligado": "Ligado",
    "equipment.Desligado": "Desligado",
    "equipment.CAN ERROR": "Erro CAN",
    "equipment.CAMERA SENSOR ERROR": "Erro no sensor de câmara",
    "equipment.STEAM/RI SENSOR ERROR": "Erro no sensor RI",
    "equipment.PROBE 1 ERROR": "Alerta de sonda 1",
    "equipment.PROBE 2 ERROR": "Alerta de sonda 2",
    "equipment.PROBE 3 ERROR": "Alerta de sonda 3",
    "equipment.PROBE 4 ERROR": "Alerta de sonda 4",
    "equipment.PROBE 5 ERROR": "Alerta de sonda 5",
    "equipment.PROBE 6 ERROR": "Alerta de sonda 6",
    "equipment.GENERAL PROBE ERROR": "Erro geral de sonda",
    "equipment.WATER LACK ERROR": "Alerta de falta de água",
    "equipment.HIGH PANEL TEMPERATURE ERROR": "Alerta de temperatura alta no painel",
    "equipment.FREQUENCY INVERTER GENERAL ERROR": "Erro geral no inversor de frequência",
    "equipment.FREQUENCY INVERTER COMMUNICATION ERROR": "Alerta de comunicação no inversor de frequência",
    "equipment.FREQUENCY INVERTER STATUS ERROR": "Alerta no status do inversor de frequência",
    "equipment.CLEANING NOT COMPLETED ERROR": "Alerta de higienização incompleta",
    "equipment.MAGNETRON ERROR": "Alerta de magnetron",
    "equipment.EQUIPMENT FAMILY/MODEL ERROR": "Erro do modelo do equipamento",
    "equipment.BOARD TEMPERATURE SENSOR ERROR": "Alerta no sensor de temperatura da placa",
    "equipment.ENERGY MODULE ERROR": "Erro no módulo de energia",
    "equipment.POWER BOARD I2C BUS ERROR": "Erro na placa power",
    "equipment.GAS SYSTEM ERROR": "Erro gás",
    "equipment.VERY HIGH PANEL TEMPERATURE ERROR": "Erro de temperatura super alta no painel",
    "equipment.POWER ON": "Ligado",
    "equipment.POWER OFF": "Desligado",
    "equipment.DOOR OPENED": "Porta aberta",
    "equipment.DOOR CLOSED": "Porta fechada",
    "equipment.CLEANING CANCELED ERROR": "Higienização cancelada pelo usuário",
    "equipment.PIN não encontrado. Por favor, verifique e tente novamente": "PIN não encontrado. Por favor, verifique e tente novamente",
    "equipment.Online": "Online",
    "equipment.Offline": "Offline",
    "equipment.Exibir apenas eventos": "Exibir apenas eventos",
    "equipment.Endereço": "Endereço",
    "equipment.EQUIPMENT CONNECTED TO THE PLATFORM": "Equipamento conectado na plataforma",
    "equipment.EQUIPMENT DISCONNECTED FROM THE PLATFORM": "Equipamento desconectado da plataforma",
    "equipment.MENU RECEIVED FROM THE PLATFORM": "Menu recebido da plataforma",
    "equipment.MENU REJECTED BY THE USER": "Menu rejeitado pelo usuário",
    "equipment.CONNECTED TO THE INTERNET": "Conectado a internet",
    "equipment.DISCONNECTED FROM THE INTERNET": "Desconectado da internet",
    "equipment.Infelizmente não há versões que realizem o envio de dados compatíveis com este equipamento.": "Infelizmente não há versões que realizem o envio de dados compatíveis com este equipamento.",
    "equipment.O equipamento": "O equipamento",
    "equipment.deve ser atualizado para a versão mais recente de software para que passe a enviar os dados para a plataforma": "deve ser atualizado para a versão mais recente de software para que passe a enviar os dados para a plataforma",
    "equipment.Versão do seu equipamento": "Versão do seu equipamento",
    "equipment.Versão disponível": "Versão disponível",
    "equipment.Selecione abaixo como deseja enviar a atualização para seu equipamento": "Selecione abaixo como deseja enviar a atualização para seu equipamento",
    "equipment.Salvar em pen drive": "Salvar em pen drive",
    "equipment.Enviar por wi-fi": "Enviar por wi-fi",
    "equipment.Cliente": "Cliente",
    "equipment.NÚMERO DE SÉRIE NÃO ENCONTRADO": "NÚMERO DE SÉRIE NÃO ENCONTRADO",
    "equipment.BUSCAR": "BUSCAR",
    "equipment.ACESSADOS RECENTEMENTE": "ACESSADOS RECENTEMENTE",
    "equipment.SEM BUSCAS RECENTES": "SEM BUSCAS RECENTES",
    "equipment.Procurar equipamento por NS": "Procurar equipamento por NS",
    "equipment.AIR FILTER CLEANING COMPLETED": "Limpeza do filtro de ar concluída",
    "equipment.AIR FILTER CLEANING REJECTED": "Limpeza do filtro de ar rejeitada",
    "equipment.CATALYTIC FILTER CLEANING COMPLETED": "Limpeza do filtro catalítico concluída",
    "equipment.CATALYTIC FILTER CLEANING REJECTED": "Limpeza do filtro catalítico rejeitada",
    "equipment.Endereço (rua, avenida, rodovia)": "Endereço (rua, avenida, rodovia)",
    "equipment.Nº/Km": "Nº/Km",
    "equipment.ID do equipamento": "ID do equipamento",
    "equipment.Todo equipamento deve ser vinculado a um grupo. Selecione um grupo da lista ou cadastre um novo clicando em + Cadastrar grupo.": "Todo equipamento deve ser vinculado a um grupo. Selecione um grupo da lista ou cadastre um novo clicando em + Cadastrar grupo.",
    "equipment.Nomeie seu forno": "Nomeie seu forno",
    "equipment.Para visualizar o PIN, acesse nas configurações do equipamento a Plataforma Virtual IOK.": "Para visualizar o PIN, acesse nas configurações do equipamento a Plataforma Virtual IOK.",
    "equipment.Vinculação IOK": "Vinculação IOK",
    "equipment.Execuções": "Execuções",
    "equipment.Última execução": "Última execução",
    "equipment.localização": "localização",
    "equipment.AINDA NÃO HÁ NENHUMA LOCALIZAÇÃO CADASTRADA :(": "AINDA NÃO HÁ NENHUMA LOCALIZAÇÃO CADASTRADA :(",
    "equipment.Procurar empresa ou local": "Procurar empresa ou local",
    "equipment.Adicionar localização": "Adicionar localização",
    "equipment.espaço": "Espaço",
    "equipment.local": "Local",
    "equipment.EXCLUIR LOCALIZAÇÃO": "EXCLUIR LOCALIZAÇÃO",
    "equipment.Você deseja excluir esta localização?": "Você deseja excluir esta localização?",
    "equipment.Ao fazer isso, os equipamentos atrelados à ela não exibirão nenhuma localização, sendo necessário informar um novo local para eles.": "Ao fazer isso, os equipamentos atrelados à ela não exibirão nenhuma localização, sendo necessário informar um novo local para eles.",
    "equipment.Localização deletada com sucesso!": "Localização deletada com sucesso!",
    "equipment.Erro ao deletar a localização. Tente novamente mais tarde": "Erro ao deletar a localização. Tente novamente mais tarde",
    "equipment.Não foi possivel deletar a localização": "Não foi possível deletar a localização",
    "equipment.Localização adicionada com sucesso!": "Localização adicionada com sucesso!",
    "equipment.Não foi possível adicionar a localização. Tente novamente mais tarde": "Não foi possível adicionar a localização. Tente novamente mais tarde",
    "equipment.Nome do espaço": "Nome do espaço",
    "equipment.Informe o nome do espaço": "Informe o nome do espaço",
    "equipment.Preencha o nome do espaço": "Preencha o nome do espaço",
    "equipment.país": "País",
    "equipment.informe o país": "Informe o país",
    "equipment.preencha o país": "Preencha o país",
    "equipment.Localização editada com sucesso!": "Localização editada com sucesso!",
    "equipment.Não foi possível editar a localização. Tente novamente mais tarde": "Não foi possível editar a localização. Tente novamente mais tarde",
    "equipment.Editar localização": "Editar localização",
    "equipment.Pico de execuções": "Pico de execuções",
    "equipment.informações da semana": "informações da semana",
    "equipment.horários de pico": "horários de pico",
    "equipment.horário de pico": "horário de pico",
    "equipment.execuções do dia": "execuções do dia",
    "equipment.Horário": "Horário",
    "equipment.dia selecionado": "dia selecionado",
    "equipment.A limpeza adequada e diária do forno auxilia em seu funcionamento correto e impacta na garantia.": "A limpeza adequada e diária do forno auxilia em seu funcionamento correto e impacta na garantia.",
    "equipment.Total de limpezas realizadas:": "Total de limpezas realizadas:",
    "equipment.Limpezas não concluídas:": "Limpezas não concluídas:",
    "equipment.Desde a vinculação do forno a IOK": "Desde a vinculação do forno a IOK",
    "equipment.Equipamento ligado há:": "Equipamento ligado há:",
    "equipment.Total de receitas executadas:": "Total de receitas executadas:",
    "equipment.Receitas executadas:": "Receitas executadas:",
    "equipment.Aberturas de porta:": "Aberturas de porta:",
    "equipment.Desde a última vez que o forno foi ligado": "Desde a última vez que o forno foi ligado",
    "equipment.Execução": "Execução",
    "equipment.Nenhuma localização criada": "Nenhuma localização criada",
    "equipment.Acesso remoto": "Acesso remoto",
    "equipment.Informe o ID da empresa que poderá acessar remotamente seu forno. Use ; para separar IDs.": "Informe o ID da empresa que poderá acessar remotamente seu forno. Use ; para separar IDs.",
    "equipment.O acesso remoto permite que as empresas selecionadas enviem menus e atualizações para seu equipamento.": "O acesso remoto permite que as empresas selecionadas enviem menus e atualizações para seu equipamento.",
    "equipment.Concedo acesso remoto à Prática oficial.": "Concedo acesso remoto à Prática oficial.",
    "equipment.Deseja selecionar outros equipamentos vinculados à sua conta para conceder acesso remoto a eles?": "Deseja selecionar outros equipamentos vinculados à sua conta para conceder acesso remoto a eles?",
    "equipment.Selecione abaixo os equipamentos para os quais deseja conceder acesso remoto à": "Selecione abaixo os equipamentos para os quais deseja conceder acesso remoto à",
    "equipment.Acesso remoto concedido com sucesso!": "Acesso remoto concedido com sucesso!",
    "equipment.Selecione pelo menos um equipamento": "Selecione pelo menos um equipamento",
    "equipment.Não foi possível editar os equipamentos. Tente novamente mais tarde": "Não foi possível editar os equipamentos. Tente novamente mais tarde",
    "equipment.Solicitar atendimento": "Solicitar atendimento",
    "equipment.Relate um problema": "Relate um problema",
    "equipment.Descreva aqui o problema de seu equipamento": "Descreva aqui o problema de seu equipamento",
    "equipment.Não foi possível enviar sua solicitação de atendimento. Tente mais tarde": "Não foi possível enviar sua solicitação de atendimento. Tente mais tarde",
    "equipment.Solicitação técnica enviada com sucesso!": "Solicitação técnica enviada com sucesso!",
    "equipment.Enviar": "Enviar",
    "equipment.Solicitação de atendimento": "Solicitação de atendimento",
    "equipment.Informe o problema de seu equipamento": "Informe o problema de seu equipamento",
    "equipment.Informações do magnetron": "Informações do magnetron",
    "equipment.Vida útil aproximada": "Vida útil aproximada",
    "equipment.MAGNETRON USAGE HOURS": "Horas de uso do magnetron",
    "equipment.Atendimento": "Atendimento",
    "equipment.Eventos mais ocorridos no equipamento durante o período": "Eventos mais ocorridos no equipamento durante o período",
    "equipment.EQUIPAMENTO SEM NÚMERO DE SÉRIE": "EQUIPAMENTO SEM NÚMERO DE SÉRIE",
    "equipment.Filtros": "Filtros",
    "equipment.Filtrar": "Filtrar",
    "equipment.Limpar filtros": "Limpar filtros",
    "equipment.Procurar por modelo, versão ou país": "Procurar por modelo, versão ou país",
    "equipment.Procurar equipamento, NS, modelo, versão ou país": "Procurar equipamento, NS, modelo, versão ou país",
    "equipment.Os seguintes IDs não pertencem a uma companhia do tipo 'Distribuidor' e foram removidos": "Os seguintes IDs não pertencem a uma companhia do tipo 'Distribuidor' e foram removidos",
    "equipment.BAIXAR VERSÕES": "BAIXAR VERSÕES",
    "equipment.Compatível com todas as versões da IHM.": "Compatível com todas as versões da IHM.",
    "equipment.Compatível apenas com a versão 2.0.0C da Power.": "Compatível apenas com a versão 2.0.0C da Power.",

    //LOJAS
    "store.Procurar loja": "Procurar loja",
    "store.Excluir": "Excluir",
    "store.Editar": "Editar",
    "store.nome": "Nome",
    "store.equipamentos": "Equipamentos",
    "store.cidade": "Cidade",
    "store.estado": "Estado",
    "store.salvar": "salvar",
    "store.cadastrar loja": "cadastrar loja",
    "store.Nome fantasia": "Nome fantasia",
    "store.Preencha o nome da loja": "Preencha o nome da loja",
    "store.CNPJ": "CNPJ",
    "store.Preencha o CNPJ da loja": "Preencha o CNPJ da loja",
    "store.CEP": "CEP",
    "store.Preencha o CEP da loja": "Preencha o CEP da loja",
    "store.Informe o nome fantasia": "Informe o nome fantasia",
    "store.Cidade": "Cidade",
    "store.Informe a cidade": "Informe a cidade",
    "store.Preencha a cidade": "Preencha a cidade",
    "store.UF": "UF",
    "store.Preencha o UF da loja": "Preencha o UF da loja",
    "store.Bairro": "Bairro",
    "store.Informe o bairro": "Informe o bairro",
    "store.Preencha o bairro da loja": "Preencha o bairro da loja",
    "store.Endereço": "Endereço",
    "store.Informe o endereço": "Informe o endereço",
    "store.Preencha o endereço da loja": "Preencha o endereço da loja",
    "store.Nº": "Nº",
    "store.Preencha o número": "Preencha o número",
    "store.Loja cadastrada com sucesso": "Loja cadastrada com sucesso",
    "store.Erro ao adicionar loja. Tente novamente":
      "Erro ao adicionar loja. Tente novamente",
    "store.Loja deletada com sucesso": "Loja deletada com sucesso",
    "store.Erro ao deletar loja. Tente novamente mais tarde":
      "Erro ao deletar loja. Tente novamente mais tarde",
    "store.AINDA NÃO HÁ NENHUMA LOJA CADASTRADA :(": "AINDA NÃO HÁ NENHUMA LOJA CADASTRADA :(",
    "store.EXCLUIR LOJA": "EXCLUIR LOJA",
    "store.Você deseja excluir a loja da plataforma IOK?": "Você deseja excluir a loja da plataforma IOK?",
    "store.Ao fazer isso, os equipamentos atrelados à ela não terão vínculo com nenhuma loja, a menos que os vincule novamente.": "Ao fazer isso, os equipamentos atrelados à ela não terão vínculo com nenhuma loja, a menos que os vincule novamente.",
    "store.loja editada com sucesso": "Loja editada com sucesso",
    "store.não foi possível editar a loja. Tente novamente mais tarde": "Não foi possível editar a loja. Tente novamente mais tarde",
    "store.editar loja": "editar loja",
    "store.Procurar grupo de equipamentos": "Procurar grupo de equipamentos",
    "store.cadastrar grupo": "cadastrar grupo",
    "store.AINDA NÃO HÁ NENHUM GRUPO CADASTRADO :(": "AINDA NÃO HÁ NENHUM GRUPO CADASTRADO :(",
    "store.cadastrar grupo de equipamentos": "cadastrar grupo de equipamentos",
    "store.grupo de equipamentos cadastrado com sucesso!": "Grupo de equipamentos cadastrado com sucesso!",
    "store.não foi possível cadastrar o grupo. Tente novamente mais tarde": "Não foi possível cadastrar o grupo. Tente novamente mais tarde",
    "store.Nome do grupo": "Nome do grupo",
    "store.informe o nome do grupo": "Informe o nome do grupo",
    "store.preencha o nome do grupo": "Preencha o nome do grupo",
    "store.Grupo de equipamentos deletado com sucesso!": "Grupo de equipamentos deletado com sucesso!",
    "store.Erro ao deletar o grupo. Tente novamente mais tarde": "Erro ao deletar o grupo. Tente novamente mais tarde",
    "store.EXCLUIR GRUPO DE EQUIPAMENTOS": "EXCLUIR GRUPO DE EQUIPAMENTOS",
    "store.Você deseja excluir este grupo de equipamentos da plataforma IOK?": "Você deseja excluir este grupo de equipamentos da plataforma IOK?",
    "store.Ao fazer isso, os equipamentos atrelados à ele não terão vínculo com nenhum grupo, a menos que os vincule novamente.": "Ao fazer isso, os equipamentos atrelados à ele não terão vínculo com nenhum grupo, a menos que os vincule novamente.",
    "store.grupo de equipamentos editado com sucesso!": "Grupo de equipamentos editado com sucesso!",
    "store.não foi possível editar o grupo. Tente novamente mais tarde": "Não foi possível editar o grupo. Tente novamente mais tarde",
    "store.editar grupo de equipamentos": "editar grupo de equipamentos",
    "store.CEP não encontrado no Brasil": "CEP não encontrado no Brasil",

    // NAVBAR
    "navbar.home": "home",
    "navbar.cookbook": "cookbook",
    "navbar.my menus": "meus menus",
    "navbar.equipment": "equipamentos",
    "navbar.users": "usuários",
    "navbar.calls": "atendimentos",
    "navbar.settings": "configurações",
    "navbar.help": "ajuda",
    "navbar.logout": "Sair",
    "navbar.Editar conta": "Editar conta",
    "navbar.API Externa": "API Externa",

    // FOOTERBAR
    "footerBar.menu": "Menu",
    "footerBar.cookbook": "Cookbook",
    "footerBar.home": "Home",
    "footerBar.menus": "Menus",
    "footerBar.notifications": "Notificações",

    //ATENDIMENTOS
    "calls.ATENDIMENTOS EM ABERTO": "ATENDIMENTOS EM ABERTO",
    "calls.ATENDIMENTOS ENCERRADOS": "ATENDIMENTOS ENCERRADOS",

    //MAP
    "map.título": "Localização",
    "map.erro": "erro!",
    "map.mensagem de erro": "não foi possível carregar o mapa.",
    "map.Localização não disponível": "Localização não disponível",

    //MODAL
    "modal.CONFIRMAR": "CONFIRMAR",
    "modal.Salvar alterações": "Salvar alterações",
    "Modal.Há alterações não salvas nos parâmetros dos passos ":
      "Há alterações não salvas nos parâmetros dos passos ",
    "Modal.Como deseja proceder?": "Como deseja proceder?",
    "Modal.Avaliar alterações realizadas": "Avaliar alterações realizadas",
    "Modal.Descartar alterações": "Descartar alterações",

    //USUÁRIOS
    "user.Nome": "Nome",
    "user.E-mail": "E-mail",
    "user.Permissão": "Permissão",
    "user.Loja": "Loja",
    "user.Status": "Status",
    "user.Excluir": "Excluir",
    "user.Editar": "Editar",
    "user.Procurar usuário": "Procurar usuário",
    "user.ADICIONAR USUÁRIO": "ADICIONAR USUÁRIO",
    "user.AINDA NÃO HÁ NENHUM USUÁRIO CADASTRADO :(": "AINDA NÃO HÁ NENHUM USUÁRIO CADASTRADO :(",
    "user.+ Usuário": "+ Usuário",
    "user.Informe o nome do usuário": "Informe o nome do usuário",
    "user.Preencha o nome do usuário": "Preencha o nome do usuário",
    "user.Informe o e-mail do usuário": "Informe o e-mail do usuário",
    "user.Preencha com um e-mail válido": "Preencha com um e-mail válido",
    "user.Administrador": "Administrador",
    "user.Usuário": "Usuário",
    "user.Preencha o telefone do usuário": "Preencha o telefone do usuário",
    "user.Selecione a permissão do usuário": "Selecione a permissão do usuário",
    "user.Ativo": "Ativo",
    "user.Inativo": "Inativo",
    "user.Usuário deletado da plataforma com sucesso!": "Usuário deletado da plataforma com sucesso!",
    "user.Ocorreu um erro. Tente novamente mais tarde": "Ocorreu um erro. Tente novamente mais tarde",
    "user.EXCLUIR USUÁRIO": "EXCLUIR USUÁRIO",
    "user.delete.paragrafo 1": "Você deseja excluir o usuário da plataforma IOK?",
    "user.delete.paragrafo 2": "Ao fazer isso, ele perderá o acesso a todas as ferramentas da plataforma, a menos que seja adicionado à ela novamente, seguindo as instruções de cadastro.",
    "user.EDITAR USUÁRIO": "EDITAR USUÁRIO",
    "user.Usuário editado com sucesso!": "Usuário editado com sucesso!",
    "user.Não foi possível editar este usuário. Por favor, tente mais tarde": "Não foi possível editar este usuário. Por favor, tente mais tarde",
    "user.INFORMAÇÕES DO USUÁRIO": "INFORMAÇÕES DO USUÁRIO",
    "user.Telefone": "Telefone",
    "user.Não foi possível cadastrar este usuário. Por favor, tente mais tarde": "Não foi possível cadastrar este usuário. Por favor, tente mais tarde",
    "user.Usuário adicionado com sucesso!": "Usuário adicionado com sucesso!",
    "user.CRIAR TIPO DE USUÁRIO": "CRIAR TIPO DE USUÁRIO",
    "user.Criar novo tipo de usuário": "Criar novo tipo de usuário",
    "user.Adicionar novo usuário": "Adicionar novo usuário",
    "user.Permissões": "Permissões",
    "user.Preencha o nome do novo tipo de usuário": "Preencha o nome do novo tipo de usuário",
    "user.Selecione pelo menos uma permissão": "Selecione pelo menos uma permissão",
    "user.Não foi possível carregar as permissões": "Não foi possível carregar as permissões",
    "user.Tipo de usuário criado com sucesso!": "Tipo de usuário criado com sucesso!",
    "user.Não foi possível criar este novo tipo de usuário. Por favor, tente mais tarde": "Não foi possível criar este novo tipo de usuário. Por favor, tente mais tarde",
    "user.Não foi possível carregar os tipos de usuário da companhia": "Não foi possível carregar os tipos de usuário da companhia",
    "user.Tipo de usuário": "Tipo de usuário",
    "user.E-mail já cadastrado na plataforma. Informe um novo e-mail": "E-mail já cadastrado na plataforma. Informe um novo e-mail",
    "user.UNIR CONTAS": "UNIR CONTAS",
    "user.ID da companhia": "ID da companhia",
    "user.Digite o ID da companhia que deseja unir a sua conta": "Digite o ID da companhia que deseja unir a sua conta",
    "user.Preencha o ID da companhia": "Preencha o ID da companhia",
    "user.O administrador da outra conta poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.": "O administrador da outra conta poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.",
    "user.O administrador da outra conta receberá um e-mail para aceitar sua solicitação. Assim que for aceita, a união das duas contas será realizada!": "O administrador da outra conta receberá um e-mail para aceitar sua solicitação. Assim que for aceita, a união das duas contas será realizada!",
    "user.Não foi possível solicitar a união de contas. Por favor, confirme o ID ou tente mais tarde": "Não foi possível solicitar a união de contas. Por favor, confirme o ID ou tente mais tarde",
    "user.SOLICITAÇÃO APROVADA COM SUCESSO": "SOLICITAÇÃO APROVADA COM SUCESSO",
    "user.AGUARDE ENQUANTO REALIZAMOS A UNIÃO DAS CONTAS. ESTE PROCESSO PODE LEVAR ALGUNS MINUTOS...": "AGUARDE ENQUANTO REALIZAMOS A UNIÃO DAS CONTAS. ESTE PROCESSO PODE LEVAR ALGUNS MINUTOS...",
    "user.UNIÃO DE CONTAS FINALIZADA COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "UNIÃO DE CONTAS FINALIZADA COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.",
    "user.NÃO CONSEGUIMOS REALIZAR A UNIÃO DAS CONTAS. POR FAVOR, TENTE NOVAMENTE. SE O PROBLEMA PERSISTIR, ENTRE EM CONTATO COM NOSSO SUPORTE.": "NÃO CONSEGUIMOS REALIZAR A UNIÃO DAS CONTAS. POR FAVOR, TENTE NOVAMENTE. SE O PROBLEMA PERSISTIR, ENTRE EM CONTATO COM NOSSO SUPORTE.",
    "user.possui todos os acessos": "possui todos os acessos",
    "user.possui o acesso das abas cookbook, meus menus e equipamentos": "possui o acesso das abas cookbook, meus menus e equipamentos",
    "user.possui apenas o acesso da aba cookbook": "possui apenas o acesso da aba cookbook",
    "user.possui apenas o acesso da aba equipamentos": "possui apenas o acesso da aba equipamentos",
    "user.possui apenas o acesso da aba meus menus": "possui apenas o acesso da aba meus menus",
    "user.Grupo de equipamentos": "Grupo de equipamentos",
    "user.Informe o telefone do usuário": "Informe o telefone do usuário",
    "user.Nenhum grupo criado": "Nenhum grupo criado",
    "user.Digite o ID da companhia que terá acesso à sua conta": "Digite o ID da companhia que terá acesso à sua conta",
    "user.Ao informar um ID para unir contas com sua companhia, esteja ciente de que o novo usuário também se tornará Administrador da conta de sua companhia. É importante informar que a partir do momento em que um usuário aceitar unir contas com sua companhia, a companhia pertencente a ele deixará de existir.":
      "Ao informar um ID para unir contas com sua companhia, esteja ciente de que o novo usuário também se tornará Administrador da conta de sua companhia. É importante informar que a partir do momento em que um usuário aceitar unir contas com sua companhia, a companhia pertencente a ele deixará de existir.",
    "user.Exemplo: Sua conta: 001 | Outra conta: 111. Depois de unir contas com você, a outra conta passará a ser 001.": "Exemplo: Sua conta: 001 | Outra conta: 111. Depois de unir contas com você, a outra conta passará a ser 001.",

    //TUTORIAIS 
    "tutorial.Fechar": "Fechar",
    "tutorial.Fim": "Fim",
    "tutorial.Próximo": "Próximo",
    "tutorial.Abrir": "Abrir",
    "tutorial.Pular tutorial": "Pular tutorial",
    "tutorial.Editar detalhes da receita": "Editar detalhes da receita",
    "tutorial.Agora você pode adicionar mais detalhes às suas receitas e deixá-las ainda mais completas. Clique em Editar detalhes e preencha as informações necessárias.": "Agora você pode adicionar mais detalhes às suas receitas e deixá-las ainda mais completas. Clique em Editar detalhes e preencha as informações necessárias.",
    "tutorial.Salvar edição da receita": "Salvar edição da receita",
    "tutorial.Depois de informar mais detalhes da receita, clique em Salvar.": "Depois de informar mais detalhes da receita, clique em Salvar.",
    "tutorial.Editar registro de um equipamento": "Editar registro de um equipamento",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Categoria e Loja.": "Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Categoria e Loja.",
    "tutorial.Editar usuário": "Editar usuário",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente a Loja a qual ele faz parte.": "Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente a Loja a qual ele faz parte.",
    "tutorial.Salvar edições de um equipamento": "Salvar edições de um equipamento",
    "tutorial.Depois de completar o registro de seus equipamentos, clique em Salvar.": "Depois de completar o registro de seus equipamentos, clique em Salvar.",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em uma loja, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "Ao clicar sobre a linha de um equipamento cadastrado em uma loja, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.",
    "tutorial.Para visualizar mais informações sobre a loja, como localização e receitas mais executadas, clique sobre a linha da unidade que deseja analisar.": "Para visualizar mais informações sobre a loja, como localização e receitas mais executadas, clique sobre a linha da unidade que deseja analisar.",
    "tutorial.Criar receita": "Criar receita",
    "tutorial.No cookbook você pode criar suas próprias receitas! Clique em Criar Receita e preencha todas as informações necessárias.": "No cookbook você pode criar suas próprias receitas! Clique em Criar Receita e preencha todas as informações necessárias.",
    "tutorial.Editar ou excluir um usuário": "Editar ou excluir um usuário",
    "tutorial.Para excluir ou editar as informações de um usuário já cadastrado, clique no menu lateral e selecione a opção desejada.": "Para excluir ou editar as informações de um usuário já cadastrado, clique no menu lateral e selecione a opção desejada.",
    "tutorial.Gerenciamento de usuários": "Gerenciamento de usuários",
    "tutorial.Aqui você pode gerenciar seus usuários com mais facilidade. Ao clicar em + Usuário, você poderá adicionar um novo usuário ou criar um novo tipo de usuário.": "Aqui você pode gerenciar seus usuários com mais facilidade. Ao clicar em + Usuário, você poderá adicionar um novo usuário ou criar um novo tipo de usuário.",
    "tutorial.Suas receitas": "Suas receitas",
    "tutorial.Pelo cookbook você também encontra suas próprias receitas, além de poder criá-las! Clique em Receitas Salvas para visualizar as receitas que foram salvas do cookbook da Prática e também as criadas por você.": "Pelo cookbook você também encontra suas próprias receitas, além de poder criá-las! Clique em Receitas Salvas para visualizar as receitas que foram salvas do cookbook da Prática e também as criadas por você.",
    "tutorial.Filtros do Cookbook": "Filtros do Cookbook",
    "tutorial.Para facilitar a navegação pelas receitas, você pode aplicar filtros a elas. Basta clicar no filtro desejado e escolher uma de suas opções.": "Para facilitar a navegação pelas receitas, você pode aplicar filtros a elas. Basta clicar no filtro desejado e escolher uma de suas opções.",
    "tutorial.Cookbook": "Cookbook",
    "tutorial.No cookbook, em Receitas Disponíveis, você encontrará as receitas criadas pelos Chefs da Prática e poderá utilizá-las sempre que quiser.": "No cookbook, em Receitas Disponíveis, você encontrará as receitas criadas pelos Chefs da Prática e poderá utilizá-las sempre que quiser.",
    "tutorial.Atualizar equipamentos": "Atualizar equipamentos",
    "tutorial.Para enviar uma atualização a seus equipamentos, clique em Atualizar Equipamentos, selecione os modelos que deseja atualizar e a forma como deseja realizar essa atualização - via wi-fi ou pen drive. Depois disso, clique em Confirmar.": "Para enviar uma atualização a seus equipamentos, clique em Atualizar Equipamentos, selecione os modelos que deseja atualizar e a forma como deseja realizar essa atualização - via wi-fi ou pen drive. Depois disso, clique em Confirmar.",
    "tutorial.Adicionar equipamento": "Adicionar equipamento",
    "tutorial.Para adicionar um equipamento à sua conta, clique em Adicionar Equipamento e preencha as informações do modal. Depois disso, clique em Salvar.": "Para adicionar um equipamento à sua conta, clique em Adicionar Equipamento e preencha as informações do modal. Depois disso, clique em Salvar.",
    "tutorial.Editar equipamento": "Editar equipamento",
    "tutorial.Para desvincular um equipamento ou editar suas informações, clique no menu lateral e selecione a opção desejada.": "Para desvincular um equipamento ou editar suas informações, clique no menu lateral e selecione a opção desejada.",
    "tutorial.Seus equipamentos": "Seus equipamentos",
    "tutorial.Aqui você visualizará todos os equipamentos cadastrados em sua plataforma.": "Aqui você visualizará todos os equipamentos cadastrados em sua plataforma.",
    "tutorial.Restaurar arquivos": "Restaurar arquivos",
    "tutorial.Para restaurar um arquivo excluído ou deletá-lo permanentemente, clique no menu lateral e selecione a opção desejada.": "Para restaurar um arquivo excluído ou deletá-lo permanentemente, clique no menu lateral e selecione a opção desejada.",
    "tutorial.Lixeira": "Lixeira",
    "tutorial.Os menus excluídos serão mantidos na Lixeira por 30 dias. Depois desse prazo serão excluídos permanentemente.": "Os menus excluídos serão mantidos na Lixeira por 30 dias. Depois desse prazo serão excluídos permanentemente.",
    "tutorial.Salvar edições de usuário": "Salvar edições de usuário",
    "tutorial.Depois de completar o cadastro dos usuários, clique em Salvar.": "Depois de completar o cadastro dos usuários, clique em Salvar.",
    "tutorial.Criar menu": "Criar menu",
    "tutorial.Para criar e personalizar um menu, clique em Criar Menu e preencha as informações necessárias no modal. Você pode editar um menu a qualquer momento.": "Para criar e personalizar um menu, clique em Criar Menu e preencha as informações necessárias no modal. Você pode editar um menu a qualquer momento.",
    "tutorial.Importar menu": "Importar menu",
    "tutorial.Para importar um menu, clique em Importar Menu e selecione o modelo e versão do equipamento.": "Para importar um menu, clique em Importar Menu e selecione o modelo e versão do equipamento.",
    "tutorial.Criar grupo": "Criar grupo",
    "tutorial.Para organizar suas receitas em grupos, clique em Criar Grupo e preencha as informações necessárias no modal.": "Para organizar suas receitas em grupos, clique em Criar Grupo e preencha as informações necessárias no modal.",
    "tutorial.Tipos de usuário": "Tipos de usuário",
    "tutorial.Agora você também pode criar tipos de usuários com permissões específicas para atender melhor a sua organização.": "Agora você também pode criar tipos de usuários com permissões específicas para atender melhor a sua organização.",
    "tutorial.Gerenciamento de contas": "Gerenciamento de contas",
    "tutorial.Se caso outra pessoa da sua organização também migrou a conta principal da empresa, aqui você pode unir as duas contas.": "Se caso outra pessoa da sua organização também migrou a conta principal da empresa, aqui você pode unir as duas contas.",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Grupo.": "Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Grupo.",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente ao Grupo de equipamentos a qual ele faz parte.": "Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente ao Grupo de equipamentos a qual ele faz parte.",
    "tutorial.Grupos de equipamentos": "Grupos de equipamentos",
    "tutorial.Na aba Grupos você pode visualizar os grupos cadastrados e os equipamentos distribuídos entre eles. Além disso, é possível cadastrar novos grupos e editar os já cadastrados.": "Na aba Grupos você pode visualizar os grupos cadastrados e os equipamentos distribuídos entre eles. Além disso, é possível cadastrar novos grupos e editar os já cadastrados.",
    "tutorial.Detalhes de grupos": "Detalhes de grupos",
    "tutorial.Para visualizar mais informações sobre o grupo, como localização e receitas mais executadas, clique sobre a linha que deseja analisar.": "Para visualizar mais informações sobre o grupo, como localização e receitas mais executadas, clique sobre a linha que deseja analisar.",
    "tutorial.Equipamentos em grupos": "Equipamentos em grupos",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em um grupo, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "Ao clicar sobre a linha de um equipamento cadastrado em um grupo, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.",
    "tutorial.Grupos": "Grupos",
    "tutorial.Atualização e cache": "Atualização e cache",
    "tutorial.Para atualizar a plataforma e corrigir eventuais problemas de cache, clique aqui.": "Para atualizar a plataforma e corrigir eventuais problemas de cache, clique aqui.",
    "tutorial.Dados dos equipamentos": "Dados dos equipamentos",
    "tutorial.Ao clicar sobre a linha de um equipamento, você pode visualizar mais estatísticas e informações sobre ele, como as receitas mais executadas, limpezas realizadas e outros dados relevantes.": "Ao clicar sobre a linha de um equipamento, você pode visualizar mais estatísticas e informações sobre ele, como as receitas mais executadas, limpezas realizadas e outros dados relevantes.",
    "tutorial.Para alterar o idioma de sua plataforma, clique em Idioma e selecione a linguagem desejada.": "Para alterar o idioma de sua plataforma, clique em Idioma e selecione a linguagem desejada.",
    "tutorial.Alterar idioma": "Alterar idioma",
    "tutorial.Para editar ou configurar sua conta, clique em seu nome e selecione a opção desejada.": "Para editar ou configurar sua conta, clique em seu nome e selecione a opção desejada.",
    "tutorial.Editar conta": "Editar conta",
    "tutorial.Total de Usuários Cadastrados em sua conta.": "Total de Usuários Cadastrados em sua conta.",
    "tutorial.Home": "Home",
    "tutorial.Localização de seus equipamentos, sendo possível expandir o mapa para melhor visualização;": "Localização de seus equipamentos, sendo possível expandir o mapa para melhor visualização;",
    "tutorial.Atualizações referentes a versão dos equipamentos vinculados a sua conta;": "Atualizações referentes a versão dos equipamentos vinculados a sua conta;",
    "tutorial.Total de Receitas Cadastradas;": "Total de Receitas Cadastradas;",
    "tutorial.Menus Disponíveis;": "Menus Disponíveis;",
    "tutorial.Esta é a sua home! Aqui você encontra um resumo geral sobre sua conta, como quantidade de Equipamentos Vinculados;": "Esta é a sua home! Aqui você encontra um resumo geral sobre sua conta, como quantidade de Equipamentos Vinculados;",
    "tutorial.Clique nas abas do menu para explorar a plataforma e conhecer tudo o que ela pode oferecer.": "Clique nas abas do menu para explorar a plataforma e conhecer tudo o que ela pode oferecer.",
    "tutorial.Exportar menu": "Exportar menu",
    "tutorial.Depois de personalizar seu menu, exporte-o para seus equipamentos pela internet ou por pen drive.": "Depois de personalizar seu menu, exporte-o para seus equipamentos pela internet ou por pen drive.",
    "tutorial.Na aba Localização você pode visualizar os endereços já cadastrados, além de criar localizações precisas para vincular seus equipamentos a elas.": "Na aba Localização você pode visualizar os endereços já cadastrados, além de criar localizações precisas para vincular seus equipamentos a elas.",
    "tutorial.Localização": "Localização",

    //RECOVER ACCOUNT
    "recoverAccount.Para prosseguir, selecione a categoria na qual você se encaixa:": "Para prosseguir, selecione a categoria na qual você se encaixa:",
    "recoverAccount.Deseja acessar a plataforma como DISTRIBUIDOR? Crie sua conta clicando em EMPRESA e entre em contato conosco para liberarmos os demais acessos.": "Deseja acessar a plataforma como DISTRIBUIDOR? Crie sua conta clicando em EMPRESA e entre em contato conosco para liberarmos os demais acessos.",
    "recoverAccount.A qual organização você pertence": "A qual organização você pertence?",

    //RECOVER PASSWORD PAGE
    "recoverPassword.E-mail para recuperação de senha":
      "E-mail para recuperação de senha",
    "recoverPassword.Informe o e-mail cadastrado na plataforma":
      "Informe o e-mail cadastrado na plataforma",
    "recoverPassword.E-mail inválido. Por favor, verifique":
      "E-mail inválido. Por favor, verifique",
    "recoverPassword.RECUPERAR SENHA": "RECUPERAR SENHA",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL COM O LINK DE RECUPERAÇÃO DE SENHA":
      "EM BREVE VOCÊ RECEBERÁ UM E-MAIL COM O LINK DE RECUPERAÇÃO DE SENHA",
    "recoverPassword.Informe seu e-mail": "Informe seu e-mail",
    "recoverPassword.Crie uma nova senha": "Crie uma nova senha",
    "recoverPassword.O campo senha deve conter no mínimo 6 caracteres":
      "O campo senha deve conter no mínimo 6 caracteres",
    "recoverPassword.Confirme sua senha": "Confirme sua senha",
    "recoverPassword.As senhas devem ser iguais": "As senhas devem ser iguais",
    "recoverPassword.ENTRAR": "ENTRAR",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL PARA CONFIRMAR SUA IDENTIDADE": "EM BREVE VOCÊ RECEBERÁ UM E-MAIL PARA CONFIRMAR SUA IDENTIDADE",
    "recoverPassword.CONTINUAR": "CONTINUAR",
    "recoverPassword.CONFIRMAR": "CONFIRMAR",

    // EXPORT
    "export.Limpezas": "Limpezas",
    "export.Eventos": "Eventos",
    "export.Lista de receitas executadas": "Lista de receitas executadas",
    "export.Lista de limpezas executadas": "Lista de limpezas executadas",
    "export.Lista de eventos executados": "Lista de eventos executados",
    "export.Exportar dados": "Exportar dados",
    "export.Informações do equipamento": "Informações do equipamento",
    "export.Dados exportados com sucesso!": "Dados exportados com sucesso!",
    "export.Não foi possível exportar os dados. Por favor, tente novamente mais tarde.": "Não foi possível exportar os dados. Por favor, tente novamente mais tarde.",
    "export.Detalhes do menu": "Detalhes do menu",
    "export.Detalhes de receitas": "Detalhes de receitas",
    "export.Detalhes e Passos": "Detalhes e Passos",
    "export.Exportando dados. Em breve o arquivo estará disponível!": "Exportando dados. Em breve o arquivo estará disponível!",
    "export.Resumo dos passos": "Resumo dos passos",
    "export.salvar imagem": "salvar imagem"
  },
};

export default pt;
