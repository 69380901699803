import * as Styled from './styles';
import Notebook from "../../../assets/image/notebookIOK.png";
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { db } from '../../../db/db';
import { useEffect, useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';

type ModalProps = {
  onCancel: () => void;
  id: number;
};

const ModalRecoverAccount: React.FC<ModalProps> = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [showAgain, setShowAgain] = useState<boolean>();
  const userConfigsDb = useLiveQuery(() => db.userConfigs.toArray());

  useEffect(() => {
    if (userConfigsDb && userConfigsDb[0].showAgain) {
      setShowAgain(userConfigsDb[0].showAgain)
    }
  }, [userConfigsDb])

  const handleShowAgain = () => {
    if (showAgain === true) {
      setShowAgain(false);
    } else {
      setShowAgain(true);
    };
  };

  return (
    <>
      <Styled.Modal>
        <Styled.Container>
          <Styled.CloseButton
            onClick={() => {
              const submit = {
                userConfigs: {
                  id: userConfigsDb && userConfigsDb[0].id,
                  showAgain: showAgain
                }
              }
              db.userConfigs.update(props.id, submit.userConfigs)
              props.onCancel()
            }}>X</Styled.CloseButton>
          <Styled.Image>
            <img src={Notebook} alt='Notebook' />
          </Styled.Image>

          <Styled.Content>
            <Styled.Title>
              {intl.formatMessage({ id: 'login.A PLATAFORMA IOK MUDOU!' })}
            </Styled.Title>
            <Styled.Text>
              {intl.formatMessage({ id: 'login.Se você já possui um cadastro na antiga plataforma, ' })}
              <span
                onClick={() => {
                  navigate('/recoverAccount');
                  const submit = {
                    userConfigs: {
                      id: userConfigsDb && userConfigsDb[0].id,
                      showAgain: showAgain,
                      recover: true
                    }
                  }
                  db.userConfigs.update(props.id, submit.userConfigs);
                }}>{intl.formatMessage({ id: 'login.clique aqui' })}</span>
              {intl.formatMessage({ id: 'login. para' })}
              <span className="bold">{intl.formatMessage({ id: 'login.migrar' })}</span>
              {intl.formatMessage({ id: 'login.seus dados e aproveitar toda a experiência IOK.' })}
            </Styled.Text>
            <Styled.Footer>
              <label className="container">
                {intl.formatMessage({ id: 'login.Não exibir este aviso novamente' })}
                <input
                  id='check'
                  type='checkbox'
                  name='check'
                  onChange={() => handleShowAgain()}
                />
                <span className="checkmark"></span>
              </label>
            </Styled.Footer>
          </Styled.Content>
        </Styled.Container>
      </Styled.Modal>
    </>
  );
}

export default ModalRecoverAccount;