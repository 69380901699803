import translate from "../../components/i18n/translate"
import { StepsTutorials } from "../../models/StepTutorials"

export const EditRecipeTutorial: StepsTutorials = {
  id: 1,
  step: [{
    title: translate('tutorial.Editar detalhes da receita'),
    content: translate('tutorial.Agora você pode adicionar mais detalhes às suas receitas e deixá-las ainda mais completas. Clique em Editar detalhes e preencha as informações necessárias.'),
    target: "#joyride-edit-recipe",
    placement: "auto",
    disableOverlayClose: true
  }]
}

export const SaveRecipeTutorial: StepsTutorials = {
  id: 2,
  step: [{
    title: translate('tutorial.Salvar edição da receita'),
    content: translate('tutorial.Depois de informar mais detalhes da receita, clique em Salvar.'),
    target: '#joyride-save-recipe',
    placement: 'auto',
    disableOverlayClose: true
  }]
}

export const EditEquipmentTutorial: StepsTutorials = {
  id: 3,
  step: [{
    title: translate('tutorial.Editar registro de um equipamento'),
    content: translate('tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Grupo.'),
    target: '#joyride-edit-equipment',
    placement: 'auto',

  }]
}

export const SaveEquipmentTutorial: StepsTutorials = {
  id: 4,
  step: [{
    title: translate('tutorial.Salvar edições de um equipamento'),
    content: translate('tutorial.Depois de completar o registro de seus equipamentos, clique em Salvar.'),
    target: '#joyride-save-equipment',
    placement: 'auto',
    disableOverlayClose: true
  }]
}

export const EditUserTutorial: StepsTutorials = {
  id: 5,
  step: [{
    title: translate('tutorial.Editar usuário'),
    content: translate('tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente ao Grupo de equipamentos a qual ele faz parte.'),
    target: '#joyride-edit-user',
    placement: 'auto',
    disableOverlayClose: true
  }]
}

export const SaveUserTutorial: StepsTutorials = {
  id: 6,
  step: [{
    title: translate('tutorial.Salvar edições de usuário'),
    content: translate('tutorial.Depois de completar o cadastro dos usuários, clique em Salvar.'),
    target: '#joyride-save-user',
    placement: 'auto',
    disableOverlayClose: true
  }]
}

export const MenuTutorial: StepsTutorials = {
  id: 7,
  step: [
    {
      title: translate('tutorial.Criar menu'),
      content: translate('tutorial.Para criar e personalizar um menu, clique em Criar Menu e preencha as informações necessárias no modal. Você pode editar um menu a qualquer momento.'),
      target: '#joyride-create-menu',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Importar menu'),
      content: translate('tutorial.Para importar um menu, clique em Importar Menu e selecione o modelo e versão do equipamento.'),
      target: '#joyride-import-menu',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Lixeira'),
      content: translate('tutorial.Os menus excluídos serão mantidos na Lixeira por 30 dias. Depois desse prazo serão excluídos permanentemente.'),
      target: '#joyride-trash',
      placement: 'auto',
      disableOverlayClose: true
    }
  ]
}

export const RestoreMenusTutorial: StepsTutorials = {
  id: 8,
  step: [{
    title: translate('tutorial.Restaurar arquivos'),
    content: translate('tutorial.Para restaurar um arquivo excluído ou deletá-lo permanentemente, clique no menu lateral e selecione a opção desejada.'),
    target: '#joyride-restore-menus',
    placement: 'auto',
    disableOverlayClose: true
  }]
}

export const YourEquipmentTutorial: StepsTutorials = {
  id: 9,
  step: [
    {
      title: translate('tutorial.Adicionar equipamento'),
      content: translate('tutorial.Para adicionar um equipamento à sua conta, clique em Adicionar Equipamento e preencha as informações do modal. Depois disso, clique em Salvar.'),
      target: '#joyride-add-equipment',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Atualizar equipamentos'),
      content: translate('tutorial.Para enviar uma atualização a seus equipamentos, clique em Atualizar Equipamentos, selecione os modelos que deseja atualizar e a forma como deseja realizar essa atualização - via wi-fi ou pen drive. Depois disso, clique em Confirmar.'),
      target: '#joyride-update-equipment',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Editar equipamento'),
      content: translate('tutorial.Para desvincular um equipamento ou editar suas informações, clique no menu lateral e selecione a opção desejada.'),
      target: '#joyride-edit-equipment',
      placement: 'auto',
      disableOverlayClose: true
    },
  ]
}

export const CookbookTutorial: StepsTutorials = {
  id: 10,
  step: [
    {
      title: translate('tutorial.Cookbook'),
      content: translate('tutorial.No cookbook, em Receitas Disponíveis, você encontrará as receitas criadas pelos Chefs da Prática e poderá utilizá-las sempre que quiser.'),
      target: '#joyride-cookbook',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Filtros do Cookbook'),
      content: translate('tutorial.Para facilitar a navegação pelas receitas, você pode aplicar filtros a elas. Basta clicar no filtro desejado e escolher uma de suas opções.'),
      target: '#joyride-cookbook-filter',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Suas receitas'),
      content: translate('tutorial.Pelo cookbook você também encontra suas próprias receitas, além de poder criá-las! Clique em Receitas Salvas para visualizar as receitas que foram salvas do cookbook da Prática e também as criadas por você.'),
      target: '#joyride-your-cookbook',
      placement: 'auto',
      disableOverlayClose: true
    }
  ]
}

export const ManageUsersTutorial: StepsTutorials = {
  id: 11,
  step: [
    {
      title: translate('tutorial.Gerenciamento de usuários'),
      content: translate('tutorial.Aqui você pode gerenciar seus usuários com mais facilidade. Ao clicar em + Usuário, você poderá adicionar um novo usuário ou criar um novo tipo de usuário.'),
      target: '#joyride-manage-users',
      placement: 'auto',
      disableOverlayClose: true,
    },
    {
      title: translate('tutorial.Gerenciamento de contas'),
      content: translate('tutorial.Se caso outra pessoa da sua organização também migrou a conta principal da empresa, aqui você pode unir as duas contas.'),
      target: '#joyride-merge-accounts',
      placement: 'auto',
      disableOverlayClose: true,
    },
    {
      title: translate('tutorial.Editar ou excluir um usuário'),
      content: translate('tutorial.Para excluir ou editar as informações de um usuário já cadastrado, clique no menu lateral e selecione a opção desejada.'),
      target: '#joyride-edit-user',
      placement: 'auto',
      disableOverlayClose: true,
    },
    {
      title: translate('tutorial.Editar usuário'),
      content: translate('tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente ao Grupo de equipamentos a qual ele faz parte.'),
      target: '#joyride-edit-user',
      placement: 'auto',
      disableOverlayClose: true
    }
  ]
}

export const CreateRecipeTutorial: StepsTutorials = {
  id: 12,
  step: [
    {
      title: translate('tutorial.Criar receita'),
      content: translate('tutorial.No cookbook você pode criar suas próprias receitas! Clique em Criar Receita e preencha todas as informações necessárias.'),
      target: '#joyride-create-cookbook',
      placement: 'auto',
      disableOverlayClose: true
    }
  ]

}

export const StoreTutorial: StepsTutorials = {
  id: 13,
  step: [
    {
      title: translate('tutorial.Grupos de equipamentos'),
      content: translate('tutorial.Na aba Grupos você pode visualizar os grupos cadastrados e os equipamentos distribuídos entre eles. Além disso, é possível cadastrar novos grupos e editar os já cadastrados.'),
      target: '#joyride-store',
      placement: 'auto',
      disableOverlayClose: true
    }
  ]
}

export const StoreEquimentTutorial: StepsTutorials = {
  id: 15,
  step: [
    {
      title: translate('tutorial.Dados dos equipamentos'),
      content: translate('tutorial.Ao clicar sobre a linha de um equipamento, você pode visualizar mais estatísticas e informações sobre ele, como as receitas mais executadas, limpezas realizadas e outros dados relevantes.'),
      target: '#joyride-store-equipment',
      placement: 'auto',
      disableOverlayClose: true
    }
  ]
}

export const GroupTutorial: StepsTutorials = {
  id: 16,
  step: [
    {
      title: translate('tutorial.Criar grupo'),
      content: translate('tutorial.Para organizar suas receitas em grupos, clique em Criar Grupo e preencha as informações necessárias no modal.'),
      target: '#joyride-create-group',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Exportar menu'),
      content: translate('tutorial.Depois de personalizar seu menu, exporte-o para seus equipamentos pela internet ou por pen drive.'),
      target: '#joyride-export-menu',
      placement: 'auto',
      disableOverlayClose: true
    }
  ]
}

export const EquipmentTutorial: StepsTutorials = {
  id: 17,
  step: [
    {
      title: translate('tutorial.Seus equipamentos'),
      content: translate('tutorial.Aqui você visualizará todos os equipamentos cadastrados em sua plataforma.'),
      target: '#joyride-equipment',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Grupos'),
      content: translate('tutorial.Na aba Grupos você pode visualizar os grupos cadastrados e os equipamentos distribuídos entre eles. Além disso, é possível cadastrar novos grupos e editar os já cadastrados.'),
      target: '#joyride-store',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Localização'),
      content: translate('tutorial.Na aba Localização você pode visualizar os endereços já cadastrados, além de criar localizações precisas para vincular seus equipamentos a elas.'),
      target: '#joyride-location',
      placement: 'auto',
      disableOverlayClose: true
    }
  ]
}

export const CacheTutorial: StepsTutorials = {
  id: 18,
  step: [
    {
      title: translate('tutorial.Atualização e cache'),
      content: translate('tutorial.Para atualizar a plataforma e corrigir eventuais problemas de cache, clique aqui.'),
      target: '#joyride-cache',
      placement: 'auto',
      disableOverlayClose: true
    }
  ]
}

export const HomeTutorial: StepsTutorials = {
  id: 19,
  step: [
    {
      title: translate('tutorial.Home'),
      content: translate('tutorial.Esta é a sua home! Aqui você encontra um resumo geral sobre sua conta, como quantidade de Equipamentos Vinculados;'),
      target: '#joyride-dashboard-equipment',
      placement: 'auto',
      disableOverlayClose: true,
    },
    {
      title: translate('tutorial.Home'),
      content: translate('tutorial.Menus Disponíveis;'),
      target: '#joyride-dashboard-menu',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Home'),
      content: translate('tutorial.Total de Receitas Cadastradas;'),
      target: '#joyride-dashboard-recipe',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Home'),
      content: translate('tutorial.Atualizações referentes a versão dos equipamentos vinculados a sua conta;'),
      target: '#joyride-dashboard-update',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Home'),
      content: translate('tutorial.Localização de seus equipamentos, sendo possível expandir o mapa para melhor visualização;'),
      target: '#joyride-dashboard-location',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Home'),
      content: translate('tutorial.Total de Usuários Cadastrados em sua conta.'),
      target: '#joyride-dashboard-users',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Home'),
      content: translate('tutorial.Clique nas abas do menu para explorar a plataforma e conhecer tudo o que ela pode oferecer.'),
      target: '#joyride-navbar',
      placement: 'auto',
      disableOverlayClose: true
    },
  ]
}

export const AccountTutorial: StepsTutorials = {
  id: 20,
  step: [
    {
      title: translate('tutorial.Editar conta'),
      content: translate('tutorial.Para editar ou configurar sua conta, clique em seu nome e selecione a opção desejada.'),
      target: '#joyride-user-name',
      placement: 'auto',
      disableOverlayClose: true
    },
    {
      title: translate('tutorial.Alterar idioma'),
      content: translate('tutorial.Para alterar o idioma de sua plataforma, clique em Idioma e selecione a linguagem desejada.'),
      target: '#joyride-language',
      placement: 'auto',
      disableOverlayClose: true
    },
  ]
}
