import { Colors, Shadow } from '../StyleTypes';

const light = {
  title: 'light',
  colors: {
    background: Colors.HIGH_LIGHTEST,
    textPrimary: Colors.LOW_PURE,
    textSecondary: Colors.HIGH_PURE,
    header: Colors.PRIMARY_LIGHTEST,
    textHeader: Colors.PRIMARY_HIGHLIGHT,
    iconHeader: '#2F72CF',
    modalHeaders: Colors.PRIMARY_PURE,
    navbar: Colors.PRIMARY_PURE,
    navbarHoverItem: Colors.PRIMARY_HIGHLIGHT,
    dropdownBackground: Colors.HIGH_MEDIUM,
    dropdownHover: Colors.PRIMARY_LIGHTEST,
    solidButton: Colors.PRIMARY,
    outlineSearch: Colors.HIGH_LIGHT,
    outlineActiveButton: Colors.PRIMARY,
    outlineInactiveButton: Colors.LOW_LIGHT,
    cancellationButton: Colors.TOAST_WARNING,
    backgroundInput: Colors.HIGH_LIGHT,
    backgroundInputError: Colors.ERROR_WARNING,
    textErrorInput: Colors.TOAST_WARNING,
    placeholderInput: Colors.HIGH_DARK,
    placeholderInputPassword: '#c9c9c9',
    navbarShadow: 'none',
    backgroundButton: Colors.PRIMARY,
    backgroundButtonModal: Colors.CONFIRMATION_GRADIENT,
    backgroundBox: Colors.PRIMARY_HIGHLIGHT,
    backgroundHeaderModal: Colors.PRIMARY_LIGHT,
    iconsSvg: Colors.PRIMARY,
    boxShadow: '#c0bfbf',
    boxGroup: '#E5E5E5',
    boxGroupBorder: '#E5E5E5',
    boxText: Colors.PRIMARY,
    backgroundTable: Colors.HIGH_MEDIUM,
    whiteDarkBlueLight: '#025CB7',
    backgroundCompatibilityButtons: '#D0DFF0',
    textSelect: Colors.LOW_PURE,
    textSteps: Colors.LOW_DARKEST,
    textTitle: Colors.PRIMARY_HIGHLIGHT,
    backgroundSteps: Colors.STEP_LIGHT,
    backgroundGradient: Colors.HIGH_GRADIENT,
    imageOptions: Colors.HIGH_PURE,
    imageOptionsText: Colors.PRIMARY_PURE,
    backgroundHeaderTable: Colors.PRIMARY_HIGHLIGHT,
    textInfoHome: Colors.LOW_PURE,
    backgroundGraphic: Colors.GRAPHIC_LIGHT,
    backgroundInfoMap: Colors.HIGH_PURE,
    infoMap: Colors.PRIMARY_PURE,
  },
  shadows: {
    modalShadow: Shadow.LIGHT_MODAL_SHADOW,
    modalGroupShadow: 'none',
  },
  mapStyle: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ],
};

export default light;